import { FETCH_VENDORS_LOADING, FETCH_VENDORS } from "../actions/types";


const vendorsReducer =  (state = {'Vendors':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_VENDORS_LOADING:
            return {...state, 'loading': true, 'Vendors':null };
        case FETCH_VENDORS:
            return { ...state, [action.internalId]:action.payload, 'loading': false}
        default:
            return state;
    }
};

export default vendorsReducer;