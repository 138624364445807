import { GridToolTip } from './gridToolTip';

export const GridTooltips = (props) => {
    const { columns } = props;
    return (
        <>
            {columns.map((column) => {
                return <GridToolTip key={`tooltip-gridcolumn-${column.Code}`} columnCode={column.Code} toolTip={column.Tooltip} />
            })
            }
        </>
    );

}