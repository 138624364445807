import { FETCH_UPLOADEDDATA_LOADING, FETCH_UPLOADEDDATA, FETCH_UPLOADERROR_LOADING, FETCH_UPLOADERROR } from "../actions/types";
import Moment from 'moment';


const uploadedDataReducer = (state = { 'loading': false, 'error': false, 'data': null, 'errorList': null }, action) => {
    switch (action.type) {
        case FETCH_UPLOADEDDATA_LOADING:
            return { ...state, 'loading': true, 'error': false };
        case FETCH_UPLOADEDDATA:
            let data = action.payload;
            data = data.map(x => {
                return { ...x, LastDateModified: Moment(x.LastDateModified).format('DD/MMM/yyyy') }
            })
            return { ...state, "data": data, 'loading': false, 'error': false }
        case FETCH_UPLOADERROR_LOADING:
            return { ...state, 'loading': true, 'error': true };
        case FETCH_UPLOADERROR:
            let errorList = action.payload;
            errorList = errorList.map(x => {
                return { ...x, LastDateModified: Moment(x.LastDateModified).format('DD/MMM/yyyy') }
            })

            return { ...state, 'loading': true, 'errorList': errorList, 'error': true };
        default:
            return state;
    }
};

export default uploadedDataReducer;