import { DataGrid, Column, Scrolling } from "devextreme-react/data-grid";
import { fetcher } from "../../../../actions";
import useSWR from "swr";
import { LinearProgress } from "@mui/material";
import Moment from "moment";
//

const LogGrid = (props) => {
  const { categoryId, internalId, programId } = props;

  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(
    `/api/Report/reportEditableFields?ProgramId=${programId}&CategoryId=${categoryId}&InternalId=${internalId}`,
    fetcher
  );

  if (error) return <div>Failed to load</div>;
  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  let gridData = [];
  if (dataSource) {
    gridData = dataSource.map((obj) => {
      return {
        ...obj,
        transactionDate:
          obj.transactionDate === "0001-01-01T00:00:00"
            ? ""
            : Moment(obj.transactionDate).format("yyyy-MM-DD HH:mm:ss"),
      };
    });
  }

  return (
    <>
      <div id="ConsolidateGrid">
        <DataGrid
          dataSource={gridData}
          showBorders={true}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"20vh"}
          width={"100%"}
          columnResizingMode={"widget"}
          wordWrapEnabled={true}
        >
          <Scrolling mode="virtual" />
          <Column
            dataField="transactionDate"
            caption="Transaction Date"
            alignment="left"
          />
          <Column dataField="displayName" caption="Name" alignment="left" />
          <Column dataField="field" caption="Field" alignment="left" />
          <Column dataField="oldValue" caption="Old Value" alignment="left" />
          <Column dataField="newValue" caption="New Value" alignment="left" />
        </DataGrid>
      </div>
    </>
  );
};

export default LogGrid;
