import apiClient from "../../apis/items";

export const textOptionsToPost = [
    {
        id: 1,
        name: "contains",
    },
    {
        id: 2,
        name: "doesNotContains",
    },
    {
        id: 3,
        name: "startsWith",
    },
    {
        id: 4,
        name: "endsWith",
    },
    {
        id: 5,
        name: "equals",
    },
    {
        id: 6,
        name: "doesNotEqual",
    },
    {
        id: 7,
        name: "isBlank",
    },
    {
        id: 8,
        name: "isNotBlank",
    },
    {
        id: 9,
        name: "isAnyOf",
    },
    {
        id: 10,
        name: "isNoneOf",
    },
];

export const numericOptionsToPost = [
    {
        id: 1,
        name: "equals",
    },
    {
        id: 2,
        name: "doesNotEqual",
    },
    {
        id: 3,
        name: "isLessThan",
    },
    {
        id: 4,
        name: "isGreaterThan",
    },
    {
        id: 5,
        name: "isLessThanOrEqual",
    },
    {
        id: 6,
        name: "isGreaterThanOrEqual",
    },
    {
        id: 7,
        name: "isBlank",
    },
    {
        id: 8,
        name: "isNotBlank",
    },
    {
        id: 9,
        name: "isBetween",
    },
];

const logicalOperatorOptions = [
    {
        id: 1, text: 'Or'
    },
    {
        id: 2, text: 'And'
    },
]

const getLogicalOperatorText = (id) => {
    return logicalOperatorOptions?.find(
        a => a.id === id
    )?.text;
}

const getOperatorText = (id, columnType) => {
    if (columnType === 'numeric') {
        return numericOptionsToPost?.find(
            a => a.id === id
        )?.name;
    }
    else {
        return textOptionsToPost?.find(
            a => a.id === id
        )?.name;
    }

}

export const postMasterGridFilter = (data, typeOfSearchId) => {
    const dataToPost = typeOfSearchId === 1 ? {
        "filterName": "test Filter",
        "partNameList": data.map((partName) => (partName))
    } : {
        "filterName": "test Filter",
        "internalIdList": data.map((internalId) => (internalId))
    }

    return dataToPost;
}

export const postValidateFilter = async (data, typeOfSearchId) => {
    const dataToPost = typeOfSearchId === 1 ? {
        "filterName": "test Filter",
        "partNameList": data.map((partName) => (partName))
    } : {
        "filterName": "test Filter",
        "internalIdList": data.map((internalId) => (internalId))
    }
    var response = await apiClient.post(`/api/Validateitems/itemValidateMassiveFilter`, dataToPost);
    return response?.data;
}

export const postMasterGridColumnFilter = (data) => {
    const dataToPost = {
        "filterName": "test Filter",
        "columnNameList": data.map((filterRow) => {
            return {
                "columnId": filterRow?.id,
                "filterCriteria": getOperatorText(filterRow?.operator, filterRow?.columnType),
                "filterValue": filterRow?.filterValue2 !== null ? `${filterRow?.filterValue2}, ${filterRow?.filterValue}` : filterRow?.filterValue,
                "operator": filterRow?.logicalOperator ? getLogicalOperatorText(filterRow?.logicalOperator) : "",
            }
        })
    }

    return dataToPost;
}


