import {
  DataGrid,
  Column,
  Scrolling,
  FilterRow,
  HeaderFilter,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import Moment from "moment";
import { LinearProgress } from "@mui/material";

import { fetcher } from "../../../../actions";
import useSWR from "swr";

const getDataGrid = (data) => {
  if (!data) {
    return null;
  }
  const newArr = data.map((obj) => {
    var date =
      obj.date === "0001-01-01T00:00:00"
        ? ""
        : Moment(obj.date).format("yyyy-MM-DD");
    return { ...obj, FormattedDate: date };
  });
  return newArr;
};

const BackGrid = (props) => {
  const { internalId } = props;
  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(
    `/api/Report/reportBackOrderCommitted?InternalID=${internalId}`,
    fetcher
  );
  const gridData = getDataGrid(dataSource);

  if (error) return <div>Failed to load</div>;
  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      <div id="BackGrid">
        <DataGrid
          dataSource={gridData}
          showBorders={true}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"63vh"}
          width={"100%"}
          columnResizingMode={"widget"}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="virtual" />
          <Column dataField="documentNumber" caption="SO Number" />
          <Column dataField="FormattedDate" caption="Date" format={"yyyy-MM-dd"} dataType={"date"} />
          <Column dataField="customer" caption="Customer Name" />
          <Column dataField="qtyFulfilled" caption="Quantity Open" />
          <Column dataField="qtyCommitted" caption="Quantity Committed" />
          <Column dataField="qtyBackOrdered" caption="Quantity BO" />
          <Summary>
            <TotalItem
              column="qtyFulfilled"
              summaryType="sum"
              displayFormat="{0}"
            />
            <TotalItem
              column="qtyCommitted"
              summaryType="sum"
              displayFormat="{0}"
            />
            <TotalItem
              column="qtyBackOrdered"
              summaryType="sum"
              displayFormat="{0}"
            />
          </Summary>
        </DataGrid>
      </div>
    </>
  );
};

export default BackGrid;
