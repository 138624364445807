import { RoomPreferencesRounded } from "@mui/icons-material";
import { Card, CardContent, Typography } from "@mui/material";
import { Button } from "devextreme-react";
import DataGrid, {
    Column,
    Editing,
    Form,
    PatternRule,
    RequiredRule,
    Scrolling
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createRole, deleteRole, updateRole } from "../../actions";
import { modalActions } from "./modalActions";
import ModalContainer from "../../common/ModalContainer";
import PermissionsByRoleTab from "./Modals/PermissionsByRole/PermissionsByRoleTab";

const AdminRoles = (props) => {
    const { roles } = props;
    const [state, setState] = useState({
        "open": {
          "permissionsByRole": false
        },
        "roleId": 0    
      });
    const dispatch = useDispatch()
    const onSaving = (e) => {
        const change = e.changes[0];
        if (change.type === "remove") {
            const roleId = change.key;
            dispatch(deleteRole(roleId));
        }
        else if (change.type === "insert") {
            const roleName = change.data.name;
            dispatch(createRole(roleName));
        }

        else if (change.type === "update") {
            const roleId = change.key;
            const roleName = change.data.name;
            dispatch(updateRole(roleId, roleName));
        }

    };

    const { ActionsButtons, handleCloseDialog } = modalActions(setState, state, "permissionsByRole", <RoomPreferencesRounded />, "roleId", "id");

    
    const renderActionButton = (e) =>{
        return <div style={{ cursor: "pointer", display: "inline-block" }} onClick={() =>  setState({ ...state, open: { ...state.open, "permissionsByRole": true }, "roleId": e.data.id })}><RoomPreferencesRounded /></div>;
    }
    /// Method that generate the name in the pop up window
    const renderTitle = (roleId) => {
        var nameRole = ''
        for(var i = 0; i < roles.length; i++){
            if(roles[i].id == roleId){
                nameRole = roles[i].name;
            }
        }
        return <h3>Role Permissions {nameRole}</h3>
    }


    if (!roles) {
        return <></>
    }

    return <div>
        <Card height={"40vh"}>
            <CardContent>
                <Typography variant="h6" component="div">
                    Roles Available
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Create Roles for grouping permissions and then grant access to the features to your users
                </Typography>
                <hr></hr>
                <div id="AdminRolesGrid">
                    <DataGrid
                        id="gridContainer"
                        dataSource={roles}
                        keyExpr="id"
                        showBorders={true}
                        onSaving={onSaving}
                    >
                        <Editing
                            mode="form"
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                        >
                            <Scrolling mode="virtual" />
                            <Form>
                                <Item itemType="group" caption="Role Information">
                                    <Item dataField="Actions" visible={false} />
                                    <Item dataField="name" />
                                </Item>
                            </Form>
                        </Editing>
                        <Column type="buttons" >
                            <Button name="edit" />
                            <Button name="delete" />
                        </Column>
                        <Column type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={renderActionButton} />
                        <Column dataField="id" caption="Id" />
                        <Column dataField="name" caption="Name" >
                            <RequiredRule />
                            <PatternRule
                                pattern="^[a-zA-Z1-9]+$"
                                message="Special characters are not allowed"
                            />
                        </Column>
                        <Column dataField="userCount" caption="User Count" allowEditing={false} />
                    </DataGrid>
                </div>
            </CardContent>
        </Card>       
        <ModalContainer open={state.open.permissionsByRole} modalId="permissionsByRole" onClose={handleCloseDialog} title={renderTitle(state.roleId)} >
            <PermissionsByRoleTab roleId={state.roleId}/>
        </ModalContainer>
    </div>
}

export default AdminRoles;


