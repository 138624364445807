import { CleanUpdateFields, CleanUpdateFieldsGlobalGrid, postItems } from "../../actions";
import { setCellColorBackgroundFromValue } from "./customCellStyle";
import { sendSignalRMessage } from "./functions/signalR";
import { getPalette } from "devextreme/viz/palette";

export const displayNode = (node, recursionIndex) => {
  node.visible = true;
  if (recursionIndex <= 5) {
    if (node.children.length > 0) {
      node.hasChildren = true;
      node.children.forEach((node) => {
        displayNode(node, recursionIndex + 1);
      });
    }
  }
};

const getSettingsMap = (settingsData, programId, categoryId) => {
  const categorySettings = settingsData.filter(
    (x) => x.idCategory === categoryId && x.idProgram === programId
  );

  return categorySettings.reduce((map, item) => {
    map[item.code[0]] = item;
    return map;
  }, {});
};

const handleSetting = (e, settingsMap, greaterThanCode, limitCode, columnName) => {
  const ROW_TYPE_DATA = "data";
  const greaterThan = settingsMap[greaterThanCode];
  const limit = settingsMap[limitCode];

  if (greaterThan && greaterThan.value === "F" && limit) {
    setCellColorBackgroundFromValue(e, columnName, limit.value);
  } else if (e.rowType === ROW_TYPE_DATA) {
    setCellColorBackgroundFromValue(e, columnName, e.data["L12M"]);
  }
};

const isEditableCellInCategoryList = (e, categoriesList) => {
  return e.column.allowEditing && categoriesList[e.data.programId]?.find(x => x.categoryId === e.data.categoryId);
};


export const onCellPrepared = (e, settingsData,categoriesList) => {
  if (e.rowType === "data") {
    const programId = e.data["programId"];
    const categoryId = e.data["categoryId"];
    const categorySettings = settingsData["categoryId_" + categoryId]["programId_" + programId];
    if (categorySettings) {

      const handleSetting = (greaterThanCode, limitCode, columnName) => {
        const greaterThan = categorySettings["codeLetter_" + greaterThanCode];
        const limit = categorySettings["codeLetter_" + limitCode];

        if (greaterThan && greaterThan.value === "F" && limit) {
          setCellColorBackgroundFromValue(e, columnName, limit.value);
        } else if (e.rowType === "data") {
          setCellColorBackgroundFromValue(e, columnName, e.data["L12M"]);
        }
      };

      handleSetting("D", "I", "BO_total");
      handleSetting("E", "F", "CommitTotal");
    }
    if (isEditableCellInCategoryList(e, categoriesList)) {
      e.cellElement.style.backgroundColor = "#d6ecff8f";
    }
  }
}



export const onContextMenuPreparing = (e, action) => {
  if (e.rowType === "data") {
    e.items = [
      {
        text: "View Editable Fields Log",
        onItemClick: function () {
          return action(e);
        },
      },
    ];
  }
};

export async function setCellValue(
  newData,
  value,
  currentRowData,
  field,
  dbFieldName,
  type,
  userId
) {
  async function sendData(payload) {
    await postItems(payload);
  }

  if (value === null) {
    value = 0;
  }
  const payload = {
    Id: currentRowData.Id,
    keyItem: currentRowData.keyItem,
    InternalId: currentRowData.InternalId,
    programId: currentRowData.programId,
    categoryId: currentRowData.categoryId,
    fieldName: dbFieldName,
    fieldValue: String(value),
    type: type,
    userId: userId,
  };
  sendData(payload);
  newData[field] = value;
}

export const disableNumericArrows = (e) => {
  if (e.editorName === "dxNumberBox")
    e.editorOptions.step = 0;
}

export const clearFields = async (programId, categoryId, PoQtyReviewer, PoNotesReviewer, PoQtyMarket, PoNotesMarket, PoQtyApprover, PoNotesApprover, data, mutate) => {
  await CleanUpdateFields(
    programId,
    categoryId,
    PoQtyReviewer,
    PoNotesReviewer,
    PoQtyMarket,
    PoNotesMarket,
    PoQtyApprover,
    PoNotesApprover
  )
  var newData = data.map((item) => {
    return {
      ...item,
      col_poQtyReviewer: PoQtyReviewer === 1 ? null : item.col_poQtyReviewer,
      col_poNotesReviewer: PoNotesReviewer === 1 ? null : item.col_poNotesReviewer,
      col_poQtyMarket: PoQtyMarket === 1 ? null : item.col_poQtyMarket,
      col_poNotesMarket: PoNotesMarket === 1 ? null : item.col_poNotesMarket,
      col_poQtyApprover: PoQtyApprover === 1 ? null : item.col_poQtyApprover,
      col_poNotesApprover: PoNotesApprover === 1 ? null : item.col_poNotesApprover
    };
  });
  mutate(newData);
}



export const clearFieldsGlobalGrid = async (roleId, internalId, categories, PoQtyReviewer, PoNotesReviewer, PoQtyMarket, PoNotesMarket, PoQtyApprover, PoNotesApprover, data, mutate) => {
  await CleanUpdateFieldsGlobalGrid(roleId, internalId);
  var newData = data.map((item) => {
    if (categories[item.programId].find(x => x.idCategory === item.categoryId)) {
      return {
        ...item,
        col_poQtyReviewer: PoQtyReviewer === 1 ? null : item.col_poQtyReviewer,
        col_poNotesReviewer: PoNotesReviewer === 1 ? null : item.col_poNotesReviewer,
        col_poQtyMarket: PoQtyMarket === 1 ? null : item.col_poQtyMarket,
        col_poNotesMarket: PoNotesMarket === 1 ? null : item.col_poNotesMarket,
        col_poQtyApprover: PoQtyApprover === 1 ? null : item.col_poQtyApprover,
        col_poNotesApprover: PoNotesApprover === 1 ? null : item.col_poNotesApprover
      };
    }
    return item;
  });
  mutate(newData);
}
