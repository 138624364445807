import useSWR from "swr";
import MainGrid from "../mainGrid/mainGrid";
import { fetcherPost } from "../../GlobalGrid";
import { useState } from "react";

export const GlobalGridRenderer = ({ url, data }) => {
    const [selectedMoh, setSelectedMoh] = useState(1);

    const handleMohModifierChange = (e) => {
        setSelectedMoh(e);
    }

    console.log("GlobalGridRenderer url: ", url, data);
    const { data: newData, error, isLoading, mutate } = useSWR(url, fetcherPost(data), { refreshInterval: 60000, revalidateOnFocus: false, revalidateOnReconnect: false, refreshWhenHidden: false });
    if (newData) {
        return (
            <>
                <MainGrid programId={0} categoryId={0} selectedModifier={selectedMoh} data={newData.dataSource} error={error} isLoading={isLoading} mutate={mutate} onMohModifierChange={handleMohModifierChange} />
            </>
        )
    }
    return null;
}
