import { DataGrid, Column, Scrolling, FilterRow, HeaderFilter, Search, Summary, TotalItem, FilterPanel } from "devextreme-react/data-grid";
import Moment from "moment";
import { LinearProgress } from "@mui/material";
import { fetcher } from "../../../../actions";
import useSWR from "swr";

const getFormattedDate = (rowData, column) => {
    const date = rowData[column];
    const formattedDate =
        ( (date === "1900-01-01T00:00:00") || (date === "0001-01-01T00:00:00") )
            ? null
            : new Date(Moment(date).format("yyyy-MM-DD").replace(/-/g, '/'));
    return formattedDate;
};
const PurchaseOrderGrid = (props) => {
    const { internalId } = props;
    const {
        data: dataSource,
        error,
        isLoading,
    } = useSWR(
        `/api/Report/reportPurchaseOrder?InternalID=${internalId}`,
        fetcher
    );

    if (error) return <div>Failed to load</div>;
    if (isLoading) {
        return (
            <div style={{ paddingTop: "5px" }}>
                <LinearProgress /> Loading...
            </div>
        );
    }

    return (
        <>
            <div id="PurchaseOrderGrid">
                <DataGrid
                    dataSource={dataSource}
                    showBorders={true}
                    columnMinWidth={10}
                    columnAutoWidth={true}
                    height={"63vh"}
                    width={"100%"}
                    columnResizingMode={"widget"}
                >
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} >
                        <Search enabled={true} />
                    </HeaderFilter>
                    <Scrolling mode="virtual" />
                    <Column dataField="date" caption="PO Date" dataType={"date"} width={"150px"} format={"yyyy-MM-dd"} calculateCellValue={(rowData) => getFormattedDate(rowData, "date")} />
                    <Column dataField="documentNumber" caption="PO Number" dataType={"string"} width={"110px"} />
                    <Column dataField="vendorName" caption="Vendor Name" dataType={"string"} />
                    <Column dataField="location" caption="Location" dataType={"string"} />
                    <Column dataField="quantity" caption="Qty on PO" dataType={"number"} width={"100px"} />
                    <Column dataField="quantityOpen" caption="Qty Open" dataType={"number"} width={"100px"} />
                    <Column dataField="shipBy" caption="Ship By Date" dataType={"date"} width={"150px"} format={"yyyy-MM-dd"} calculateCellValue={(rowData) => getFormattedDate(rowData, "shipBy")} />
                    <Column dataField="vendorInitialETA" caption="Vendor Initial ETA" dataType={"date"} width={"150px"} format={"yyyy-MM-dd"} calculateCellValue={(rowData) => getFormattedDate(rowData, "vendorInitialETA")} />
                    <Column dataField="vendorETAUpdates" caption="Vendor ETA Updates" dataType={"string"} />
                    <Summary>
                        <TotalItem
                            column="quantityOpen"
                            summaryType="sum"
                            displayFormat="{0}"
                        />
                    </Summary>
                </DataGrid>
            </div>
        </>
    );
};

export default PurchaseOrderGrid;
