import _ from 'lodash';
import { FETCH_ADMIN_ROLES_LOADING, FETCH_ADMIN_USERS, FETCH_ADMIN_USERS_LOADING, FETCH_ADMIN_ROLES, ROLE_USER_ADDED, ROLE_USER_ADDED_LOADING, ROLE_USER_DELETE_LOADING, ROLE_USER_DELETE, ROLE_ADDED_LOADING, ROLE_ADDED, ROLE_DELETE_LOADING, ROLE_DELETE, ROLES_COLUMNS_PERMISSIONS_LOADING, ROLES_COLUMNS_PERMISSIONS, ROLES_FEATURE_PERMISSIONS_LOADING, ROLES_FEATURE_PERMISSIONS, ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING, ROLES_FEATURE_PERMISSIONS_UPDATE, ROLES_COLUMNS_PERMISSIONS_UPDATE_LOADING, ROLES_COLUMNS_PERMISSIONS_UPDATE, FETCH_CATEGORIES_ALL_LOADING, FETCH_CATEGORIES_ALL, FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM, FETCH_CATEGORIES_ALL_BY_PROGRAM, FETCH_PROGRAMS_ALL_LOADING, FETCH_PROGRAMS_ALL, USER_CATEGORIESPROGRAMS_LOADING, USER_CATEGORIESPROGRAMS} from '../actions/types';


const adminReducer = (state = { users: null, roles: null, rolesFeaturesPermissions: null, rolesColumnsPermissions: null }, action) => {

    let data = [];
    switch (action.type) {
        case FETCH_ADMIN_ROLES_LOADING:
            return { ...state, 'loading': true, 'roles': [] };
        case FETCH_ADMIN_ROLES:
            data = action.payload.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    userCount: x.users.length,
                    users: x.users
                }
            });
            return { ...state, 'loading': false, 'roles': data };
        case ROLE_USER_ADDED_LOADING:
            return { ...state, 'loading': true };
        case ROLE_USER_ADDED:
            data = action.payload.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    userCount: x.users.length,
                    users: x.users
                }
            });
            return { ...state, 'loading': false, 'roles': data };
        case ROLE_USER_DELETE_LOADING || ROLE_ADDED_LOADING || ROLE_DELETE_LOADING:
            return { ...state, 'loading': true };
        case ROLE_USER_DELETE || ROLE_ADDED || ROLE_DELETE:
            data = action.payload.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    userCount: x.users.length,
                    users: x.users
                }
            });
            return { ...state, 'loading': false, 'roles': data };
        case ROLE_ADDED_LOADING:
            return { ...state, 'loading': true };
        case ROLE_ADDED:
            data = action.payload.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    userCount: x.users.length,
                    users: x.users
                }
            });
            return { ...state, 'loading': false, 'roles': data };
        case ROLE_DELETE_LOADING:
            return { ...state, 'loading': true };
        case ROLE_DELETE:
            data = action.payload.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    userCount: x.users.length,
                    users: x.users
                }
            });
            return { ...state, 'loading': false, 'roles': data };
        case FETCH_ADMIN_USERS_LOADING:
            return { ...state, 'loading': true, 'users': [] };
        case FETCH_ADMIN_USERS:
            return { ...state, 'loading': false, 'users': action.payload };
        case ROLES_COLUMNS_PERMISSIONS_LOADING:
            return { ...state, 'loading': true, 'rolesColumns': [] };
        case ROLES_COLUMNS_PERMISSIONS:
            return { ...state, 'loading': false, 'rolesColumns': action.payload };
        case ROLES_FEATURE_PERMISSIONS_LOADING:
            return { ...state, 'loading': true, 'rolesFeatures': [] };
        case ROLES_FEATURE_PERMISSIONS:
            return { ...state, 'loading': false, 'rolesFeatures': _.mapKeys(action.payload, "idFeature") };
        case ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING:
            return { ...state, 'loading': false };
        case ROLES_FEATURE_PERMISSIONS_UPDATE:
            return { ...state, 'loading': false };
        case ROLES_COLUMNS_PERMISSIONS_UPDATE_LOADING:
            return { ...state, 'loading': false };
        case ROLES_COLUMNS_PERMISSIONS_UPDATE:
            return { ...state, 'loading': false };
        case FETCH_CATEGORIES_ALL_LOADING:
            return { ...state, 'categories': [], 'loading': true };
        case FETCH_CATEGORIES_ALL:
            return { ...state, 'categories': action.payload, 'loading': false };
        case FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM:
            return { ...state, 'categories_by_program': [], 'loading': true };
        case FETCH_CATEGORIES_ALL_BY_PROGRAM:
            return { ...state, 'categories_by_program': action.payload, 'loading': false };
        case FETCH_PROGRAMS_ALL_LOADING:
            return { ...state, 'programs': [], 'loading': true };
        case FETCH_PROGRAMS_ALL:
            return { ...state, 'programs': action.payload, 'loading': false };
        case USER_CATEGORIESPROGRAMS_LOADING:
            return { ...state, 'programCategories': [], 'loading': true };
        case USER_CATEGORIESPROGRAMS:
            return { ...state, 'programCategories': action.payload, 'loading': false };
        default:
            return state;
    }
};

export default adminReducer;