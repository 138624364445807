import "./ExcludedItemsL3m12m.css";

export const ExcludedItemsL3m12m = (props) => {
    const { dataSource } = props;

    const {excludedQuantityL3M, excludedQuantityL12M, totalExcludedQuantity}  = dataSource;
    const l3mPercentage = totalExcludedQuantity===0?0: (excludedQuantityL3M / totalExcludedQuantity * 100).toFixed(2);
    const l12mPercentage = totalExcludedQuantity===0?0: (excludedQuantityL12M / totalExcludedQuantity * 100).toFixed(2);

    const getIntegerData = (data) => {
        let intvalue = Math.ceil( data ); 
        return intvalue;
    }


    return (

        <div className="container">
            <div className="title-text">
                Quantities Excluded
            </div>
            <div class="grid-container">
                <div class="grid-item"></div>
                <div class="grid-item">Units</div>
                <div class="grid-item">% Excluded of all transactions</div>

                <div class="grid-item">Excluded from L12M</div>
                <div class="grid-item data-text">{excludedQuantityL12M}</div>
                <div class="grid-item data-text">{`${getIntegerData(l12mPercentage)}%`}</div>

                <div class="grid-item">Excluded from L3M:</div>
                <div class="grid-item data-text">{excludedQuantityL3M}</div>
                <div class="grid-item data-text">{`${getIntegerData(l3mPercentage)}%`}</div>
            </div>
        </div>
    );
}