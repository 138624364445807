/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Popup, TextBox } from "devextreme-react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./saveGridTemplate.css";
import { confirm, alert } from 'devextreme/ui/dialog';
import { CloneGridTemplateDialog } from "./GridTemplateClone/cloneGridTemplate";
import { GridTemplatesGrid } from "./SaveGridTemplatedGrid";
import ShareGridTemplateDialog from "./GridTemplateShare/ShareGridTemplate";
import { deleteGridTemplate, getGridTemplatesByUserProgramCategory, postGridTemplate, setSelectedTemplate, updateExistingTemplate, updateTemplateName } from "../../../../actions/gridTemplates/gridTemplatesActions";
import PropTypes from "prop-types";
import { renderButtons } from "./Functions/RenderButtons";
import { LinearProgress } from "@mui/material";

export const SaveGridTemplateDialog = (props) => {
    const { open, onClose, userId, programId, categoryId, storageKey, onTemplateChange } = props;
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const programs = useSelector(state => state?.userInfo.programs);
    const categories = useSelector(state => state?.userInfo.categories);


    const [state, setState] = useState({ "templateName": "", selectedTemplateId: 0, "open": { "showCloneGridTemplate": false, "showShareGridTemplate": false } });



    async function getGridData() {
        const response = await getGridTemplatesByUserProgramCategory(userId, programId, categoryId);
        setTemplates(response);
    };

    useEffect(() => {
        getGridData();
    }, [userId, programId, categoryId, open]);

    const getCurrentTemplate = (templates) => {
        if (templates) {
            let currentTemplate = templates.find(x => x.selected === true);
            return currentTemplate?.templateName;
        }
        return ""
    }

    const valueChanged = (data) => {
        setState({ ...state, templateName: data.value });
    }

    const updateSelectedTemplateData = async (id, template) => {
        if (template.selected) {
            localStorage.setItem(storageKey, template.template);
            onTemplateChange(template.template);
        } else {
            localStorage.setItem(storageKey, "");
            onTemplateChange(null);
        }
        await setSelectedTemplate(id, template.selected);
        setLoading(false);
    }


    const switchTemplate = async (id) => {
        setLoading(true);
        const updatedTemplates = templates.map((template) => ({
            ...template,
            selected: template.id === id ? !template.selected : false,
        }));
        setTemplates(updatedTemplates)
        const selectedTemplate = updatedTemplates.find((template) => template.id === id);
        updateSelectedTemplateData(id, selectedTemplate);
    };

    const showCloneGridTemplateDialog = (templateId) => {
        setState({
            ...state, open: {
                ...state.open, showCloneGridTemplate: true
            }, selectedTemplateId: templateId

        });

    }

    const showShareGridTemplateDialog = (templateId) => {
        setState({
            ...state, open: {
                ...state.open, showShareGridTemplate: true
            }, selectedTemplateId: templateId

        });
    }

    async function updateGridTemplateConfiguration(id) {
        const currentState = localStorage.getItem(storageKey);
        await updateExistingTemplate(id, currentState);
        await getGridData();
    }

    const updateGridTemplateDialog = async (id) => {
        const dialogResult =  await confirm("<i>Are you sure you want to update this template?</i>", "Confirm template update");
            if (dialogResult) {
                await updateGridTemplateConfiguration(id);
                alert("Template updated successfully", "Success");
            }
    }

    const deleteTemplate = async (id) => {
        const confirmDelete = confirm("<i>Are you sure you want to delete the template for this program and category?</i>", "Confirm template delete");
        confirmDelete.then((dialogResult) => {
            if (dialogResult) {
                const newState = templates.filter(x => x.id !== id);
                setTemplates(newState);
                deleteGridTemplate(id);
            }
        }
        );
    }

    const updateGridTemplateName = (data) => {
        updateTemplateName(data.data.id, data.data.templateName);
    }

    const saveColumnConfiguration = async () => {

        if (state.templateName === "") {
            alert("Template name is required", "Error");
        }
        else {
            const currentState = localStorage.getItem(storageKey); 
            if (currentState === null) {
                alert("No template to save", "Error");
                return;
            }
            let existingTemplate = templates.find(x => x.templateName === state.templateName);
            if (existingTemplate) {
                let updateTemplate = confirm("<i>The template name already exists do you want to overwrite this template?</i>", "Confirm template overwrite");
                updateTemplate.then(async (dialogResult) => {
                    if (dialogResult) {
                        await updateGridTemplateDialog(existingTemplate.id, currentState);
                    }
                });
            }
            else {
                await postGridTemplate(state.templateName, userId, programId, categoryId, currentState);
                await getGridData();
            }
        }
        setState({ ...state, templateName: "" })
    }


    const handleCloseDialog = (key, value) => {
        setState({ ...state, open: { ...state.open, [key]: value } });
    }

    const displayTitle = (programId, categoryId) => {

        if (programId === 0 && categoryId === 0){
            return `Templates for Global Grid`;
        }
        const program = programs?.filter(x => x.programId.toString() === programId.toString())[0]?.name
        const category = categories[programId]?.filter(x => x.categoryId === categoryId)[0]?.categoryName;
        return `Templates for Program ${program} category ${category}`;
    }

    const renderRowButtons = (row) => {
        return (
            renderButtons(row, switchTemplate, showShareGridTemplateDialog, updateGridTemplateDialog, showCloneGridTemplateDialog, deleteTemplate)
        );
    }

    const title = displayTitle(programId, categoryId);

    return (
        <div>
            <Popup
                visible={open}
                height={"65vh"}
                width={"40vw"}
                onHiding={() => onClose("saveColumnConfiguration", false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                resizeEnabled={false}
                title={title}
            >
                <div>
                    <div>
                        <div className="flex-item" style={{ fontWeight: "bolder", fontSize: "x-large" }}>
                            <span>Current Template: {getCurrentTemplate(templates)}</span>
                        </div>
                        <div className="flex-item">
                            <span>Template Name</span>
                        </div>
                        <div className="flex-item">
                            <TextBox maxLength={100} width={"20vw"} showClearButton={true}
                                value={state.templateName}
                                onValueChanged={valueChanged}
                            />
                        </div>
                        <div className="flex-item">
                            <Button
                                text="Add"
                                onClick={async () => await saveColumnConfiguration()} />
                        </div>
                    </div>
                    {loading ?
                        <div style={{ paddingTop: "5px" }}>
                            <LinearProgress /> Setting template...{" "}
                        </div>
                        :
                        <GridTemplatesGrid templates={templates} deleteTemplate={deleteTemplate} updateGridTemplateName={updateGridTemplateName} renderButtons={renderRowButtons} />
                    }
                </div>
            </Popup >
            <CloneGridTemplateDialog open={state.open.showCloneGridTemplate} onClose={handleCloseDialog} templateId={state.selectedTemplateId} />
            <ShareGridTemplateDialog open={state.open.showShareGridTemplate} onClose={handleCloseDialog} templateId={state.selectedTemplateId} />
        </div >
    );
}

SaveGridTemplateDialog.propTypes = {
    onClose: PropTypes.func,
    onTemplateChange: PropTypes.func,
    open: PropTypes.bool,
    programId: PropTypes.string,
    categoryId: PropTypes.string,
    storageKey: PropTypes.string,
    userId: PropTypes.string
};

export default SaveGridTemplateDialog;
