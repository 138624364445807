import { FETCH_NETSUITEINTEGRATION_LOADING, FETCH_NETSUITEINTEGRATION } from "../actions/types";


const netsuiteIntegrationReducer =  (state = {}, action) => {
    switch (action.type) {
        case FETCH_NETSUITEINTEGRATION_LOADING:
            return {...state, 'loading': true ,'response': null};
        case FETCH_NETSUITEINTEGRATION:
            return { ...state, 'response': action.payload ,'loading': false}
        default:
            return state;
    }
};

export default netsuiteIntegrationReducer;