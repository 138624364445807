import { DATAGRID_VALUE_CHANGED } from "../actions/types";

export const signalRReducer = (state = {}, action) => {
    switch (action.type) {
        case DATAGRID_VALUE_CHANGED:
            return { ...state, 'gridData': action.payload };
        case 'DATAGRID_EXPORT_COMPLETED':
            return { ...state, 'exportData': action.payload };
        default:
            return state;
    }
};
