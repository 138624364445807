import axios from "axios";
import { GetSilentTokent, redirectToLogin } from "../components/authentication/AuthenticationHelpers";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 500000,
});

apiClient.interceptors.request.use(
  async (config) => {
    const token = await GetSilentTokent();
    config.headers["Authorization"] = 'Bearer ' + token.accessToken ;
    return config;
  },
  (error) => {

    console.log("Error in request interceptor: ", error);
    return Promise.reject(error); 
  }
);

apiClient.interceptors.response.use(
  response => response,
  error => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
  });

export default apiClient;
