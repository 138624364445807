import { DataGrid, Column, Scrolling } from "devextreme-react/data-grid";
import { LinearProgress } from "@mui/material";

import { fetcher } from "../../../../actions";
import useSWR from "swr";

const VendorGrid = (props) => {
  const { internalId } = props;

  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(
    `/api/Report/reportVendorInventory?InternalID=${internalId}`,
    fetcher
  );

  if (error) return <div>Failed to load</div>;
  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      <div id="VendorGrid">
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"20vh"}
          width={"100%"}
          columnResizingMode={"widget"}
          wordWrapEnabled={true}
        >
          <Scrolling mode="virtual" />
          <Column
            dataField="VendorXREF"
            caption="Vendor XREF"
            alignment="left"
          />
          <Column
            dataField="vendorQty"
            caption="Vendor Quantity"
            alignment="left"
          />
          <Column
            dataField="vendorqtynotes"
            caption="Vendor Notes"
            alignment="left"
          />
        </DataGrid>
      </div>
    </>
  );
};

export default VendorGrid;
