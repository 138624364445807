import { putExclusions } from "../../actions";
import apiClient from "../../apis/items";

export const onCellPrepared = (e) => {
    if (e?.rowType === 'data' && e?.isAltRow === true) {
        e.cellElement.style.backgroundColor = "#f5f5f5";
    }
    if ((e?.data?.excludedQty !== 'Infinity' && e?.data?.excludedQty > e?.data?.ifQty) || e?.data?.statusName === 'System Modified') {
        e.cellElement.style.color = "#FF0000";
    }
    if (e.rowType === "data" && e.column.allowEditing) {
        e.cellElement.style.backgroundColor = "#f5f2d8";
    }
};

export const setEditableCells = (e) => {
    if (e?.value === "Infinity") {
        e.editorOptions.readOnly = true;
        return false;
    }
    else {
        e.editorOptions.readOnly = false;
        return true;
    }
}


export async function setCellValueExcludedQty(
    newData,
    value,
    currentRowData,
    dataSource,
) {
    async function sendData(id, payload) {
        await putExclusions(id, payload)
    }

    const index = dataSource.findIndex(x => x.id === currentRowData?.id);
    if (index !== -1) {
        dataSource[index].excludedQty = value;
    }
    const payload = [{
        op: "replace",
        path: "excludedQty",
        value: value
    }];
    sendData(currentRowData?.id, payload);
    newData[index] = value;
}

export async function setCellValueExclusionNotes(
    newData,
    value,
    currentRowData,
    dataSource,
) {
    async function sendData(id, payload) {
        await putExclusions(id, payload);
    }

    const index = dataSource.findIndex(x => x.id === currentRowData?.id);

    if (index !== -1) {
        dataSource[index].exclusionNote = value;
    }
    const payload = [{
        op: "replace",
        path: "exclusionNote",
        value: value
    }];
    sendData(currentRowData?.id, payload);
    newData[index] = value;
}


export const postExclusions = async (exclusionType, selectedName, selectedDocument, exclusionNote, customerItems) => {
    let dataToPost = null;
    let response = null;
    let promises = null;
    let allResponses = null;
    let allOk = null;

    switch (exclusionType) {
        case 1:
            dataToPost = {
                "internalId": null,
                "customerName": selectedName,
                "itemFulFillmentNumber": selectedDocument,
                "itemName": null,
                "ifQty": null,
                "excludedQty": null,
                "exclusionNote": exclusionNote,
                "statusCode": "ACT",
                "typeCode": "CUST"
            }
            response = await apiClient.post(`/api/Exclusions`, dataToPost);
            return response?.status;
        case 2:
            promises = customerItems?.map(async (item) => {
                const dataToPost = {
                    "internalId": item?.internalId,
                    "customerName": selectedName,
                    "itemFulFillmentNumber": selectedDocument,
                    "itemName": item?.name,
                    "ifQty": null,
                    "excludedQty": null,
                    "exclusionNote": item?.exclusionNote,
                    "statusCode": "ACT",
                    "typeCode": "CUSTXITEM"
                }
                response = await apiClient.post(`/api/Exclusions`, dataToPost);
                return response;
            })

            allResponses = await Promise.all(promises);
            // Check if all responses have status code 200
            allOk = allResponses.every(response => response?.status === 201);

            if (allOk) {
                // Handle successful responses
                return 201
            } else {
                return 400
            };
        case 3:
            dataToPost = {
                "internalId": null,
                "customerName": selectedName,
                "itemFulFillmentNumber": selectedDocument,
                "itemName": null,
                "ifQty": null,
                "excludedQty": null,
                "exclusionNote": exclusionNote,
                "statusCode": "ACT",
                "typeCode": "ITEMNUMBER"
            }
            response = await apiClient.post(`/api/Exclusions`, dataToPost);
            return response?.status;;
        case 4:
            promises = customerItems?.map(async (item) => {
                const dataToPost = {
                    "internalId": item?.internalId,
                    "customerName": selectedName,
                    "itemFulFillmentNumber": selectedDocument,
                    "itemName": item?.name,
                    "ifQty": item?.qty,
                    "excludedQty": item?.eqty,
                    "exclusionNote": item?.exclusionNote,
                    "statusCode": "ACT",
                    "typeCode": "ITEMIDQTY"
                }
                const response = await apiClient.post(`/api/Exclusions`, dataToPost);
                return response;
            })

            allResponses = await Promise.all(promises);

            // Check if all responses have status code 200
            allOk = allResponses.every(response => response?.status === 201);

            if (allOk) {
                // Handle successful responses
                return 201
            } else {
                return 400
            };
        default:
            break;
    }
}
