export const exclusionTypeOptions = [
  {
    id: 1,
    name: "Customer",
  },
  {
    id: 2,
    name: "Customer X Item",
  },
  {
    id: 3,
    name: "Item Fulfillment Number",
  },
  {
    id: 4,
    name: "Item Fulfillment Number X Item",
  },
];
