import { Breadcrumbs, Typography } from "@mui/material";
import { Button, Popup } from "devextreme-react";
import RelatedComponentsGrid from "./RelatedComponentsGrid";
import { Tooltip } from 'devextreme-react/tooltip';
import { ModalCloseButton } from "../../../tools/ModalCloseButton";

const RelatedComponentsModal = (props) => {
    const { open, onClose, parentData } = props;
    const renderTitle = () => {
        return <>
            <h3>Related Components</h3>
        </>
    }


    const renderCloseButton = () => (
        <div className="closeButtonContainer">
          <ModalCloseButton onClose={() => onClose()} />
        </div>
      );


    const renderBreadCrumb = () => {
        if (parentData) {
            return (
                <>
                    <Breadcrumbs>
                        <Typography color="text.primary">{parentData.listType}</Typography>
                        <Typography color="text.primary">System: {parentData.parent}</Typography>
                        <Typography color="text.primary">{parentData.name}</Typography>
                        <Button icon="info" id="about"/>
                        <Tooltip
                        target="#about"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                        >
                        <div>Red - Vendor is Brembo <br></br>
                            Yellow - Vendor is local <br></br>
                            Blue - Vendor is not defined.</div>
                        </Tooltip>
                    </Breadcrumbs>
                </>
            )
        }
    }

    const displayTitle = () => {
        return <>
            <div style={{ display: "flex", justifyContent: "space-between" }} >
                {renderTitle()}
                {renderCloseButton()}
            </div>

        </>
    }

    const renderRelatedGrid = () => {
        if (!parentData) return (<></>)
        return (<>
            <RelatedComponentsGrid parentData={parentData} />
        </>);
    }

    return (
        <div >
            <Popup id="popup"
                visible={open}
                onHiding={onClose}
                showTitle={true}
                titleRender={displayTitle}
                resizeEnabled={true}
            >
                {renderBreadCrumb()}
                {renderRelatedGrid()}
            </Popup>
        </div>
    );

}

export default RelatedComponentsModal