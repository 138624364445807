import { LinearProgress, Typography } from "@mui/material";
import { Button, List, RadioGroup, ScrollView } from "devextreme-react";
import { SetAllColumnsViewerByRole, fetcher, updateFeatureColumnsByRole } from "../../../../actions";
import useSWR from "swr";


const _ = require("lodash");

export const ColumnsByRole = (props) => {
    const { roleId } = props;
    const { isloading, data, mutate } = useSWR(`/api/RoleColumns/reportColumnPermissions?idRole=${roleId}`, fetcher);

    async function callUpdateFeatureColumnsByRole(roleId, idColumn, e) {
        await updateFeatureColumnsByRole(roleId, idColumn, e);
    }

    async function callSetColumnsViewerAllByRole(roleId) {
        await SetAllColumnsViewerByRole(roleId);
    }



    const setPermissionValue = async (e, item) => {
        // update the database here
        const newdata = data.map((x) => {
            if (item.IdColumn === x.IdColumn) {
                let permission = e;
                return { ...x, Permission: permission };
            }
            return x;
        });
        mutate(newdata, false);
        callUpdateFeatureColumnsByRole(roleId, item.IdColumn, e);
    }

    const setViewerAll = (roleId) => {
        const newdata = data.map((x) => {
            return { ...x, Permission: 1 };
        });
        mutate(newdata, false);
        callSetColumnsViewerAllByRole(roleId);
    }

    const renderItem = (item) => {
        const writePermissions = [
            { id: 0, text: 'No' },
            { id: 1, text: 'Viewer' },
            { id: 2, text: 'Editor' }
        ];
        const readPermissions = [
            { id: 0, text: 'No' },
            { id: 1, text: 'Viewer' }
        ];
        let permissions = writePermissions;
        if (item.Editable === 0) {
            permissions = readPermissions;
        }
        return <div>
            <div>Assigned: {item.NameColumn}</div>
            <div>
                <RadioGroup id="radio-group-with-selection" items={permissions} value={item.Permission}
                    valueExpr="id" displayExpr="text" onValueChange={async (e) => await setPermissionValue(e, item)} />
            </div>
        </div>;
    }


    if (isloading) {
        return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
    }

    return (
        <div id="ColumnsByRoleContainer">
            <div><br></br></div>
            <div>
                <Typography display="block" variant="body2">
                    Define access to grid columns depending on the selected role,
                </Typography>
                <Typography display="block" variant="body2">
                    None: The role with this permission has no access to the column
                </Typography>
                <Typography display="block" variant="body2">
                    Viewer: The role with this permission can only view but not edit anything
                </Typography>
                <Typography display="block" variant="body2">
                    Editor: The role with this permission can view and edit
                </Typography>
            </div>
            <div><br></br></div>
            <div><Button onClick={() => setViewerAll(roleId)}>Viewer All</Button></div>
            <div id="FeaturesByRoleGridContainer" style={{ height: "38vh", marginTop: "1vh" }}>
                <ScrollView width='100%' height='100%'>
                    <div className="list-container">
                        <List
                            dataSource={data}
                            height="100%"
                            itemRender={renderItem}
                            searchExpr="NameColumn"
                            searchEnabled={true}
                            searchMode="contains"
                        />
                    </div>
                </ScrollView>
            </div>
        </div>

    );
}


export default ColumnsByRole; 