import { Card, CardContent } from "@mui/material";
import { FileUploader, SelectBox, Toast, DateBox } from "devextreme-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsolidatedShipment, fetchFileUploadError, fetchVendorList, fetchLastUpdateFiles } from "../../actions";
import FilesGrid from "./filesGrid";
import "./filesMainBox.css";
import { GetSilentTokent } from "../authentication/AuthenticationHelpers";

const data = [{
    Name: 'Consolidated Shipments',
    Id: 1
},
{
    Name: 'Vendor Inventory Lists',
    Id: 2
}
];
const fileExtensions = ['.csv'];
const FilesMainBox = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    
    const lastFileUpdate = useSelector(state => state.items.netsuiteFileLastUpdate);
    const paramFileType = useSelector((state) => state.menu.props?.type ?? null);
    const dispatch = useDispatch();
    const [fileType, setFileType] = useState(1);
    const [uploadUrl, setUploadUrl] = useState(`${baseUrl}/api/Files/UploadConsolidatedShipment`);
    const [uploadData, setUploadData] = useState({ showGrid: false, data: null });
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        const GettokenAsync = async () => {
        const token = await GetSilentTokent();
        setAuthToken(token);
        }
        GettokenAsync();
    }, []);

    const [ErrorMessage, setErrorMessage] = useState({
        "visible": false,
        "message": "",
        "type": "error"
    });

    const changeFileType = (fileType) => {
        const FILE_TYPE_CONSOLIDATED_SHIPMENT = 1;
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const ENDPOINT_CONSOLIDATED_SHIPMENT = '/api/Files/UploadConsolidatedShipment';
        const ENDPOINT_VENDOR_INVENTORY_LIST = '/api/Files/UploadVendorInventoryList';

        dispatch(fetchLastUpdateFiles(FILE_TYPE_CONSOLIDATED_SHIPMENT));
        setErrorMessage({ ...ErrorMessage, "visible": false, "message": "" });

        const url = fileType === FILE_TYPE_CONSOLIDATED_SHIPMENT
            ? `${BASE_URL}${ENDPOINT_CONSOLIDATED_SHIPMENT}`
            : `${BASE_URL}${ENDPOINT_VENDOR_INVENTORY_LIST}`;

        setFileType(fileType);
        setUploadUrl(url);

        if (fileType === FILE_TYPE_CONSOLIDATED_SHIPMENT) {
            dispatch(fetchConsolidatedShipment());
        } else {
            dispatch(fetchVendorList());
        }
    }

    useEffect(() => {
        if (paramFileType && paramFileType !== fileType) {
            let value = parseInt(paramFileType)
            setFileType(value);
        }
    }, [fileType, paramFileType]);

    useEffect(() => {
        changeFileType(fileType);
    }, [fileType]);

    const renderLastFileUpdateName = (lastFileUpdate, type) => {
        let name;
        if (lastFileUpdate?.length > 0) {
            if (lastFileUpdate[0].Code === "filesConsolidate" && type === 0) {
                name = lastFileUpdate[0].NameFile;
            } else if (lastFileUpdate.length > 1 && type === 1) {
                name = lastFileUpdate[1].NameFile;
            }
        }
        return name;
    }

    const renderLastFileUpdateDate = (lastFileUpdate, type) => {
        if (lastFileUpdate?.length > type) {
            const datetime = new Date(lastFileUpdate[type].UpdatedDate);
            const year = datetime.getFullYear();
            const month = String(datetime.getMonth() + 1).padStart(2, '0');
            const day = String(datetime.getDate()).padStart(2, '0');
            const hour = String(datetime.getHours()).padStart(2, '0');
            const minute = String(datetime.getMinutes()).padStart(2, '0');
            const second = String(datetime.getSeconds()).padStart(2, '0');

            return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
        }
        return null;
    }

    const onFileUploaded = (e) => {
        dispatch(fetchLastUpdateFiles(1))
        const result = JSON.parse(e.request.response);
        if (result.hasErrors === false) {

            if (result.hasWarnings) {
                setErrorMessage({ ...ErrorMessage, "visible": true, type: "warning", "message": result.message })
            }
            setUploadData({ showGrid: true, data: result });
            if (fileType === 1) {
                dispatch(fetchConsolidatedShipment());
            }
            else {
                dispatch(fetchVendorList());
            }
        }
        else {

            dispatch(fetchFileUploadError());
            setErrorMessage({ ...ErrorMessage, "visible": true, type: "error", "message": 'Upload failed, There  are some validation errors' })
        }

    }

    const onUploadError = (e) => {
        console.log(e);
        setErrorMessage({ ...ErrorMessage, "visible": true, "message": e.request.response })
    }

    const onHiding = () => {
        setErrorMessage({ ...ErrorMessage, "visible": false, "message": "" })
    }

    const onValueChanged = (e) => {
        changeFileType(e.value);
    }

    const controlContainer = {
        paddingLeft: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"

    }

    if (!authToken) return (<></>);

     const headers = {
        authorization: 'bearer ' + authToken.accessToken
    }

    return (<>
        <div id="container" style={{ display: "flex" }}>
            <div id="controls" style={{ width: "40vw" }}>
                <div style={{ maxWidth: "95%", paddingTop: "3px", paddingLeft: "1vw" }}>

                    <Card>
                        <CardContent >
                            <div style={controlContainer}>
                                <div className="dx-fieldset" style={{ margin: "0px", minWidth: "90%" }}>
                                    <div className="dx-field" style={{ Width: "100%" }}>
                                        <div className="dx-field-label">File Type</div>
                                        <div className="dx-field-value" >

                                            <SelectBox
                                                dataSource={data}
                                                displayExpr="Name"
                                                valueExpr="Id"
                                                onValueChanged={onValueChanged}
                                                defaultValue={fileType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div style={{ maxWidth: "95%", paddingTop: "3px", paddingLeft: "1vw" }}>
                    <Card>
                        <CardContent >
                            <div >
                                <div className="main-block">
                                    <div className="file-uploader-block" >
                                        <FileUploader width={"20vw"} multiple={false} uploadMethod="Post" uploadHeaders={headers} uploadMode="useButtons" uploadUrl={uploadUrl} allowedFileExtensions={fileExtensions} onUploadError={onUploadError} onUploaded={onFileUploaded} />
                                        <span className="note">{'Allowed file extensions: '}
                                            <span>.csv</span>
                                            .
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <div>
                            <CardContent>
                                <div className="main-block">
                                    <div className="file-uploader-block" >
                                        <span className="note">
                                            Last Consolidated File Information
                                        </span>
                                        <div>
                                            <span className="note">
                                                File Name:
                                            </span>
                                        </div>
                                        <div>
                                            <span className="note">
                                                {renderLastFileUpdateName(lastFileUpdate, 0)}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="note">
                                                Last Update Date Time (PST):
                                            </span>
                                        </div>
                                        <div>
                                            <span className="note">
                                                <div style={{ width: "40%", textAlign: "left", float: "left" }}>
                                                    <DateBox
                                                        value={renderLastFileUpdateDate(lastFileUpdate, 0)}
                                                        displayFormat={'yyyy-MM-dd'}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div style={{ width: "40%", textAlign: "left", float: "right" }}>
                                                    <DateBox
                                                        value={renderLastFileUpdateDate(lastFileUpdate, 0)}
                                                        type={"time"}
                                                        displayFormat={'shortTime'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                        <br /><br />
                        <CardContent>
                            <div className="main-block">
                                <div className="file-uploader-block" >
                                    <span className="note">
                                        Last Vendor File Information
                                    </span>
                                    <div>
                                        <span className="note">
                                            File Name:
                                        </span>
                                    </div>
                                    <div>
                                        <span className="note">
                                            {renderLastFileUpdateName(lastFileUpdate, 1)}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="note">
                                            Last Update Date Time (PST):
                                        </span>
                                    </div>
                                    <div>
                                        <span className="note">
                                            <div style={{ width: "40%", textAlign: "left", float: "left" }}>
                                                <DateBox
                                                    value={renderLastFileUpdateDate(lastFileUpdate, 1)}
                                                    displayFormat={'yyyy-MM-dd'}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div style={{ width: "40%", textAlign: "left", float: "right" }}>
                                                <DateBox
                                                    value={renderLastFileUpdateDate(lastFileUpdate, 1)}
                                                    type={"time"}
                                                    displayFormat={'shortTime'}
                                                    disabled={true}
                                                />
                                            </div>
                                        </span>
                                        <br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card >
                </div >
            </div>
            <div id="grid" style={{ width: "50vw" }}>

                <div className="SelectContainer">
                    <Toast
                        visible={ErrorMessage.visible}
                        message={ErrorMessage.message}
                        type={ErrorMessage.type}
                        onHiding={onHiding}
                        displayTime={10000}
                        hideOnOutsideClick={true}
                    />
                    <FilesGrid showGrid={uploadData.showGrid} data={uploadData.data} filetype={fileType} />
                </div>
            </div>
        </div>
    </>
    );
}

export default FilesMainBox;
