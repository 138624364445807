import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { RequestProfileData } from "./../../common/authentication";
import { appInsights } from "./../../common/ApplicationInsights";
import { Drawer } from "devextreme-react";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import NavigationList from "../../NavigationList";
import "./drawer.css";
import { LogoutButton } from "./LogoutButton";



export function MenuWrapper(props) {
    const { origin } = props;
    const { instance, accounts } = useMsal();
    const userDataLoading = useSelector(state => state.userInfo.loading);
    const authenticatedUsed = useSelector(state => state.userInfo.userAuthenticated);
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        appInsights.trackPageView("MainGrid");
        RequestProfileData(instance, accounts, authenticatedUsed, dispatch);
    }, []);



    const onOutsideClick = () => {
        setOpened(false);
    }

    if (userDataLoading) {
        return <></>
    }

    return (
        <>
            <Toolbar className={"main-toolbar"} >
                <Item location="before"
                    widget="dxButton"
                    options={{
                        icon: 'menu',
                        onClick: () => setOpened(!opened)
                    }} />
                <Item location="after">
                    <LogoutButton />
                </Item>
            </Toolbar>
            <Drawer
                opened={opened}
                openedStateMode={'shrink'}
                position={'left'}
                revealMode={'expand'}
                closeOnOutsideClick={onOutsideClick}
                render={() => <NavigationList origin={origin} />}>
                <div id="content" className="dx-theme-background-color">
                    {props.children}
                </div>
            </Drawer>
        </>

    );

}