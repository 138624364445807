import { Link } from '@mui/material';
import React from 'react';




const LinkCellBig = (cellData, action, params) => {

  if (cellData.value == 0) {
    return <>{cellData.value}</>
  }

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          return action(cellData, params)
        }}
        style={{ color: "#626262", fontFamily: "verdana", fontSize: "14px", marginTop: "-2px" }}
      >
        {cellData.value}
      </Link>
    </div>

  );
}
export default LinkCellBig;
