export const renderItemFulfillmentNumber = (e) => {
    if (e?.data?.itemFulfillmentNumber === null || e?.data?.itemFulfillmentNumber === "") {
        return <></>
    }
    if (e?.data?.itemFulfillmentNumber === "Infinity") {
        return <>{"∞"}</>
    }
    return <>{`${e.text}`}</>
}


export const renderItemName = (e) => {
    if (e?.data?.itemName === null || e?.data?.itemName === "") {
        return <></>
    }
    if (e?.data?.itemName === "Infinity") {
        return <>{"∞"}</>
    }
    return <>{`${e.text}`}</>
}

export const renderIFQty = (e) => {
    if (e?.data?.ifQty === null || e?.data?.ifQty === "") {
        return <></>
    }
    if (e?.data?.ifQty === "Infinity") {
        return <>{"∞"}</>
    }
    return <>{`${e.text}`}</>
}


export const renderExclusionQty = (e) => {
    if (e?.data?.excludedQty === null || e?.data?.excludedQty === "") {
        return <></>
    }
    if (e?.data?.excludedQty === "Infinity") {
        return <>{"∞"}</>
    }
    return <>{`${e.text}`}</>
}
