import apiClient from "../apis/items";

export const getGridTemplateShareUsersByTemplateId = async (templateId) => {
  const response = await apiClient.get(`/api/GridTemplatesShare/${templateId}`);
  return response?.data;
};

export const getGridTemplateShareUsersRoleByTemplateId = async (templateId) => {
  const response = await apiClient.get(`/api/GridTemplatesShare/gridTemplateUserByRole/${templateId}`);
  return response?.data;
};

export const updateGridTemplateShare = async (templateId, data) => {
  const GridTemplatesUsersShared = await apiClient.post(
    `/api/GridTemplatesShare/${templateId}`,
    data
  );
  return GridTemplatesUsersShared;
};
