import {
  DataGrid,
  Column,
  Summary,
  GroupItem,
  Scrolling,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  ColumnFixing,
  Toolbar,
  Item
} from "devextreme-react/data-grid";
import { useCallback, useState } from "react";
import RelatedComponentsModal from "../RelatedComponents/RelatedComponentsModal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { ReadMore } from "@mui/icons-material";
import Moment from "moment";
import "./kitsGrid.css";
import { GetColumnsByGrid, customRenderColumn } from "../../../../common/Columns";
import { GridTooltips } from "../../../gridTooltip/gridTooltips";
import { Button } from "devextreme-react";

const handleRowPrepared = (e) => {
  if (e.rowType !== "group" && e.rowType !== "header") {
    if (e.key != undefined) {
      e.rowElement.style.backgroundColor = e.key["Color"];
    }
  }
};

const handleCellPrepared = (e) => {
  if (e.rowType === "data") {
    if (e.column.name === "bo") {
      var elem = e.cellElement;
      elem.style.setProperty("cursor", "pointer", "important");
    }
  }
};

const renderActionButton = (e) => {
  if (e.row.rowType === "data") {
    return (
      <div
        style={{ cursor: "pointer", display: "inline-block" }}
        onClick={() => console.log("clicked")}
      >
        <ReadMore />
      </div>
    );
  }
};

function renderColumn(columnKey, component) {
  const extraProps = { cssClass: "tooltip-target-" + columnKey };
  return customRenderColumn(columnKey, component, extraProps);
}

const KitsGrid = (props) => {
  const columns = GetColumnsByGrid(2);
  const [relatedComponentsModalOpen, setRelatedComponentsModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandAll, setexpandAll] = useState(true); // [1
  const { dataSource } = props;

  let gridData = [];
  if (dataSource) {
    gridData = dataSource.map((obj) => {
      return {
        ...obj,
        creationDate:
          obj.creationDate === "0001-01-01T00:00:00"
            ? ""
            : Moment(obj.creationDate).format("yyyy-MM-DD"),
      };
    });
  }

  const handleCellClick = useCallback((e) => {
    if (e.key != undefined) {
      console.log(e);
      if (e.rowType === "data") {

        setSelectedItem(e.data);
        setRelatedComponentsModalOpen(true);
      }
    }
  }, []);

  const handleCloseDialog = () => {
    setRelatedComponentsModalOpen(false);
  };

  const togleExpandAll = () => {
    setexpandAll(!expandAll);
  };

  const renderGroupCell = (options) => {
    if (options.value === "Public") {
      return (
        <>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <RemoveRedEyeOutlinedIcon style={{ color: "green" }} />  {options.value}
          </div>
        </>
      );
    } else if (options.value === "Non Public") {
      return (
        <>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <VisibilityOffOutlinedIcon style={{ color: "orange" }} />  {options.value}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <VisibilityOffOutlinedIcon />  {options.value}
          </div>
        </>
      );
    }
  };

  const renderRelatedComponentsModal = (open, item, closeDialogMethod) => {
    if (open === false) return <></>;
    return (
      <RelatedComponentsModal
        open={open}
        parentData={item}
        onClose={closeDialogMethod}
      />
    );

  }

  return (
    <>
      <DataGrid
        dataSource={gridData}
        showBorders={true}
        onRowPrepared={handleRowPrepared}
        onCellPrepared={handleCellPrepared}
        columnMinWidth={50}
        columnAutoWidth={true}
        height={"90%"}
        width={"100%"}
        onCellClick={handleCellClick}
        columnResizingMode={"widget"}
        filterMode={"withAncestors"}
      >
        <Toolbar>
          <Item location="before">
            <Button icon="expand" onClick={togleExpandAll} id="kits_expand_collapse" />
          </Item>
        </Toolbar>
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <ColumnFixing enabled={true} />
        <Scrolling mode="virtual" />
        <Grouping autoExpandAll={expandAll} />
        {renderColumn("listType", <Column dataField="listType" groupIndex={0} groupCellRender={renderGroupCell} />)}
        {renderColumn("parent", <Column dataField="parent" groupIndex={1} caption="System" />)}
        {renderColumn("name", <Column dataField="name" caption="System Option" />)}
        {renderColumn("creationDate", <Column dataField="creationDate" format={"yyyy-MM-dd"} dataType={"date"} displayFormat="yyyy-mm-dd" caption="Creation Date" />)}
        {renderColumn("available", <Column dataField="available" caption="Kit Potential" />)}
        {renderColumn("numBO", <Column dataField="numBO" caption="BO" />)}
        {renderColumn("valcommitted", <Column dataField="valcommitted" caption="Committed" />)}
        {renderColumn("sales12M", <Column dataField="sales12M" caption="L12M Qty Sold" />)}
        {renderColumn("specialSystem", <Column dataField="specialSystem" caption="Exclusivity" />)}
        {renderColumn("Actions", <Column type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={renderActionButton} />)}
        <Summary>
          <GroupItem
            column="sales12M"
            summaryType="sum"
            valueFormat="#,##0.##"
            showInGroupFooter={false}
            alignByColumn={true}
            displayFormat="{0}"

          />
          <GroupItem
            column="available"
            summaryType="min"
            showInGroupFooter={false}
            alignByColumn={true}
            displayFormat="System Potential {0}"
          />
          <GroupItem
            column="numBO"
            summaryType="sum"
            valueFormat="#,##0.##"
            showInGroupFooter={false}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="valcommitted"
            summaryType="sum"
            valueFormat="#,##0.##"
            showInGroupFooter={false}
            alignByColumn={true}
            displayFormat="{0}"
          />
        </Summary>
      </DataGrid>
      <GridTooltips columns={columns} />
      {renderRelatedComponentsModal(relatedComponentsModalOpen, selectedItem, handleCloseDialog)}
    </>
  );
};

export default KitsGrid;
