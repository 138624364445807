import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";
import Moment from 'moment';
import { useSelector } from "react-redux";


const FilesGrid = (props) => {
    const data = useSelector(state => state.uploadedData.data);
    const hasErrors = useSelector(state => state.uploadedData.error);
    const errors =  useSelector(state => state.uploadedData.errorList);
    const {filetype} = props;
if (hasErrors){
    if (!errors) {
        return <></>
    }
    return (
        <>
            <div id="filesGrid" style={{width:"50vw"}}>
                <DataGrid
                    dataSource={errors}
                    showBorders={true}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    height={"70vh"}
                    allowColumnResizing={true}
                    columnResizingMode = {'nextColumn'}

                >
                    <Scrolling mode="virtual" />
                    <Column dataField="internalID" caption="Internal Id"  width={100} allowEditing={false} />
                    <Column dataField="comments"  caption="Comments" allowEditing={false} />
                    <Column dataField="data"  width={300} caption="Data" allowEditing={false} />
                    <Column dataField="LastDateModified" width={150} caption="Date"  allowEditing={false} />
                    
                </DataGrid>

            </div>

        </>
    );
}
else
{

    if (!data) {
        return <></>
    }
    let gridData = [];
    gridData = data.map(obj => {
        return { ...obj, creationDate: Moment(obj.lastModifiedDate).format('DD/MMM/yyyy') };
    });

if (filetype===1){ //consolidated shipment
    return (
        <>
            <div id="filesGrid" style={{width:"50vw"}}>
                <DataGrid
                    dataSource={gridData}
                    showBorders={true}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    height={"70vh"}
                    columnResizingMode = {'nextColumn'}
                >
                    <Scrolling mode="virtual" />
                    <Column dataField="internalID" caption="Internal Id" allowEditing={false} />
                    <Column dataField="item" caption="Item" allowEditing={false} />
                    <Column dataField="shipping"  caption="Shipping" allowEditing={false} />
                    <Column dataField="etaSummary" caption="ETA Summary"   allowEditing={false} />
                    <Column dataField="lastModifiedDate" caption="Date"   allowEditing={false} />                   
                </DataGrid>

            </div>

        </>
    );

}
else{  // Vendor Inventory
    return (
        <>
            <div id="filesGrid" style={{width:"50vw"}}>
                <DataGrid
                    dataSource={gridData}
                    showBorders={true}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    height={"70vh"}
                    columnResizingMode = {'nextColumn'}

                >
                    <Scrolling mode="virtual" />                
                    <Column dataField="internalID" caption="Internal Id" allowEditing={false} />
                    <Column dataField="itemName" caption="Item Name" allowEditing={false} />
                    <Column dataField="VendorXREF"  caption="Vendor XREF" allowEditing={false} />
                    <Column dataField="vendorQty" caption="Vendor QTY"   allowEditing={false} />
                    <Column dataField="vendorQtyNotes" caption="Vendor QTY Notes"   allowEditing={false} />
                    <Column dataField="lastModifiedDate" caption="Date"   allowEditing={false} />
                </DataGrid>

            </div>

        </>
    );

}
  
}

}




export default FilesGrid