import PageviewIcon from '@mui/icons-material/Pageview';
import { Validator, RequiredRule } from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";

export const ColumnsFilterReview = (props) => {
    const { filterRows, filterName, setFilterName, } = props;

    const filterNameTextBoxStyle = filterName === null ? { marginBottom: "0.625rem", marginTop: "0.625rem", borderColor: "#880808" } : { marginBottom: "0.625", marginTop: "0.625" };
    const logicalOperatorOptions = [
        {
            id: 1, text: 'Or'
        },
        {
            id: 2, text: 'And'
        },
    ];

    const getLogicalOperatorText = (id) => {
        return logicalOperatorOptions?.find(
            a => a.id === id
        )?.text;
    }


    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ marginTop: "0.1rem" }}>
                    <PageviewIcon fontSize="large" />
                </div>
                <div >
                    <div>
                        <span style={{ fontSize: "2em", fontFamily: "verdana", fontWeight: "bold", marginLeft: "0.5rem", marginBottom: "3rem" }}>Preview</span><br />
                    </div>
                </div>
            </div>
            <div className="flex-item" style={{ marginTop: "2rem" }}>
                <span>Filter Name *</span>
            </div>
            <TextBox
                onChange={(e) => setFilterName(e.component._changedValue)}
                placeholder="Add Filter Name"
                defaultValue={filterName}
                style={filterNameTextBoxStyle}
            >
                <Validator>
                    <RequiredRule IsRequired="true" message="field is required" />
                </Validator>
            </TextBox>
            <div>
                {filterRows?.map((filterRow) => (
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1rem", backgroundColor: "#fcfcfc", paddingTop: "1rem", paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: "1em", marginLeft: "0.7rem", marginRight: "1rem" }}>{filterRow?.column} </span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "1em", color: "#2b7cff", marginRight: "1rem" }}>{filterRow?.operatorName} </span><br />
                            </div>
                            {filterRow?.filterValue2 !== null && <div>
                                <span style={{ fontSize: "1em", marginRight: "0.2rem" }}>{`${filterRow?.filterValue2} -`} </span><br />
                            </div>}
                            <div>
                                <span style={{ fontSize: "1em", marginRight: "1rem" }}>{filterRow?.filterValue} </span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "1em", color: "#0f6bff" }}>{getLogicalOperatorText(filterRow?.logicalOperator)} </span><br />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div >
        </div >
    );
}
