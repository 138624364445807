import { Chart } from "devextreme-react";
import { Label } from "devextreme-react/cjs/bar-gauge";
import { Series } from "devextreme-react/cjs/chart";

export const ExcludedItemsByYear = (props) => {
    const { dataSource } = props;

    const sortedDataSourse = dataSource.sort((a, b) => a.year - b.year);
    return (
        <div className="Container">
            <div className="title-text">
                Quantities Excluded By Year
            </div>
            <div >
                <Chart se id="chart" dataSource={sortedDataSourse} style={{ height: "25vh" }}>
                    <Series
                        valueField="sum"
                        argumentField="year"
                        name="Excluded Items by Year"
                        type="bar"
                        color="#ffaa66" >

                        <Label
                            visible={true}
                            backgroundColor="#c18e92"
                        />
                    </Series>
                </Chart>
            </div>
        </div>
    );
}

export default ExcludedItemsByYear;