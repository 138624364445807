import { DataGrid, Column, Scrolling, HeaderFilter } from "devextreme-react/data-grid";
import useSWR from "swr";
import { fetcher } from "../../actions";
import { LinearProgress } from "@mui/material";

export const ExclusionsHistoryGrid = () => {
    const {
        data: dataSource,
        error,
        isLoading,
    } = useSWR(
        `/api/Exclusions/log?pageNumber=1&pageSize=100`,
        fetcher
    );
    if (error) return <div>Failed to load</div>;
    if (isLoading) {
        return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
    }

    return (
        <div>
            <DataGrid
                dataSource={dataSource?.data}
                showBorders={true}
                columnAutoWidth={true}
                height={"65vh"}
                width={"100%"}
                columnResizingMode={'widget'}
                filterMode={'withAncestors'}
            >
                <Scrolling mode="virtual" />
                <Column
                    dataField="dateModified"
                    caption="Modified Date Time"
                    alignment="left"
                    format={"yyyy-MM-dd HH:mm:ss"} dataType={"date"}
                />
                <Column
                    dataField="userDisplayName"
                    caption="User Name"
                    alignment="left"
                />
                <Column
                    dataField="customerName"
                    caption="Customer Name"
                    alignment="left"
                />
                <Column
                    dataField="itemFulfillmentNumber"
                    caption="IF Number"
                    alignment="left"
                />
                <Column
                    dataField="itemName"
                    caption="Item Name"
                    alignment="left"
                />
                <Column
                    dataField="field"
                    caption="Field"
                    alignment="left"
                />
                <Column
                    dataField="oldValue"
                    caption="Old Value"
                    alignment="left"
                />
                <Column
                    dataField="newValue"
                    caption="New Value"
                    alignment="left"
                />
                <HeaderFilter visible={true} />
            </DataGrid>
        </div>
    );
};
