import {
    FETCH_CATEGORIES, FETCH_CATEGORIES_LOADING, FETCH_FEATUREPERMISSIONS, FETCH_FEATUREPERMISSIONS_LOADING, FETCH_MOHSELECTVALUES,
    FETCH_MOHSELECTVALUES_LOADING, FETCH_PROGRAMS, FETCH_PROGRAMS_LOADING, FETCH_ROLEUSERS, FETCH_ROLEUSERS_LOADING, FETCH_USERINFO,
    FETCH_USERINFO_LOADING, FETCH_USERPERMISSIONS, FETCH_USERPERMISSIONS_LOADING, USER_AUTHENTICATED, FETCH_COLUMNS_LOADING, FETCH_COLUMNS,
    CHANGE_SELECTED_PROGRAM, USER_INFORMATION_LOADED, CHANGE_SELECTED_CATEGORY, FETCH_ROLEUSERS_ERROR, OPEN_WIZARD_MODAL
} from "../actions/types";
import _ from 'lodash';

const userReducer = (state = { error: false, loading: true, user: null, selectedRole: null, permissions: [], roles: [], userAuthenticated: null, selectedProgram: 0, selectedCategory: 0, openWizardModal: false }, action) => {
    switch (action.type) {
        case FETCH_USERINFO_LOADING:
            return { ...state, user: null, 'loading': true };
        case FETCH_USERINFO:
            return { ...state, user: action.payload, 'loading': false }
        case FETCH_USERPERMISSIONS_LOADING:
            return { ...state, 'permissions': null };
        case FETCH_USERPERMISSIONS:
            return { ...state, 'permissions': action.payload, 'selectedRole': action.selectedRole };
        case FETCH_ROLEUSERS_LOADING:
            return { ...state, roles: null };
        case FETCH_ROLEUSERS:
            return { ...state, 'roles': action.payload };
        case FETCH_ROLEUSERS_ERROR:
            return { ...state, 'error': true, 'loading': false }
        case FETCH_CATEGORIES_LOADING:
            return { ...state, 'categories': null };
        case FETCH_CATEGORIES:
            return { ...state, 'categories': _.groupBy(action.payload, "programId"), 'loading': false }
        case FETCH_PROGRAMS_LOADING:
            return { ...state, 'programs': null };
        case FETCH_COLUMNS_LOADING:
            return { ...state, 'columns': null };
        case FETCH_PROGRAMS:
            return { ...state, 'programs': action.payload }
        case FETCH_COLUMNS:
            return { ...state, 'columns': action.payload }
        case FETCH_FEATUREPERMISSIONS_LOADING:
            return { ...state, 'feature': null }
        case FETCH_FEATUREPERMISSIONS:
            return { ...state, 'feature': action.payload }
        case FETCH_MOHSELECTVALUES_LOADING:
            return { ...state, 'mohSelect': null }
        case FETCH_MOHSELECTVALUES:
            return { ...state, 'mohSelect': action.payload }
        case USER_AUTHENTICATED:
            return { ...state, 'userAuthenticated': action.payload }
        case CHANGE_SELECTED_PROGRAM:
            return { ...state, 'selectedProgram': action.programId }
        case CHANGE_SELECTED_CATEGORY:
            return { ...state, 'selectedCategory': action.categoryId }
        case OPEN_WIZARD_MODAL:
            return { ...state, 'openWizardModal': action.openWizardModal }
        case USER_INFORMATION_LOADED:
            return { ...state, 'loading': false }
        default:
            return state;
    }
};

export default userReducer;
