import { Scrolling } from "devextreme-react/data-grid";
import PivotGrid, { FieldChooser } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { LinearProgress } from "@mui/material";

import { fetcher } from "../../../../actions";
import useSWR from "swr";

const InventoryGrid = (props) => {
  const { internalId } = props;

  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(
    `/api/Report/reportDetailInventory?InternalID=${internalId}`,
    fetcher
  );

  const dataSourcePivot = new PivotGridDataSource({
    fields: [
      {
        caption: "Group",
        width: 120,
        dataField: "mainGroup",
        expanded: true,
        area: "row",
      },
      {
        caption: "SubGroup",
        width: 60,
        dataField: "subgroup",
        expanded: true,
        area: "row",
      },
      {
        caption: "showName",
        dataField: "showName",
        expanded: true,
        width: 100,
        area: "row",
        selector(data) {
          return `${data.showName}`;
        },
      },
      {
        caption: "QoH",
        dataField: "qoH",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "Comm",
        dataField: "comm",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "Avail",
        dataField: "avail",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "BO",
        dataField: "bo",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "PO",
        dataField: "po",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "Mod",
        dataField: "mod",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "Scr Dent Refinish",
        dataField: "scr",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
      {
        caption: "Avail + PO + Mod - BO",
        dataField: "avapomodbo",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
    ],
    store: dataSource,
  });

  const onCellPrepared = (e) => {
    if (e?.cell?.rowType === 'D') {
      e.cellElement.style.backgroundColor = "#fff";
    }
  }

  if (error) return <div>Failed to load</div>;

  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      <div id="InventoryGrid">
        <PivotGrid
          id="inventory"
          dataSource={dataSourcePivot}
          allowSortingBySummary={true}
          allowSorting={true}
          allowFiltering={true}
          allowExpandAll={true}
          height={"100%"}
          showBorders={true}
          onContentReady={true}
          onCellPrepared={(e) => onCellPrepared(e)}
        >
          <FieldChooser enabled={false} />
          <Scrolling mode="virtual" />
        </PivotGrid>
      </div>
    </>
  );
};

export default InventoryGrid;
