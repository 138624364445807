import { FETCH_ITEMS, FETCH_ITEMS_LOADING, FETCH_NETSUITE_LASTUPDATE, FETCH_NETSUITE_LASTUPDATE_LOADING, POST_ITEMS, RESET_ITEMS, 
    FETCH_NETSUITE_FILELASTUPDATE_LOADING, FETCH_NETSUITE_FILELASTUPDATE, FETCH_CATEGORIES, FETCH_ITEMS_BY_CATEGORY_LOADING, 
    FETCH_ITEMS_BY_CATEGORY } from "../actions/types";
import _ from 'lodash';

const itemReducer = (state = { loading: false, items: null, netsuiteLastUpdate: null }, action) => {
    switch (action.type) {
        case FETCH_ITEMS_LOADING:
            return { ...state, loading: true, items: null };
        case FETCH_ITEMS:
            return { ...state, items: _.groupBy(action.payload, 'categoryId'), loading: false }
        case FETCH_ITEMS_BY_CATEGORY_LOADING:
            return { ...state, loading: true };

        case FETCH_ITEMS_BY_CATEGORY:
            return {
                ...state,
                items: { ...state.items, [action.programId]: { ...state.items[action.programId], [action.categoryId]: action.payload } },
                loading: false
            };
        case POST_ITEMS:
            return {
                ...state, loading: false, items: {
                    ...state.items,
                    [action.payload.Id]: action.payload
                }
            };
        case RESET_ITEMS:
            var programs = {...state.Items};
            Object.keys(programs).forEach(key => {
                var category = programs[key];
                Object.keys(category).forEach(key => {
                    category[key] = null;
                });
            });
            return { ...state, 'loading': false, 'Items': programs };
        case FETCH_NETSUITE_LASTUPDATE_LOADING:
            return { ...state, netsuiteLastUpdate: null, loading: true }
        case FETCH_NETSUITE_LASTUPDATE:
            return { ...state, netsuiteLastUpdate: action.payload, loading: false }
        case FETCH_NETSUITE_FILELASTUPDATE_LOADING:
            return { ...state, netsuiteFileLastUpdate: null, loading: true }
        case FETCH_NETSUITE_FILELASTUPDATE:
            return { ...state, netsuiteFileLastUpdate: action.payload, loading: false }
        case FETCH_CATEGORIES:
            var programs = _.groupBy(action.payload, "programId");
            Object.keys(programs).forEach(key => {
                var category = programs[key];
                Object.keys(category).forEach(key => {
                    category[key] = null;
                });
            });
            return { ...state, 'Items': programs, 'loading': false }
        default:
            return state;
    }
};

export default itemReducer;