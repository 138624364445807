import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { changedMenu } from "../../../actions/menu";
import PropTypes from "prop-types";
import { MENU_GLOBAL_GRID } from "../../../actions/menuTypes";



export const GlobalGridLink = (props) => {

    GlobalGridLink.propTypes = {
        text: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
        style: PropTypes.object
    };
    const { text, className, style } = props;
    const dispatch = useDispatch();
    
    const selectMenu = (menuId) => {
        dispatch(changedMenu(menuId, { openWizardModal: true }));
    }
    
    return (
        <Link to="#" onClick={()=>selectMenu(MENU_GLOBAL_GRID)} className={className} style={style} >{text}</Link>
    );
}
