import { Button } from "devextreme-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextBox from "devextreme-react/text-box";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { useForm } from "react-hook-form";
import { IoIosHelpCircle } from "react-icons/io";
import { Tooltip } from "devextreme-react/tooltip";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { fetcher, postSettings } from "../../../../actions";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import { LinearProgress } from "@mui/material";
import PropTypes from "prop-types";

const SettingsContent = (props) => {
    SettingsContent.propTypes = {
        categoryId: PropTypes.number,
        onSubmit: PropTypes.func,
        programId: PropTypes.number,
    };

    const { programId, categoryId, onSubmit } = props;
    const url = categoryId !== 0 ? `/api/Report/reportSettings?idProgram=${programId}&categoryId=${categoryId}` : `/api/Report/reportSettings?idProgram=${programId}`;

    const { data, error, isLoading } = useSWR(url, fetcher, { refreshInterval: 60000, revalidateOnFocus: false, revalidateOnReconnect: false, refreshWhenHidden: false });

    const permissions = useSelector((state) => state.userInfo.feature);
    const dispatch = useDispatch();
    const [state, setState] = useState(null);

    const [tooltipState, setTooltipState] = useState({
        visible: false,
        control: "",
        text: "",
    });

    const { handleSubmit } = useForm();
    var arraySort = require("array-sort");

    const settingsPermissions = permissions.find(
        (x) => x.NameFeature === "Settings"
    );
    let settingsPermissionsValue = 0;
    if (settingsPermissions) {
        settingsPermissionsValue = settingsPermissions.Permission;
    }
    const readOnly = settingsPermissionsValue !== 1;


    useEffect(() => {
        if (data) {
            const result = data.reduce((accumulator, currentItem) => {
                let isVisible = true;
                let itemData = { visible: isVisible, value: currentItem.value };

                if (currentItem.code.startsWith("I")) {
                    const checkboxValue = data.find((x) => x.code.startsWith("D"))?.value;
                    if (checkboxValue) {
                        isVisible = checkboxValue !== "T";
                        itemData = { visible: isVisible, value: currentItem.value };
                    }
                }

                if (currentItem.code.startsWith("F")) {
                    const checkboxValue = data.find((x) => x.code.startsWith("E"))?.value;
                    if (checkboxValue) {
                        isVisible = checkboxValue !== "T";
                        itemData = { visible: isVisible, value: currentItem.value };
                    }
                }

                return { ...accumulator, [currentItem.code]: itemData };
            }, {});

            setState(result);
        }
    }, [data]);


    const onCheckBoxChanged = (e, controlId) => {
        const checkBoxValue = e.value === true ? "T" : "F";
        let id;

        switch (controlId[0]) {
            case "D":
                id = `I${controlId.slice(1)}`;
                break;
            case "E":
                id = `F${controlId.slice(1)}`;
                break;
            default:
                return;
        }

        if (state[id]) {
            setState(prevState => ({
                ...prevState,
                [id]: { value: prevState[id].value, visible: !e.value },
                [controlId]: { value: checkBoxValue, visible: true },
            }));
        }
    };


    const mouseEntered = (e, tooltipcode, text) => {
        const splitText = text.split("\n");
        let tooltipText;

        if (splitText.length > 1) {
            const [firstLine, secondLine] = splitText;
            tooltipText = (
                <>
                    {firstLine} <br /> {secondLine}
                </>
            );
        } else {
            tooltipText = text;
        }
        setTooltipState({ visible: true, control: tooltipcode, text: tooltipText });
    };

    const mouseLeaved = (e, tooltipcode) => {
        setTooltipState(prevState => ({
            ...prevState,
            visible: false,
            control: tooltipcode,
        }));
    };


    const renderControl = (controlType, setting) => {
        const value = state?.[setting.code]?.value;
        const visible = state?.[setting.code]?.visible;

        const TextBoxComponent = ({ defaultValue }) => (
            <TextBox
                readOnly={readOnly}
                onChange={(e) => onChange(e, setting.code)}
                defaultValue={defaultValue}
            >
                <Validator>
                    <RequiredRule message="field is required" />
                </Validator>
            </TextBox>
        );

        const NumberBoxComponent = ({ defaultValue, width }) => (
            <NumberBox
                readOnly={readOnly}
                onValueChanged={(e) => onNumericChange(e, setting.code)}
                defaultValue={defaultValue}
                width={width}
                min={0}
                step={0}
            >
                <Validator>
                    <RequiredRule message="field is required" />
                </Validator>
            </NumberBox>
        );

        switch (controlType) {
            case "TEXT":
                return <TextBoxComponent defaultValue={value && visible ? value : 0} />;
            case "NUMBER":
                return <NumberBoxComponent defaultValue={value && visible ? value : 0} width={value && visible ? "15vw" : "30vw"} />;
            case "CHECK":
                return (
                    <CheckBox
                        readOnly={readOnly}
                        name={setting.code}
                        value={value === "T"}
                        onValueChanged={(e) => onCheckBoxChanged(e, setting.code)}
                        key={setting.code}
                    />
                );
            default:
                return null;
        }
    };


    function renderForm(dataSource) {
        const controls = arraySort(dataSource, "formOrder");
        const visibleControls = controls.filter(x => state?.[x.code]?.visible);

        const controlsMapped = visibleControls.map(({ code, description, name, controlTypeCode }) => {
            const toolTipId = `tooltip-${code}`;

            return (
                <div className="dx-field" key={code}>
                    <div className="dx-field-label" style={{ width: "fit-content" }}>
                        <IoIosHelpCircle
                            id={toolTipId}
                            onMouseEnter={(e) => mouseEntered(e, toolTipId, description)}
                            onMouseLeave={(e) => mouseLeaved(e, toolTipId, description)}
                        />
                        {` ${name}`}
                    </div>
                    <div className="dx-field-value" style={{ display: "inline-flex", width: "30%" }}>
                        {renderControl(controlTypeCode, { code, description, name })}
                    </div>
                </div>
            );
        });

        return <div>{controlsMapped}</div>;
    };


    const onSubmitForm = () => {
        const request = Object.entries(state).map(([key, { value }]) => ({ key, value }));
        dispatch(postSettings(request, programId))
            .then(() => onSubmit())
            .catch(error => {
                console.error('Failed to post settings:', error);
                // Handle the error appropriately here
            });
    };

    const onChange = (e, code) => {
        setState({
            ...state,
            [code]: {
                value: e.component._changedValue,
                visible: state[code].visible,
            },
        });
    };

    const onNumericChange = (e, code) => {
        setState({ ...state, [code]: { "value": e.value.toString(), "visible": state[code].visible } });
    };


    if (error) return (<div>Failed to load settings</div>);

    if (isLoading) {
        return (
            <div style={{ paddingTop: "5px" }}>
                <LinearProgress /> Loading...{" "}
            </div>
        );
    }

    return (
        <div className="dx-fieldset">
            <form onSubmit={handleSubmit(onSubmitForm)}>
                {state !== null && renderForm(data)}
                {!readOnly && (
                    <div>
                        <Button
                            width={120}
                            text="Save"
                            type="normal"
                            stylingMode="contained"
                            onClick={onSubmitForm}
                        />
                        {tooltipState.control && (
                            <Tooltip
                                target={`#${tooltipState.control}`}
                                visible={tooltipState.visible}
                                hideOnOutsideClick={false}
                            >
                                <div>{tooltipState.text}</div>
                            </Tooltip>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
};

export default SettingsContent;
