import { useEffect, useState } from "react";
import { Button, Popup, ScrollView } from "devextreme-react";
import VendorsGrid from "./VendorsGrid";
import { LoadPanel } from "devextreme-react";
import { Component } from "devextreme-react/core/component";
import PropTypes from "prop-types";

export const VendorsModal = (props) => {
  const { open, onClose, name, internalId } = props;
  const [isLoadingModal, setIsLoadingForm] = useState(true);

  Component.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    internalId: PropTypes.string,
  };


  const renderTitle = () => {
    return (
      <>
        <h3>{name} - Available Vendors</h3>
      </>
    );
  };
  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose("vendors", false)}>X</Button>
      </div>
    );
  };
  const displayTitle = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {renderTitle()}
          {renderCloseButton()}
        </div>
      </>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingForm(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoadingModal === true) {
    return (<LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={true} />)
  }


  return (
    <>
      <Popup
        id="popup"
        visible={open}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        resizeEnabled={true}
      >
        <div>
          <ScrollView height="100%" width="100%">
            <VendorsGrid internalId={internalId} />
          </ScrollView>
        </div>
      </Popup>
    </>
  );
};
