import { Button, Popup, ScrollView } from "devextreme-react";
import { AddExclusionContent } from "./AddExclusionContent"


export const AddExclusionsModal = (props) => {
    const { onClose, setErrorMessage } = props;

    const renderTitle = () => {
        return (
            <>
                <h2 className='main-container__header'>
                    New Exclusion Rule
                </h2>
            </>
        );
    };
    const renderCloseButton = () => {
        return (
            <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
                <Button onClick={() => onClose()}>X</Button>
            </div>
        );
    };
    const displayTitle = () => {
        return (
            <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {renderTitle()}
                    {renderCloseButton()}
                </div>
            </>
        );
    };


    return (
        <>
            <Popup
                id="popup"
                visible={true}
                onHiding={onClose}
                showTitle={true}
                titleRender={displayTitle}
                resizeEnabled={true}
            >
                <div>
                    <ScrollView height="100%" width="100%">
                        <AddExclusionContent onClose={onClose} setErrorMessage={setErrorMessage} />
                    </ScrollView>
                </div>
            </Popup>
        </>
    );
};
