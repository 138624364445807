import {
  DataGrid,
  Summary,
  TotalItem,
  Column,
  Scrolling,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  ColumnFixing,
} from "devextreme-react/data-grid";
import {
  Chart,
  Size,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Grid,
} from "devextreme-react/chart";
import Moment from "moment";
import query from "devextreme/data/query";
import { useEffect, useState } from "react";

const InfoPeriodsChart = (props) => {
  const palette = ["#2007fa", "#776bdb", "#1b84f5", "#97c8fc"];
  const [chartData, setChartData] = useState([]);
  const { dataSourcePeriods } = props;
  const [gridData, setGridData] = useState([]);
  const [gridLabelData, setGridLabelData] = useState([]);
  const [gridDataTotal, setGridDataTotal] = useState([]);

  useEffect(() => {
    let newData = getDataGrid(dataSourcePeriods);
    setGridData(newData);
    let newChartData = getDataGroupGrid(dataSourcePeriods);
    setChartData(newChartData);
    let gridLabelData = getLabelDataGrid(newData);
    setGridLabelData(gridLabelData);
    let gridDataNoFilters = getDataGridTotal(dataSourcePeriods);
    setGridDataTotal(gridDataNoFilters);
  }, [dataSourcePeriods]);

  const createObjectGrid = (data, key) => {
    let newObject = {};
    newObject = {
      periodSelected: data[key].periodSelected,
      FormattedDate:
        data[key].date === "0001-01-01T00:00:00"
          ? ""
          : Moment(data[key].date).format("yyyy-MM-DD"),
      L3M: data[key].period == "L3M" ? data[key].quantity : null,
      L3M_1: data[key].period == "L3M_1" ? data[key].quantity : null,
      L3M_2: data[key].period == "L3M_2" ? data[key].quantity : null,
      L12M: data[key].period == "L12M" ? data[key].quantity : null,
      L12M_1: data[key].period == "L12M_1" ? data[key].quantity : null,
      L12M_2: data[key].period == "L12M_2" ? data[key].quantity : null,
      Y0: data[key].period == "Y0" ? data[key].quantity : null,
      Y1: data[key].period == "Y1" ? data[key].quantity : null,
      Y2: data[key].period == "Y2" ? data[key].quantity : null,
      Y3: data[key].period == "Y3" ? data[key].quantity : null,
      customerName: data[key].customerName,
      date: data[key].date,
      documentNumber: data[key].documentNumber,
      month: data[key].month,
      period: data[key].period,
      quantity: data[key].quantity,
      assemblyItem: data[key].assemblyItem 
    };
    return newObject;
  };

  const filterData = (data) => {
    var newArr = [];
    var total = null;
    var periodCompare = "";
    Object.keys(data).forEach((key) => {
      periodCompare = data[key].period;
      if (key == 0) {
        newArr.push(createObjectGrid(data, key));
      }
      if (key > 0) {
        if (
          data[key].periodSelected === 1 ||
          data[key].periodSelected === 2 ||
          data[key].periodSelected === 7
        ) {
          if (data[key].month == data[key - 1].month) {
            if (data[key].period == data[key - 1].period) {
              total += data[key].quantity;
            } else {
              total = data[key].quantity;
            }
            //Find index of specific object using findIndex method.
            let objIndex = newArr.findIndex(
              (obj) => obj.month == data[key].month
            );
            newArr[objIndex].L3M =
              "L3M" == periodCompare ? total : newArr[objIndex].L3M;
            newArr[objIndex].L3M_1 =
              "L3M_1" == periodCompare ? total : newArr[objIndex].L3M_1;
            newArr[objIndex].L3M_2 =
              "L3M_2" == periodCompare ? total : newArr[objIndex].L3M_2;
            newArr[objIndex].L12M =
              "L12M" == periodCompare ? total : newArr[objIndex].L12M;
            newArr[objIndex].L12M_1 =
              "L12M_1" == periodCompare ? total : newArr[objIndex].L12M_1;
            newArr[objIndex].L12M_2 =
              "L12M_2" == periodCompare ? total : newArr[objIndex].L12M_2;
            newArr[objIndex].Y0 =
              "Y0" == periodCompare ? total : newArr[objIndex].Y0;
            newArr[objIndex].Y1 =
              "Y1" == periodCompare ? total : newArr[objIndex].Y1;
            newArr[objIndex].Y2 =
              "Y2" == periodCompare ? total : newArr[objIndex].Y2;
            newArr[objIndex].Y3 =
              "Y3" == periodCompare ? total : newArr[objIndex].Y3;
          } else {
            newArr.push(createObjectGrid(data, key));
          }
        } else {
          if (
            data[key].period == data[key - 1].period &&
            data[key].month == data[key - 1].month
          ) {
            //Find index of specific object using findIndex method.
            let objIndex = newArr.findIndex(
              (obj) =>
                obj.period == data[key].period && obj.month == data[key].month
            );
            newArr[objIndex].L3M =
              newArr[objIndex].L3M != null
                ? newArr[objIndex].L3M + data[key].quantity
                : null;
            newArr[objIndex].L3M_1 =
              newArr[objIndex].L3M_1 != null
                ? newArr[objIndex].L3M_1 + data[key].quantity
                : null;
            newArr[objIndex].L3M_2 =
              newArr[objIndex].L3M_2 != null
                ? newArr[objIndex].L3M_2 + data[key].quantity
                : null;
            newArr[objIndex].L12M =
              newArr[objIndex].L12M != null
                ? newArr[objIndex].L12M + data[key].quantity
                : null;
            newArr[objIndex].L12M_1 =
              newArr[objIndex].L12M_1 != null
                ? newArr[objIndex].L12M_1 + data[key].quantity
                : null;
            newArr[objIndex].L12M_2 =
              newArr[objIndex].L12M_2 != null
                ? newArr[objIndex].L12M_2 + data[key].quantity
                : null;
            newArr[objIndex].Y0 =
              newArr[objIndex].Y0 != null
                ? newArr[objIndex].Y0 + data[key].quantity
                : null;
            newArr[objIndex].Y1 =
              newArr[objIndex].Y1 != null
                ? newArr[objIndex].Y1 + data[key].quantity
                : null;
            newArr[objIndex].Y2 =
              newArr[objIndex].Y2 != null
                ? newArr[objIndex].Y2 + data[key].quantity
                : null;
            newArr[objIndex].Y3 =
              newArr[objIndex].Y3 != null
                ? newArr[objIndex].Y3 + data[key].quantity
                : null;
          } else {
            newArr.push(createObjectGrid(data, key));
          }
        }
      }
    });
    return newArr;
  };

  const getDataGridTotal = (data) => {
    var newArr = [];
    if (!data) {
      return null;
    }
    Object.keys(data).forEach((key) => {
      newArr.push(createObjectGrid(data, key));
    });
    return newArr;
  };

  const getDataGroupGrid = (data) => {
    var newArr = [];
    if (!data) {
      return newArr;
    }
    newArr = filterData(data);
    return newArr;
  };

  const getDataGrid = (data) => {
    var newArr = [];
    if (!data) {
      return null;
    }
    let newObjectGrid = {};
    Object.keys(data).forEach((key) => {
      if (data[key].documentNumber != null) {
        newObjectGrid = {
          FormattedDate:
            data[key].date === "0001-01-01T00:00:00"
              ? ""
              : Moment(data[key].date).format("yyyy-MM-DD"),
          customerName: data[key].customerName,
          date: data[key].date,
          documentNumber: data[key].documentNumber,
          month: data[key].month,
          period: data[key].period,
          quantity: data[key].quantity,
          L3M: data[key].period == "L3M" ? data[key].quantity : null,
          L3M_1: data[key].period == "L3M_1" ? data[key].quantity : null,
          L3M_2: data[key].period == "L3M_2" ? data[key].quantity : null,
          L12M: data[key].period == "L12M" ? data[key].quantity : null,
          L12M_1: data[key].period == "L12M_1" ? data[key].quantity : null,
          L12M_2: data[key].period == "L12M_2" ? data[key].quantity : null,
          Y0: data[key].period == "Y0" ? data[key].quantity : null,
          Y1: data[key].period == "Y1" ? data[key].quantity : null,
          Y2: data[key].period == "Y2" ? data[key].quantity : null,
          Y3: data[key].period == "Y3" ? data[key].quantity : null,
          assemblyItem: data[key].assemblyItem 
        };
        newArr.push(newObjectGrid);
      }
    });
    newArr = newArr.sort((p1, p2) =>
      p1.date < p2.date ? 1 : p1.date > p2.date ? -1 : 0
    );
    return newArr;
  };

  const getLabelDataGrid = (data) => {
    var newArr = [];
    if (!data) {
      return newArr;
    }
    newArr = data.map((obj) => {
      var date = obj.period;
      return { period: obj.period, name: date };
    });
    newArr = newArr.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.period === value.period && t.name === value.name
        )
    );
    return newArr;
  };

  const onOptionChanged = (e) => {
    var newArr = [];
    if (e.fullName.indexOf("filterValues") > -1) {
      setTimeout(() => {
        var filterExpr = e.component.getCombinedFilter(true);
        var filteredData = gridDataTotal;

        if (filterExpr) {
          filteredData = query(gridDataTotal).filter(filterExpr).toArray();
        }
        newArr = filterData(filteredData);
        setChartData(newArr);
      });
    }
  };

  return (
    <>
      <div id="InfoPeriodsGridChart">
        <div id="ChartPeriod">
          <Chart palette={palette} dataSource={chartData}>
            <Size height={280} />
            <CommonSeriesSettings
              argumentField="month"
              valueField="quantity"
              type={"line"}
            />
            {gridLabelData.map((item) => (
              <Series
                key={item.period}
                valueField={item.period}
                name={item.name}
              />
            ))}
            <Margin bottom={20} />
            <ArgumentAxis
              valueMarginsEnabled={false}
              discreteAxisDivisionMode="crossLabels"
            >
              <Grid visible={true} />
            </ArgumentAxis>
            <Legend
              verticalAlignment="top"
              horizontalAlignment="center"
              itemTextPosition="top"
            />
            <Export enabled={false} />
            <Tooltip enabled={false} />
          </Chart>
        </div>
        <div>
          <h3>Transactions From Period Selection </h3>
        </div>
        <br />
        <DataGrid
          id="infoGrid"
          dataSource={gridData}
          showBorders={true}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"35vh"}
          width={"97%"}
          columnResizingMode={"widget"}
          onOptionChanged={onOptionChanged}
          filterMode={"withAncestors"}
        >
          <FilterRow visible={true} />
          <FilterPanel visible={true} />
          <HeaderFilter visible={true} />
          <ColumnFixing enabled={true} />
          <Scrolling mode="infinite" />
          <Column dataField="FormattedDate" dataType={"date"} format={"yyyy-MM-dd"} caption="Date" />
          <Column dataField="documentNumber" caption="Document Number" />
          <Column dataField="customerName" caption="Customer Name" />
          <Column dataField="quantity" caption="Quantity" />
          <Column dataField="assemblyItem" caption="Assembly Item" /> 
          <Summary>
              <TotalItem
              column="quantity"
              summaryType="sum"
              displayFormat="{0}"
              />
            </Summary>
        </DataGrid>
      </div>
    </>
  );
};

export default InfoPeriodsChart;
