import { FETCH_INVENTORY_LOADING, FETCH_INVENTORY } from "../actions/types";


const inventoryReducer =  (state = {'Inventory':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_INVENTORY_LOADING:
            return {...state, 'loading': true, 'Inventory':null };
        case FETCH_INVENTORY:
            return { ...state, [action.internalId]:action.payload,'loading': false}
        default:
            return state;
    }
};

export default inventoryReducer;