import { DropDownButton } from "devextreme-react";
import { useSelector } from "react-redux";

export const ToolBarDropDownButton = ({icon, action, ExportOptions, code}) => {

    const permissionList = useSelector((state) => state.userInfo.feature);
    const itemPermission = permissionList.find((x) => x.NameFeature === code);
    
    if (!permissionList || !itemPermission) {
        return null;
      }
    return(
        <DropDownButton
                text="Download"
                icon={icon}
                items={ExportOptions}
                displayExpr="name"
                keyExpr="id"
                onItemClick={action}
              />
    )
}
export default ToolBarDropDownButton;

