import { Delete, CheckBox } from "@mui/icons-material";
import { Switch, Tooltip } from "devextreme-react";
import { useCallback } from "react";

const styles = {
    buttonContainer: {
        display: "inline-flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: "10px",
        paddingRight: "10px"
    },
    iconButton: {
        cursor: "pointer"
    }
};

const CustomTooltip = ({ target, children }) => (
    <Tooltip
        target={target}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        hideOnOutsideClick={false}
    >
        <div>{children}</div>
    </Tooltip>
);

export const RenderActionButtons = (props) => {
    const { id, status, statusName, deleteExclusion, onRowStatusChanged } = props;

    const valueChanged = (id) => {
        onRowStatusChanged(id);
    };

    const onButtonClick = useCallback(() => {
        valueChanged(!status);
    }, []);


    return (
        <div style={styles.buttonContainer}>
            <Switch
                value={status}
                height="18px"
                isValid={true}
                width="50px"
                onValueChange={() => valueChanged(id)}
                name="radio-button-demo"
                id="exclusionsenable"
            >
            </Switch>
            <div className="grid-icon-buttons" style={styles.iconButton} onClick={async () => await deleteExclusion(id)} id={`delete${id}`}>
                <CustomTooltip target={`#delete${id}`}>
                    Delete this current Exclusion.
                </CustomTooltip>
                <Delete />
            </div>
            {statusName === 'System Modified' &&
                <div className="grid-icon-buttons" style={styles.iconButton} onClick={onButtonClick} id={`approve${id}`}>
                    <CustomTooltip target={`#approve${id}`}>
                        Approve System Modified Exclusion.
                    </CustomTooltip>
                    <CheckBox />
                </div>
            }
        </div >
    );
};
