import { MENU_CLICKED } from "../actions/types";
const _ = require("lodash");

const menuReducer = (state = { 'selectedMenu': 1, props: {openWizardModal:false} }, action) => {
    switch (action.type) {
        case MENU_CLICKED:
            return { ...state, 'selectedMenu': action.menuId, props:_.merge(state.props, action.props) };
        default:
            return state;
    }
};

export default menuReducer;