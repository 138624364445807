import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const primary = grey[500]; // #f44336

const Error = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: primary,
            }}
        >
            <Typography variant="h1" style={{ color: 'white' }}>
                500
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                There was an internal error.
            </Typography>
            <Button style={{color:"black"}} href='/'>Back Home</Button>
        </Box>
    );
}

export default Error;