import { fetchRoleUsers, onUserAuthenticated } from "../actions";
import { loginRequest } from "../AuthConfig";
import { callMsGraph } from "../Graph";


export const RequestProfileData = (instance, accounts, authenticatedUser, dispatch) => {
    
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    }).then(async (response) => {
        if (!authenticatedUser) {
            callMsGraph(response.accessToken).then(async response => {
                dispatch(onUserAuthenticated(response));
                const userAdId = response.id;
                sessionStorage.setItem("userAdId", response.id);
                dispatch(fetchRoleUsers(userAdId));
            }
            );
        }
    });
}



