import { SelectBox } from "devextreme-react";

export const FilterTypeSelection = (props) => {
    const { typeOfSearchId, setTypeOfSearchId } = props;

    const TypeSearchOptions = [
        {
            id: 1, name: 'Copy and Paste Item Name'
        },
        {
            id: 2, name: 'Copy and Paste Internal Id'
        },
        {
            id: 3, name: 'Columns Filter'
        }
    ];

    const handleTypeOfSearchChange = (e) => {
        setTypeOfSearchId(e.value);
    }


    return (
        <div className="filterTypeSelection" >
            <SelectBox
                dataSource={TypeSearchOptions}
                displayExpr="name"
                valueExpr="id"
                value={typeOfSearchId}
                onValueChanged={handleTypeOfSearchChange}
                id="filter"
            />
        </div>
    );
}
