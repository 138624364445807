import React, { memo, useEffect } from 'react';
import { SelectBox, Tooltip } from 'devextreme-react';
import { useSelector } from 'react-redux';
import { getMohSelectValues } from '../../../../actions';
import { getModifierValueCode } from '../../../../common/mohFunctions';
import PropTypes from 'prop-types';

const MohSelectBox = memo((props) => {
    MohSelectBox.propTypes = {
        selectedModifier: PropTypes.number.isRequired,
        onSelectedMohChanged: PropTypes.func.isRequired,
    };
    
    const { selectedModifier, onSelectedMohChanged } = props;

    const selectedMohCode = getModifierValueCode(selectedModifier);
    const currentRole = useSelector(state => state.userInfo.selectedRole);
    const [mohOptions, setMohOptions] = React.useState([]);
    useEffect(() => {
        const getSelectValuesAsync = async () => {
            let mohOptions = [];
            if (!currentRole) return mohOptions;
            const gridModifiers = await getMohSelectValues(currentRole);
            if (gridModifiers) {
                mohOptions = Object.values(gridModifiers);
            }
            setMohOptions(mohOptions);
        };
        getSelectValuesAsync();
    }, [currentRole]);

    return (
        <>
            <SelectBox
                dataSource={mohOptions}
                displayExpr="nameColumn"
                valueExpr="code"
                value={selectedMohCode}
                onValueChanged={onSelectedMohChanged}
                id="list"
            />
            <Tooltip
                target="#list"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <div>Select an option from the dropdown to determine which role the estimated inventory projections are calculated.</div>
            </Tooltip>
        </>
    );
});

export default MohSelectBox;