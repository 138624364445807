
export const gridAttributesKey = 'gridAttributes';

export function getGridAttributesByDefault() {
  const gridAttributes = JSON.parse(localStorage.getItem(gridAttributesKey));
  const gridAttributesDefault = {};
  gridAttributesDefault.columnLines = gridAttributes?.columnLines?? true;
  gridAttributesDefault.rowLines = gridAttributes?.rowLines?? true;
  gridAttributesDefault.borders = gridAttributes?.borders?? true;
  gridAttributesDefault.rowAlternation = gridAttributes?.rowAlternation?? true;
  return gridAttributesDefault;
}