import { Button, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadPanel } from "devextreme-react";
import { useForm } from "react-hook-form";
import SettingsContent from "./SettingsContent"
import { Component } from "devextreme-react/core/component";
import { PropTypes } from "prop-types";

export const SettingsModal = (props) => {
  const { programId, categoryId, onClose, onSavedData } = props;
  const program = useSelector((state) => state.userInfo.programs);
  const category = useSelector((state) => state.userInfo.categories);
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  Component.propTypes = {
  category: PropTypes.object,
  onClose: PropTypes.func,
  onSavedData: PropTypes.func,
  program: PropTypes.object,
  programId: PropTypes.string
  }
  const showProgramCategoryName = (programId, categoryId) => {
    var programList = program;
    var catnameList = category[programId];
    var catname = "";
    var progname = "";
    programList.forEach((element) => {
      if (element.programId === +programId) {
        progname = element.name;
      }
    });
    catnameList.forEach((element) => {
      if (element.categoryId === +categoryId) {
        catname = element.categoryName;
      }
    });
    return progname + " | " + catname;
  };


  const { handleSubmit } = useForm();


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingForm(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);


  const renderTitle = () => {
    return (
      <>
        <span className="note">
          <h3>Settings</h3>
        </span>
        <div className="title" style={{ marginTop: "10px" }}>
          <span
            className="subtitle"
            style={{ fontSize: "medium", fontWeight: "bold" }}
          >
            {showProgramCategoryName(programId, categoryId)}
          </span>
        </div>
      </>
    );
  };
  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose("settings", false)}>X</Button>
      </div>
    );
  };
  const displayTitle = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {renderTitle()}
          {renderCloseButton()}
        </div>
      </>
    );
  };

  const onSubmit = () => {
    onSavedData();
  };

  if (isLoadingForm === true) {
    return (<LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={true} />)
  }

  return (
    <>
      <Popup
        id="popup"
        visible={true}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        width="40vw"
        resizeEnabled={true}
      >
        <div>
          <div className="dx-fieldset">
            <form onSubmit={handleSubmit(onSubmit)}>

              {<SettingsContent  programId={programId} categoryId={categoryId} onSubmit={onSubmit} />}
            </form>
          </div>
        </div>
      </Popup>

    </>
  );
};
