import ExcelJS from "exceljs";
import saveAs from "file-saver";


export const excelExport = (DataGrid, GridData) => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    let worksheet = ExcelJSWorkbook.addWorksheet("Report Hppo");
    const columns = DataGrid.getVisibleColumns();   
    const headerRow = worksheet.addRow();
    headerRow.font = { bold: true };

    
    for (let i = 0; i < columns.length; i++) {
      const currentColumnWidth = DataGrid.option().columns[i].width;
      worksheet.getColumn(i + 1).width =
        currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;
      let cell = headerRow.getCell(i + 1);
      // Change control
      // Developer: Cnino
      // Change date: 2024-01-18
      // Comments: This line is added to eliminate the double space that occurs in the header names
      const columnName = columns[i].caption.replace(/\s+/g, ' ');
      cell.value = columnName;
    }
    worksheet = AddDataToWorkSheet(columns, worksheet, GridData);
    ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Export.xlsx`
        );
      });
  };

  const AddDataToWorkSheet = (columns, worksheet, items) =>{
    items.forEach(x=>{
        const row = worksheet.addRow()   
        for (let i = 0; i < columns.length; i++) {           
            let cell = row.getCell(i + 1);
            const currentColumn = columns[i].name;
            if (currentColumn === "programId") {
              cell.value = x["programName"];
            } else if (currentColumn === "categoryId") {
              cell.value = x["categoryName"];
            }
            else
            {
              cell.value = x[currentColumn];
            }
          }
        if (x.hasChildren===true){
           worksheet = AddDataToWorkSheet(columns, worksheet, x.children);
        }
    });
    return worksheet;
  }