import { FileCopyOutlined, Home, NotInterested, Security } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProgramSelector } from './components/mainPage/programSelector';
import "../src/components/Menu/drawer.css";
import logo from "./images/RT-Logo-large.png"
import { changedMenu } from './actions/menu';

const NavigationList = ({ origin }) => {
    const permissions = useSelector(state => state.userInfo.feature);
    const dispatch = useDispatch();

    const selectMenu = (menuId) => {
        dispatch(changedMenu(menuId));
    }

    const renderFile = (permissions, text, icon) => {
        if (!permissions) {
            return <></>
        }
        const filePermissions = permissions.find(x => x.NameFeature === "Upload CSV");
        if (filePermissions) {
            return (<ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <Link to="#" onClick={() => selectMenu(2)} className="MenuLink">{text}</Link>
            </ListItem>);
        }
        return <></>
    }

    const renderRole = (permissions, text, icon) => {
        if (!permissions) {
            return <></>
        }
        const filePermissions = permissions.find(x => x.NameFeature === "Roles");
        if (filePermissions) {
            return (<ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <Link to="#" onClick={() => selectMenu(3)} className="MenuLink">{text}</Link>
            </ListItem>);
        }
        return <></>
    }

    const renderExclusion = (permissions, text, icon) => {
        if (!permissions) {
            return <></>
        }
        const filePermissions = permissions.find(x => x.NameFeature === "Exclusions");
        if (filePermissions) {
            return (<ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <Link to="#" onClick={() => selectMenu(7)} className="MenuLink">{text}</Link>
            </ListItem>);
        }
        return <></>
    }


    return (
        <div className="list" style={{ width: '300px' }}>
            <List>
                <ListItem>
                    <img src={logo} alt="Logo" style={{ width: "200px", height: "100px" }} />
                </ListItem>
                {origin === "main" ?
                    <ListItem>
                        <ProgramSelector />
                    </ListItem>
                    :
                    ""
                }
                <ListItem>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <Link to="#" onClick={() => selectMenu(1)} className="MenuLink">Home</Link>
                </ListItem>
                {renderRole(permissions, "Roles", <Security />)}
                {renderFile(permissions, "Files", <FileCopyOutlined />)}
                {renderExclusion(permissions, "Exclusions", <NotInterested />)}
            </List>
            <Divider />
        </div>
    );

}

export default NavigationList;
