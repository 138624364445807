
export function modalActions(setState, state, key, icon, stateField, dataField) {
    
    const ActionsButtons = (e) => {       
        return <div style={{ cursor: "pointer", display: "inline-block" }} onClick={() => showPermissionsByRole(e.data[dataField])}>{icon}</div>;
    };

    const showPermissionsByRole = (idRole) => {
        setState({ ...state, open: { ...state.open, [key]: true }, [stateField]: idRole });
    };

    const handleCloseDialog = () => {
        setState({ ...state, open: { ...state.open, [key]: false } });
    };
    return { ActionsButtons, handleCloseDialog };
}
