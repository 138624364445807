import { Button, Popup, ScrollView } from "devextreme-react";
import BackGrid from "./BackGrid";
import { Component } from "devextreme-react/core/component";
import PropTypes from "prop-types";

export const BackModal = (props) => {
  Component.propTypes = { 
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    internalId: PropTypes.string,
  };

  
  const { open, onClose, name, internalId } = props;

  const renderTitle = () => {
    return (
      <>
        <h3>{name} - Back Order and Committed</h3>
      </>
    );
  };
  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose("back", false)}>X</Button>
      </div>
    );
  };
  const displayTitle = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {renderTitle()}
          {renderCloseButton()}
        </div>
      </>
    );
  };

  return (
    <>
      <Popup
        id="popup"
        visible={open}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        width="80vw"
        resizeEnabled={true}
      >
        <div>
          <ScrollView height="100%" width="100%">
            <BackGrid internalId={internalId} />
          </ScrollView>
        </div>
      </Popup>
    </>
  );
};
