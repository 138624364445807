import { FETCH_UNIQUECUSTOMERS, FETCH_UNIQUECUSTOMERS_LOADING } from "../actions/types";


const uniqueCustomersReducer =  (state = {'uniqueCustomers':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_UNIQUECUSTOMERS_LOADING:
            return {...state, 'loading': true ,'uniqueCustomers': null};
        case FETCH_UNIQUECUSTOMERS:
            return { ...state, 'uniqueCustomers': action.payload ,'loading': false}
        default:
            return state;
    }
};

export default uniqueCustomersReducer;