import { useEffect, useState } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Button, Popup, ScrollView } from "devextreme-react";
import {
  CircularGauge,
  ValueIndicator,
  Scale,
  Label,
  Geometry,
} from "devextreme-react/circular-gauge";
import { LoadPanel } from "devextreme-react";
import UniqueCustomersGrid from "./UniqueCustomersGrid";
import CustomersChart from "./CustomersChart";
import "./UniqueCustomerModal.css";

import InfoPeriodsChart from "../InfoPeriods/InfoPeriodsChart";
import InfoCurrentPeriodsGrid from "../InfoPeriods/InfoCurrentPeriodsGrid";
import { useDispatch, useSelector } from "react-redux";
import { fetchExcludedItems, fetchInfoPeriods } from "../../../../actions";
import { LinearProgress } from "@mui/material";
import { fetcher } from "../../../../actions";
import useSWR from "swr";
import { Component } from "devextreme-react/core/component";
import PropTypes from 'prop-types';
import ExcludedItemsTab from "./ExcludedItemsTab";
import { TopBuyerShareChart } from "./TopBuyerShareChart";

export const UniqueCustomerModal = (props) => {
  const currentPermissions = useSelector((state) => state.userInfo.permissions);

  Component.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    internalId: PropTypes.string.isRequired,
    Period: PropTypes.number.isRequired
  }

  const { open, onClose, name, internalId, Period } = props;
  const [newPeriod, setNewPeriod] = useState(Period);
  const [showExcludedItemsTab, setShowExcludedItemsTab] = useState(false);
  const [tabIndex, setTabIndex] = useState(Period !== 7 ? 1 : 0);
  const {
    data: dataSourcePeriods,
    isLoading: isLoadingPeriods,
    error,
  } = useSWR(
    `/api/Report/reportInfoPeriod?InternalID=${internalId}&Period=${newPeriod}`,
    fetcher
  );

  useEffect(() => {

    const fetchExcludedItemsDataAsync = async () => {
      const excludedItemsData = await fetchExcludedItems(internalId);
      const displayTab = excludedItemsData.totalExcludedItems > 0;
      setShowExcludedItemsTab(displayTab);
    }
    fetchExcludedItemsDataAsync(internalId);

  }, [internalId]);
  const dataSource = useSelector(
    (state) => state.uniqueCustomers.uniqueCustomers
  );
  const styleButton = (periodButton) => {
    let periodColor = "#FFFFFF";
    if (newPeriod === periodButton) {
      periodColor = "#56c0f5";
    }
    return periodColor;
  };

  const visibleAuthorization = (periodButton) => {
    var visible = "none";
    let column = currentPermissions.filter(
      (x) => x.NameColumn === periodButton
    )[0];
    if (column) {
      visible = "";
    }
    return visible;
  };

  const dispatch = useDispatch();

  let topBuyerShare = 0;

  let totalUSCustomers = 0;
  let totalIntCustomers = 0;
  let totalCustomers = 0;

  if (dataSource && dataSource.length > 0) {
    topBuyerShare = dataSource[0].TopBuyerShare * 100;

    totalIntCustomers = dataSource[0].Intl_cust;
    totalUSCustomers = dataSource[0].US_cust;
    totalCustomers = totalUSCustomers + totalIntCustomers;
  }

  const salesAmount = [
    { title: "International", value: totalIntCustomers, color: "#CECECE" },
    { title: "US", value: totalUSCustomers, color: "#DAEAF1" },
  ];

  const renderTitle = (title) => {
    return (
      <>
        <h3>{title} - Sales Review</h3>
      </>
    );
  };

  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose("uniqueCustomer", false)}>X</Button>
      </div>
    );
  };
  const displayTitle = (title) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {renderTitle(title)}
          {renderCloseButton()}
        </div>
      </>
    );
  };
  const onChangePeriod = (newPeriod) => {
    setNewPeriod(newPeriod);
    dispatch(fetchInfoPeriods(internalId, newPeriod));
  };

  const YearLabel = (year) => {
    //Current date utc
    let myDate = new Date();
    //Convert to PST Time
    let pstDate = myDate.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    //Extract the year
    let yearTime = pstDate.split("/");
    let yearPST = yearTime[2].split(",");
    let yearl = yearPST[0] - year;
    let yeard = yearl;
    //Return the current year minus the value sent
    return "(Y" + year + ") " + yeard;
  };

  const onSelectionChanged = (args) => {
    if (!args.addedItems[0]) {
      return;
    }
    const selectedIndex = args.addedItems[0].selectedIndex;
    const tabIndex = selectedIndex === "0" ? 0 : selectedIndex === "1" ? 1 : 2;
    setTabIndex(tabIndex);
  };

  if (error) {
    return <div>Failed to load</div>;
  }
  if (isLoadingPeriods) {
    return (<LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={true} />)
  }

  return (
    <div>
      <Popup
        id="popup"
        visible={open}
        onHiding={onClose}
        showTitle={true}
        titleRender={() => displayTitle(name)}
        resizeEnabled={true}
      >
        {isLoadingPeriods === true && (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)}
        <TabPanel
          selectedIndex={tabIndex}
          onSelectionChanged={onSelectionChanged}
        >
          <Item title="Unique Customers (L12M)" id="customer" selectedIndex="0">

            <div className="mainContainer">
              <div className="componentContainer">
                  <CustomersChart
                    data={salesAmount}
                    totalCustomers={totalCustomers}
                  />
                <div className="chartContainer">
                 <TopBuyerShareChart topBuyerShare={topBuyerShare} />
                </div>
              </div>
            </div>
            <div className="gridContainer">
              <UniqueCustomersGrid />
            </div>
          </Item>
          <Item title="Fulfillment History" id="Periods" selectedIndex="1">
            {/* ... */}
            <ScrollView height="100%" width="100%">
              <div>
                <div style={{ marginTop: "15px", marginLeft: "5px" }}>
                  <Button
                    style={{ background: styleButton(1) }}
                    className="years"
                    name="L3M"
                    onClick={() => onChangePeriod(1)}
                  >
                    L3M
                  </Button>
                  <Button
                    style={{ background: styleButton(2) }}
                    className="years"
                    name="L12M"
                    onClick={() => onChangePeriod(2)}
                  >
                    L12M
                  </Button>
                  <Button
                    style={{
                      background: styleButton(3),
                      display: visibleAuthorization("Y0"),
                    }}
                    className="years"
                    name="y0"
                    onClick={() => onChangePeriod(3)}
                  >
                    {YearLabel(0)}
                  </Button>
                  <Button
                    style={{
                      background: styleButton(4),
                      display: visibleAuthorization("Y1"),
                    }}
                    className="years"
                    name="y1"
                    onClick={() => onChangePeriod(4)}
                  >
                    {YearLabel(1)}
                  </Button>
                  <Button
                    style={{
                      background: styleButton(5),
                      display: visibleAuthorization("Y2"),
                    }}
                    className="years"
                    name="y2"
                    onClick={() => onChangePeriod(5)}
                  >
                    {YearLabel(2)}
                  </Button>
                  <Button
                    style={{
                      background: styleButton(6),
                      display: visibleAuthorization("Y3"),
                    }}
                    className="years"
                    name="y3"
                    onClick={() => onChangePeriod(6)}
                  >
                    {YearLabel(3)}
                  </Button>
                  <Button
                    style={{
                      background: styleButton(7),
                      display: visibleAuthorization("Y0"),
                    }}
                    className="years"
                    name="yt"
                    onClick={() => onChangePeriod(7)}
                  >
                    YOY
                  </Button>
                </div>
                <br />
                <InfoPeriodsChart dataSourcePeriods={dataSourcePeriods} />{" "}
                <br />
                <>
                  <InfoCurrentPeriodsGrid internalId={internalId} />
                </>
              </div>
            </ScrollView>
          </Item>
          {showExcludedItemsTab ?
            <Item title="Exclusions" id="Exclusions" selectedIndex="2">
              <ExcludedItemsTab internalId={internalId} />
            </Item> : ""
          }
        </TabPanel>
      </Popup>
    </div>
  );
};

export default UniqueCustomerModal;
