import { useDispatch, useSelector } from "react-redux";
import MainSelector from "./mainSelector";
import { useSearchParams } from "react-router-dom";
import { fetcher, onChangeSelectedCategory, onChangeSelectedProgram } from "../../actions";
import "./gridTabs.css";
import { useEffect, useState } from "react";
import { Tab, Tabs, ThemeProvider, createTheme } from "@mui/material";
import { blue, lime } from "@mui/material/colors";
import useSWR from "swr";
import MainGrid from "../mainGrid/mainGrid";
import GridHeader from "./gridHeader";

export const GridTabs = () => {
    const [selectedMoh, setSelectedMoh] = useState(1);
    const programId = useSelector(state => state.userInfo.selectedProgram);
    const categoryId = useSelector(state => state.userInfo.selectedCategory);
    const [searchParams, setSearchParams] = useSearchParams();
    const [gridData, setGridData] = useState(null);
    const dispatch = useDispatch();
    const stateCategories = useSelector(state => state.userInfo.categories);
    const validProgramCategories = stateCategories[programId]?.filter(x => x.records !== 0);


    let currentCategory = categoryId;
    if (programId !== 0 && categoryId === 0 && validProgramCategories) {
        if (validProgramCategories.length > 0) {
            currentCategory = validProgramCategories[0].categoryId;
        } else {
            currentCategory = 0;
        }
    }

    const { data, error, isLoading, mutate } = useSWR(`/api/Report/reportHppoByCategory?programId=${programId}&categoryId=${currentCategory}&modifier=${selectedMoh}`, fetcher, { refreshInterval: 60000, revalidateOnFocus: false, revalidateOnReconnect: false, refreshWhenHidden: false });
    const theme = createTheme({
        palette: {
            primary: lime,
            secondary: blue,
        },
    });
    useEffect(() => {
        const reset = searchParams.get("home");
        if (reset) {
            dispatch(onChangeSelectedProgram(0));
            searchParams.delete("home");
            setSearchParams(searchParams);
            setGridData(null)
        }
    }, [searchParams, dispatch, setSearchParams])

      const getCategoriesLabels = (categories) => {
        return (categories ?? []).map((x, i) => ({
            id: x.categoryId,
            text: x.categoryName,
            index: i
        }));
    }

    function handleMohModifierChange(e) {
        setSelectedMoh(e);
    }

    const tabLabels = getCategoriesLabels(validProgramCategories);

    const onMainSelectorChange = (e) => {
        dispatch(onChangeSelectedCategory(e));
    }

    const onApplyGridFilter = (data) => {
        setGridData(data);
    }

    if (programId === 0) {
        if (gridData && gridData !== null) {
            return <MainGrid programId={programId} categoryId={categoryId} selectedModifier={selectedMoh} data={gridData} error={error} isLoading={isLoading} mutate={mutate} onMohModifierChange={handleMohModifierChange} />
        }
        else {
            return <MainSelector onChange={onMainSelectorChange} onApplyGridFilter={onApplyGridFilter} />
        }

    }

    const changeData = (event, newValue) => {
        dispatch(onChangeSelectedCategory(newValue));
    }
    const gridValue = currentCategory === 0 ? tabLabels[0]?.id : currentCategory;
    return (
        <div>
            <GridHeader />
            <ThemeProvider theme={theme}>
                <Tabs
                    value={gridValue}
                    onChange={changeData}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    {tabLabels.map((x, i) => {
                        return <Tab key={x.id} value={x.id} label={x.text} />
                    })}
                </Tabs>

            </ThemeProvider>
            <MainGrid programId={programId} categoryId={currentCategory} data={data} error={error} isLoading={isLoading} mutate={mutate} onMohModifierChange={handleMohModifierChange} />
        </div>
    );
} 
