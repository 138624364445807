import { SelectBox } from "devextreme-react";
import { exclusionTypeOptions } from "./exclusionOptions"
import { useState, useCallback } from "react";
import CustomStore from 'devextreme/data/custom_store';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { fetcher } from "../../actions";
import useSWR from "swr";
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection,
    Paging,
    FilterRow,
    Scrolling,
} from 'devextreme-react/data-grid';
import { ItemsGrid } from './ItemsGrid'
import TextArea from 'devextreme-react/text-area';
import { Button } from "devextreme-react";
import { postExclusions } from "./customGridActions";
import "./exclusionsMainBox.css";

export const AddExclusionContent = (props) => {
    const { onClose } = props;
    const [selectedExclusionType, setSelectedExclusionType] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [searchDocument, setSearchDocument] = useState(null);
    const [selectedCustomerName, setSelectedCustomerName] = useState(null);
    const [selectedDocumentNumber, setSelectedDocumentNumber] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showError, setShowError] = useState(false);
    const [exclusionNote, setExclusionNote] = useState(null);
    const gridColumns = ['name', 'exclusions'];
    const [ErrorMessage, setErrorMessage] = useState({
        "visible": false,
        "message": "",
        "type": "error"
    });

    const customerNameStore = new CustomStore({
        key: 'name',
        load: (loadOptions) => {
            if (searchValue?.length >= 3) {
                return fetcher(`/api/ExclusionList/Customers?term=${searchValue}`).then(customers => {
                    const data = customers?.data?.map((customer) => {
                        return {
                            id: customer.id,
                            name: customer.text
                        }
                    });
                    return {
                        data: data,
                    }
                }).catch(() => {
                    throw new Error('Data Loading Error');
                });
            }
            return [];
        }
    });

    const {
        data: customerItems,
        isLoading: isLoadingCustomerItems,
    } = useSWR(selectedCustomerName != null ?
        `/api/ItemFulfillment/Search/name?filterValue=${searchValue}` : null,
        fetcher
    );


    const documentNumberStore = new CustomStore({
        key: 'name',
        load: (loadOptions) => {
            if (searchDocument?.length >= 3) {
                return fetcher(`/api/ExclusionList/DocumentNumber?documentNumber=${searchDocument}`).then(documents => {

                    return documents?.data?.map((document) => ({
                        id: document.id,
                        name: document.text
                    }));
                }).catch(() => {
                    throw new Error('Data Loading Error');
                });
            }
            return [];
        }
    });

    const {
        data: documentItems,
        isLoading: isLoadingDocumentItems,
    } = useSWR(selectedDocumentNumber != null ?
        `/api/ItemFulfillment/Search/Document?filterValue=${selectedDocumentNumber}` : null,
        fetcher
    );
    const isCustomerType = selectedExclusionType === 1 || selectedExclusionType === 2;
    const itemsGridData = selectedItems;

    const customerItemsOptions = customerItems?.list?.map((customer, index) => {
        return {
            id: index,
            internalId: customer.internalID,
            exclusionNote: null,
            name: customer.item,
            exclusions: customer?.exclusions ? customer.exclusions : false,
        }
    });

    const documentItemsOptions = documentItems?.list?.map((document, index) => {
        return {
            id: index,
            internalId: document.internalID,
            exclusionNote: null,
            name: document.item,
            qty: document.qty,
            eqty: document.qty,
            exclusions: document.exclusions ? document.exclusions : false,
        }
    });

    const handleExclusionTypeChange = useCallback((e) => {
        setSelectedExclusionType(e.value);
        setSelectedDocumentNumber(null);
        setSelectedCustomerName(null);
        setExclusionNote(null);
        setSelectedItems(null);
        setSearchValue(null);
        setSearchDocument(null);
        onHiding();
    }, []);

    const handleValueChange = useCallback((value, isCustomerType) => {
        if (isCustomerType) {
            setSearchValue(value);
        } else {
            setSearchDocument(value);
        }
        if (value === null) {
            setSelectedItems(null);
            onHiding();
        }
    }, []);

    const onHiding = () => {
        setErrorMessage({ ...ErrorMessage, "visible": false, "message": "" })
    }

    const handleItemClick = useCallback((e, isCustomerType) => {
        let customerName = String(e?.itemData?.name);

        if (!isCustomerType) {
            const startIndex = customerName.indexOf('(');
            const endIndex = customerName.lastIndexOf(')');
            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                customerName = customerName.substring(startIndex + 1, endIndex);
            }
            setSelectedDocumentNumber(e?.itemData?.id);
        }

        setSelectedCustomerName(customerName);
    }, []);

    const onSaveExclusions = useCallback(async (selectedExclusionType, selectedName, selectedDocument, exclusionNote, selectedItems) => {

        const response = await postExclusions(selectedExclusionType, selectedName, selectedDocument, exclusionNote, selectedItems);

        if (response === undefined) {
            setErrorMessage({ "visible": true, "message": 'This Exclusion Already Exist' });
        }
        else if (response === 201) {
            onClose();
        }
    }, []);

    const isSaveExclusionsAllowed = useCallback((selectedExclusionType, searchValue, searchDocument, selectedItems, exclusionNote, showError) => {
        if (selectedExclusionType === null) {
            return true;
        }

        if (selectedExclusionType === 2 || selectedExclusionType === 4) {
            const hasExclusions = selectedItems?.some(item => item?.exclusions === true);
            return (selectedExclusionType === 2 ? searchValue === null : searchDocument === null) || (selectedItems?.length <= 0 || selectedItems === null || showError === true || hasExclusions);
        }

        return (selectedExclusionType === 1 ? searchValue === null || exclusionNote === null || exclusionNote === "" : searchDocument === null || exclusionNote === null || exclusionNote === "");
    }, [selectedExclusionType, searchValue, searchDocument, selectedItems, exclusionNote]);


    const syncDataGridSelection = useCallback((e) => {
        setSelectedItems(e.value || []);

    }, []);

    const dataGridOnSelectionChanged = useCallback((e) => {
        if (e.selectedRowKeys?.length && e.selectedRowKeys.some((item) => item.exclusions === true)) {
            setErrorMessage({ "visible": true, "message": 'This Exclusion Rule Already Exist' });
        }
        else {
            onHiding();
        }
        setSelectedItems((e.selectedRowKeys?.length && e.selectedRowKeys) || []);
    }, []);

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                height={345}
                dataSource={isCustomerType ? customerItemsOptions : documentItemsOptions}
                columns={gridColumns}
                hoverStateEnabled={true}
                selectedRowKeys={selectedItems}
                onSelectionChanged={dataGridOnSelectionChanged}
            >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging
                    enabled={true}
                    pageSize={10}
                />
                <FilterRow visible={true} />
            </DataGrid>
        ),
        [customerItemsOptions, documentItemsOptions, selectedItems],
    );

    const onTextAreaValueChanged = useCallback((e) => {
        setExclusionNote(e.value);
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "2rem", paddingBottom: "2rem", paddingLeft: "0.5rem", paddingRight: "1rem", flexGrow: "1", flexBasis: "0" }}>
                <div style={{ display: "flex" }}>
                    <SelectBox
                        placeholder="Exclusion Type"
                        dataSource={exclusionTypeOptions}
                        width={'20rem'}
                        height={'2rem'}
                        displayExpr="name"
                        valueExpr="id"
                        value={selectedExclusionType}
                        onValueChanged={(e) => handleExclusionTypeChange(e)}
                        id="exclusionType"
                    />
                </div>
                <div >
                    <Autocomplete
                        dataSource={isCustomerType ? customerNameStore : documentNumberStore}
                        value={isCustomerType ? searchValue : searchDocument}
                        displayExpr="name"
                        valueExpr="name"
                        onValueChange={(e) => handleValueChange(e, isCustomerType)}
                        onItemClick={(e) => handleItemClick(e, isCustomerType)}
                        placeholder={isCustomerType ? "Customer Name" : "IF Number"}
                        width={'20rem'}
                        height={'2rem'}
                        showClearButton={true}
                        disabled={selectedExclusionType === null}
                    />
                </div>
                <div >
                    {(selectedExclusionType === 1 || selectedExclusionType === 3) ?
                        <TextArea
                            width={'25rem'}
                            height={'2rem'}
                            value={exclusionNote}
                            valueChangeEvent={'change'}
                            onValueChanged={onTextAreaValueChanged}
                            placeholder="Exclusion Note"
                            disabled={isCustomerType ? selectedCustomerName === null : selectedDocumentNumber === null}
                        /> :
                        <DropDownBox
                            width={'25rem'}
                            value={selectedItems}
                            valueExpr="name"
                            displayExpr="name"
                            placeholder="Parts and Assembly names"
                            showClearButton={true}
                            dataSource={isCustomerType ? customerItemsOptions : documentItemsOptions}
                            isLoading={isCustomerType ? isLoadingCustomerItems : isLoadingDocumentItems}
                            onValueChanged={syncDataGridSelection}
                            contentRender={dataGridRender}
                            disabled={isCustomerType ? (selectedCustomerName === null || selectedExclusionType === null) : (selectedDocumentNumber === null || selectedExclusionType === null)}
                        />
                    }
                </div>
            </div>
            {(selectedExclusionType === 2 || selectedExclusionType === 4) && (itemsGridData?.length > 0) &&
                (<ItemsGrid gridData={itemsGridData} isCustomerType={isCustomerType} selectedName={isCustomerType ? searchValue : searchDocument} setShowError={setShowError} />)}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "1.25rem" }}>
                <Button
                    text={'Save Exclusion'}
                    onClick={() => onSaveExclusions(selectedExclusionType, selectedCustomerName, selectedDocumentNumber, exclusionNote, selectedItems)}
                    disabled={isSaveExclusionsAllowed(selectedExclusionType, searchValue, searchDocument, selectedItems, exclusionNote, showError)}
                />
            </div>
            {ErrorMessage.visible && (
                <div id="error" style={{ display: "flex", justifyContent: "center", marginTop: "1.25rem", color: "red" }}>
                    {ErrorMessage?.message}
                </div>
            )}
        </div>
    );
};
