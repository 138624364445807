import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "devextreme-react";
import { useSelector } from "react-redux";

const ToolBarButton = ({
  code,
  location,
  icon,
  action,
  isDisabled = false,
  tooltipText,
  validatePermission = true
}) => {
  const permissionList = useSelector((state) => state.userInfo.feature);
  const itemPermission = permissionList.find((x) => x.NameFeature === code);
  const targetId = `options_${code.replace(" ", "_")}`;

  if (!permissionList || (!itemPermission && validatePermission)) {
    return null;
  }

  return (
    <>
      <Button icon={icon} onClick={action} id={targetId} disabled={isDisabled} />
      <Tooltip
        target={`#${targetId}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        hideOnOutsideClick={false}
      >
        <div>{tooltipText}</div>
      </Tooltip>
    </>
  );
};

ToolBarButton.propTypes = {
  code: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  validatePermission: PropTypes.bool
};

export default ToolBarButton;
