import {
  DataGrid,
  Column,
  Scrolling,
  Grouping,
} from "devextreme-react/data-grid";
import Moment from "moment";
import { LinearProgress } from "@mui/material";

import { fetcher } from "../../../../actions";
import useSWR from "swr";

const handleRowPrepared = (e) => {
  if (e.rowType === "group" && e.groupIndex === 0) {
    e.rowElement.style.display = "none";
  } else {
    if (e.rowType !== "group" && e.rowType !== "header") {
      e.rowElement.style.backgroundColor = e.key["Color"];
    }
  }
};

const handleCellPrepared = (e) => {
  if (e.rowType === "data") {
    if (e.column.name === "bo") {
      var elem = e.cellElement;
      elem.style.setProperty("cursor", "pointer", "important");
    }
  }
};

const getDataGrid = (data) => {
  if (!data) {
    return null;
  }
  const newArr = data.map((obj) => {
    var date =
      obj.LastDatePurchaseReceipt === "0001-01-01T00:00:00"
        ? ""
        : Moment(obj.LastDatePurchaseReceipt).format("yyyy-MM-DD");
    return { ...obj, FormattedDate: date };
  });
  return newArr;
};

const VendorsGrid = (props) => {
  const { internalId } = props;

  const {
    data: dataSourceVendors,
    isLoadig: isLoadingVendors,
    error,
  } = useSWR(
    `/api/Report/reportPreferVendor?InternalID=${internalId}`,
    fetcher
  );

  const gridData = getDataGrid(dataSourceVendors);

  const renderGroupCell = (options) => {
    return <>{options.value}</>;
  };

  if (error) {
    return <div>Failed to load</div>;
  }
  if (isLoadingVendors) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      <div id="VendorsGrid">
        <DataGrid
          dataSource={gridData}
          showBorders={true}
          onRowPrepared={handleRowPrepared}
          onCellPrepared={handleCellPrepared}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"70vh"}
          width={"100%"}
          columnResizingMode={"widget"}
        >
          <Scrolling mode="virtual" />
          <Grouping autoExpandAll={true} />
          <Column
            dataField="Code"
            caption="Code"
            groupIndex={1}
            groupCellRender={renderGroupCell}
          />
          <Column
            dataField="GroupVendor"
            caption="GroupVendor"
            groupIndex={0}
          />
          <Column dataField="Name" caption="Vendor Name" />
          <Column dataField="PreferVendor" caption="Prefered Vendor" />
          <Column dataField="VendorCost" caption="Vendor Cost" format={'$#,##0.##'} />
          <Column dataField="Rate" caption="Last Cost" format={'$#,##0.##'} />
          <Column dataField="FormattedDate" caption="Last Purchase Receipt" />
        </DataGrid>
      </div>
    </>
  );
};

export default VendorsGrid;
