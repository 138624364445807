import { PublicClientApplication } from '@azure/msal-browser'

export const GetSilentTokent = async () => {
    const cfg = {
        auth: {
          clientId: process.env.REACT_APP_CLIENT_ID,
          authority: process.env.REACT_APP_AUTHORITY,
          redirectUri: process.env.REACT_APP_REDIRECT_URI
        },
      }
    const pca = new PublicClientApplication(cfg)
    const accounts = pca.getAllAccounts()
    const account = accounts[0]
    const scopes = process.env.REACT_APP_AUTH_API_SCOPE;

    const result = await pca.acquireTokenSilent({
        scopes: [scopes],
        account: account
    });
    return result;
};

export const redirectToLogin = () => {
    const cfg = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID,
            authority: process.env.REACT_APP_AUTHORITY,
            redirectUri: process.env.REACT_APP_REDIRECT_URI
        }
      }
    const pca = new PublicClientApplication(cfg)
    pca.loginRedirect();
}