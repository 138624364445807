import { useSelector } from "react-redux";
import React from "react";

export function GetColumnsByGrid(gridId) {
  const stateColumns = useSelector(state => state.userInfo.columns);
  const columns = stateColumns.filter(x => x.GridId === gridId);
  return columns;
}

export function customRenderColumn(columnKey, component, extraProps) {
    const ClonedElementWithMoreProps = React.cloneElement(
      component,
      extraProps
    );
    return ClonedElementWithMoreProps;
}