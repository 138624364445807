import { FETCH_CONSOLIDATE_LOADING, FETCH_CONSOLIDATE } from "../actions/types";


const consolidateReducer =  (state = {'Consolidate':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_CONSOLIDATE_LOADING:
            return {...state, 'loading': true, 'Consolidate':null };
        case FETCH_CONSOLIDATE:
            return { ...state, [action.internalId]:action.payload, 'loading': false}
        default:
            return state;
    }
};

export default consolidateReducer;