import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import itemReducer from './itemReducer';
import kitReducer from './kitReducer';
import netsuiteIntegrationReducer from './netsuiteIntegrationReducer';
import relatedComponentsReducer from './relatedComponentsReducer';
import settingsReducer from './settingsReducer';
import uniqueCustomersReducer from './uniqueCustomersReducer';
import uploadedDataReducer from './uploadedDataReducer';
import userReducer from './userReducer';
import vendorsReducer from './vendorsReducer';
import backReducer from './backReducer';
import gridTemplateReducer from './gridTemplateReducer';
import inventoryReducer from './inventoryReducer';
import infoPeriodsReducer from './infoPeriodsReducer';
import infoSummaryPeriodsReducer from './infoSummaryPeriodsReducer';
import infoCurrentPeriodsReducer from './infoCurrentPeriodsReducer';
import infoGraphicReducer from './infoGraphicReducer';
import consolidateReducer from './consolidateReducer';
import vendorReducer from './vendorReducer';
import menuReducer from './menuReducer';
import { signalRReducer } from './signalRReducer';

export default combineReducers({
 items: itemReducer,
 kits: kitReducer,
 relatedComponents: relatedComponentsReducer,
 userInfo: userReducer,
 uniqueCustomers: uniqueCustomersReducer,
 netsuiteIntegration: netsuiteIntegrationReducer,
 settings:settingsReducer, 
 vendors:vendorsReducer,
 back:backReducer,
 uploadedData: uploadedDataReducer,
 admin: adminReducer,
 gridTemplate: gridTemplateReducer,
 inventory: inventoryReducer,
 infoPeriods: infoPeriodsReducer,
 infoSummaryPeriods: infoSummaryPeriodsReducer,
 infoCurrentPeriods: infoCurrentPeriodsReducer,
 infoGraphic: infoGraphicReducer,
 consolidate: consolidateReducer,
 vendor: vendorReducer,
 menu: menuReducer,
 signalR: signalRReducer
});
