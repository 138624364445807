import { DataGrid, Column, Editing, Scrolling, HeaderFilter, FilterRow, Toolbar, Item } from "devextreme-react/data-grid";
import { Toast } from "devextreme-react";
import { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { Link } from 'react-router-dom';
import { fetcher } from "../../actions";
import { useSelector } from "react-redux";
import { setCellValueExcludedQty, onCellPrepared, setEditableCells, setCellValueExclusionNotes } from "./customGridActions"
import { RenderActionButtons } from "./RenderActionButtons";
import { renderExclusionQty, renderItemFulfillmentNumber, renderItemName, renderIFQty } from "./customCellStyle"
import { putExclusions } from "../../actions"
import { AddExclusionsModal } from "./AddExclusionsModal"
import { ExclusionsHistoryModal } from "./ExclusionsHistoryModal"
import { Button } from "devextreme-react";
import useSWR from "swr";
import "./exclusionsMainBox.css";

export const Exclusions = () => {
    const [gridData, setGridData] = useState(null);
    const [showAddExclusionsModal, setShowAddExclusionsModal] = useState(false);
    const [showExclusionsHistoryModal, setShowExclusionsHistoryModal] = useState(false);
    const [selectedRowStatus, setSelectedRowStatus] = useState(null);
    const permissions = useSelector((state) => state.userInfo.feature);

    const [ErrorMessage, setErrorMessage] = useState({
        "visible": false,
        "message": "",
        "type": "error"
    });


    const exclusionsPermissions = permissions.find(
        (x) => x.NameFeature === "Exclusions"
    );
    let exclusionsPermissionsValue = 0;
    if (exclusionsPermissions) {
        exclusionsPermissionsValue = exclusionsPermissions.Permission;
    }
    const readOnly = exclusionsPermissionsValue !== 1;

    const {
        data: dataSource,
        error,
        isLoading,
        mutate
    } = useSWR(
        `/api/Exclusions`,
        fetcher, { refreshInterval: 1000000, revalidateOnFocus: false, revalidateOnReconnect: false, refreshWhenHidden: false });

    useEffect(() => {
        if (dataSource && dataSource?.length > 0) {
            const updatedDataSource = dataSource?.map(obj => ({
                ...obj,
                status: obj.statusName === "Active"
            })) || [];
            const rowStatuses = updatedDataSource.map((row) => ({
                status: row.status,
                statusName: row.statusName,
                id: row.id
            }));
            setSelectedRowStatus(rowStatuses);
            setGridData(updatedDataSource);
        }
        else {
            setGridData([]);
        }

    }, [dataSource]);

    if (error) return <div>Failed to load</div>;

    function enableExclusion(id, status) {
        async function putExclusionsAsync(id, payload) {
            await putExclusions(id, payload);
            mutate();
        }

        const payload = [{
            op: "replace",
            path: "statusCode",
            value: status
        }];
        putExclusionsAsync(id, payload);

    }


    async function deleteExclusion(id) {
        async function sendData(id, payload) {
            await putExclusions(id, payload);
            mutate();
        }

        const index = gridData.findIndex(x => x.id === id);
        let updatedData = gridData;
        if (index !== -1) {
            updatedData.splice(index, 1);
        }
        const payload = [{
            op: "replace",
            path: "statusCode",
            value: "DEL"
        }];
        setGridData(updatedData)
        sendData(id, payload);
    }

    async function approveSystemModified(id, status) {

        async function sendData(id, payload) {
            await putExclusions(id, payload);
        }
        let updatedData = gridData;

        const index = updatedData.findIndex(x => x.id === id);
        if (index !== -1) {
            updatedData[index].status = status;
        }
        const payload = [{
            op: "replace",
            path: "statusCode",
            value: 'ACT'
        }];
        setGridData(updatedData);
        sendData(id, payload);
    }


    const onRowStatusChanged = (id) => {
        let currentRecord = selectedRowStatus.find(x => x.id === id);
        let newStatusName = null;
        switch (currentRecord.statusName) {
            case 'Active':
                newStatusName = 'Inactive';
                break;
            case 'Inactive':
                newStatusName = 'Active';
                break;
            case 'System Modified':
                newStatusName = 'Active';
                break;
            default:
                newStatusName = null;
        }
        const newStatusCode = newStatusName === 'Active' ? 'ACT' : 'DEACT';
        const newStatus = newStatusCode === 'ACT';
        const updatedRowStatus = selectedRowStatus.map((row) => {
            if (row.id === id) {
                return { ...row, status: newStatus, statusName: newStatusName };
            }
            return row;
        });
        enableExclusion(id, newStatusCode);
        setSelectedRowStatus(updatedRowStatus);
    }

    const showAddExclusions = () => {
        setShowAddExclusionsModal(true)
    };

    const closeAddExclusions = () => {
        setShowAddExclusionsModal(false);
        mutate();
    };

    const renderAddExclusionsModal = () => {
        if (showAddExclusionsModal) {
            return (
                <AddExclusionsModal
                    open={true}
                    onClose={closeAddExclusions}
                    setErrorMessage={setErrorMessage}
                />
            );
        }
        return <></>;
    };

    const renderExclusionsHistoryModal = () => {
        if (showExclusionsHistoryModal) {
            return (
                <ExclusionsHistoryModal
                    open={true}
                    onClose={() => setShowExclusionsHistoryModal(false)}
                />
            );
        }
        return <></>;
    };

    const renderRowButtons = (row, enableExclusion, deleteExclusion, approveSystemModified) => {
        const rowStatus = selectedRowStatus.find(x => x.id === row.data.id);
        if (!rowStatus) return;
        return (
            <>
                <RenderActionButtons id={row.data.id} status={rowStatus.status} statusName={rowStatus.statusName} enableExclusion={enableExclusion} deleteExclusion={deleteExclusion} approveSystemModified={approveSystemModified} onRowStatusChanged={onRowStatusChanged} />
            </>
        );
    }
    const onHiding = () => {
        setErrorMessage({ ...ErrorMessage, "visible": false, "message": "" })
    }

    if (isLoading) {
        return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
    }

    return (
        < div>
            <div className="flex-item" style={{ display: 'flex', justifyContent: 'center', marginTop: "5vh", marginBottom: "5vh" }}>
                <h3>Exclusions Rules</h3>
            </div>
            <div className="flex-item" style={{ display: 'flex', justifyContent: 'left', marginTop: "5vh", marginBottom: "1vh", fontWeight: 'bold' }}>
                <div>Note: In Order to apply the exclusions please run the Netsuite update</div>
            </div>
            <DataGrid
                dataSource={gridData}
                showBorders={true}
                columnMinWidth={10}
                columnAutoWidth={true}
                height={"71vh"}
                width={"100%"}
                columnResizingMode={"widget"}
                wordWrapEnabled={true}
                onCellPrepared={(e) => onCellPrepared(e)}
                filterMode={"withAncestors"}
                onEditorPreparing={setEditableCells}
            >
                <FilterRow visible={true} />
                <Editing mode="cell" allowUpdating={!readOnly} allowDeleting={!readOnly} useIcons={true} allowAdding={!readOnly}
                />
                <Scrolling mode="virtual" />

                <Toolbar>
                    {!readOnly && (<Item location="before">
                        <Button
                            icon={"plus"}
                            onClick={() => showAddExclusions()}
                        />
                    </Item>
                    )}
                </Toolbar>
                <HeaderFilter visible={true} />
                {!readOnly && (
                    <Column key="actions" type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={(rowData) => renderRowButtons(rowData, enableExclusion, deleteExclusion, approveSystemModified)} />
                )}
                <Column
                    dataField="customerName"
                    caption="Customer Name"
                    alignment="left"
                    allowEditing={false}
                />
                <Column
                    dataField="itemFulfillmentNumber"
                    caption="Item Fulfillment Number"
                    alignment="left"
                    allowEditing={false}
                    cellRender={(e) => renderItemFulfillmentNumber(e)}
                />
                <Column
                    dataField="itemName"
                    caption="Item Name"
                    alignment="left"
                    allowEditing={false}
                    cellRender={(e) => renderItemName(e)}
                />
                <Column
                    dataField="ifQty"
                    alignment="left"
                    caption="If Qty"
                    cellRender={(e) => renderIFQty(e)}
                    allowEditing={false}
                />
                <Column
                    dataField="excludedQty"
                    caption="Excluded Qty"
                    alignment="left"
                    allowEditing={readOnly === true ? false : (e) => setEditableCells(e)}
                    cellRender={(e) => renderExclusionQty(e)}
                    setCellValue={async (newData, value, currentRowData) => await setCellValueExcludedQty(newData, value, currentRowData, gridData)}
                />
                <Column
                    dataField="exclusionNote"
                    caption="Exclusion Notes"
                    alignment="left"
                    allowEditing={!readOnly}
                    setCellValue={async (newData, value, currentRowData) => await setCellValueExclusionNotes(newData, value, currentRowData, gridData)}
                />
                <Column
                    dataField="typeName"
                    caption="Exclusion Type"
                    alignment="left"
                    allowEditing={false}
                />
                <Column
                    dataField="statusName"
                    caption="Status"
                    alignment="left"
                    allowEditing={false}
                />
            </DataGrid>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', marginTop: "20px" }}>
                <div><Link to="#" onClick={() => setShowExclusionsHistoryModal(true)} style={{ color: "#161616" }}>Exclusions History Log</Link></div>
            </div>
            {renderAddExclusionsModal()}
            {renderExclusionsHistoryModal()}
            <div id="grid" style={{ width: "50vw" }}>
                <div className="SelectContainer">
                    <Toast
                        visible={ErrorMessage.visible}
                        message={ErrorMessage.message}
                        type={ErrorMessage.type}
                        onHiding={onHiding}
                        displayTime={10000}
                        hideOnOutsideClick={true}
                    />
                </div>
            </div>
        </div >
    );
}
