import { DataGrid, Column, Editing, Scrolling } from "devextreme-react/data-grid";


export const CopyPasteGrid = (props) => {
    const { data, setData, typeOfSearchColumnName, typeOfSearchColumn, shouldShowError } = props;

    const onCellPrepared = (e) => {
        if (e?.rowType === 'data' && e?.isAltRow === true) {
            e.cellElement.style.backgroundColor = "#f5f5f5";
        }
        if (e?.data?.exist === 'No' && shouldShowError) {
            e.cellElement.style.color = "#FF0000";
        }
    };

    const handlePaste = async (e) => {
        const clipBoard = await navigator.clipboard.readText();
        const rows = clipBoard.split("\n");
        const result = rows.map((row) => {
            const cells = row.split("\t");
            if (typeOfSearchColumn === 'partName') {
                return {
                    partName: cells[0],
                };
            }
            else {
                return {
                    internalId: cells[0],
                };
            }
        });

        if (data.length !== 0) {
            result.push(...data);
        };

        setData(result);
    };

    return (
        < div className="ConsolidateGrid" onPaste={handlePaste}>
            <DataGrid
                dataSource={data}
                showBorders={true}
                columnMinWidth={10}
                columnAutoWidth={true}
                height={"40vh"}
                width={"100%"}
                columnResizingMode={"widget"}
                wordWrapEnabled={true}
                onCellPrepared={(e) => onCellPrepared(e)}
                noDataText={'Copy and paste your data here using Ctrl + V'}
            >
                <Editing mode="cell" allowUpdating={true} allowAdding={true} allowDeleting={true}
                />
                <Scrolling mode="virtual" />
                <Column
                    dataField={typeOfSearchColumn}
                    caption={typeOfSearchColumnName}
                    alignment="center"
                />
            </DataGrid>
        </div >
    )
}
