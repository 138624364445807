
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { Delete, Download } from "@mui/icons-material";
import { deleteBulkExport } from "../../../../actions";

const ExportGrid = (props) => {

    const { data, onDeleteExport } = props;


    async function callDeleteBulkExport(processId) {
        await deleteBulkExport(processId);
    }
    const deleteExport = (processId) => {
        callDeleteBulkExport(processId).then((result) => {
            onDeleteExport();
        });
    }

    const renderButtons = (row) => {
        const account = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
        const sasKey = process.env.REACT_APP_STORAGE_SAS_KEY;
        const fileIdentifier = row.data.processKey.replaceAll("-", "");
        const container = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
            const url = `https://${account}.blob.core.windows.net/${container}/${fileIdentifier}.xlsx?${sasKey}`;
            return (
                <>
                    <div style={{ display: "inline-flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "10px", paddingRight: "10px" }}>
                        {row.data.status === "Completed" ?
                            <div className="grid-icon-buttons" style={{ cursor: "pointer" }} id="share" >
                                <a
                                    href={url}
                                    download="excel file"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Download />
                                </a>
                            </div> : ""}

                        <div className="grid-icon-buttons" style={{ cursor: "pointer" }} id="share" onClick={() => deleteExport(row.data.processKey)} >
                            <Delete />
                        </div>
                    </div>

                </>
            );
    }

    return (
        <>
            <div id="ExportGrid" style={{ height: "90%" }}>
                <DataGrid
                    dataSource={data}
                    showBorders={true}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    width={"100%"}
                    height={"50vh"}
                    columnResizingMode={'widget'}
                    filterMode={'withAncestors'}
                >
                    <Column caption="Export Date" width={150} allowEditing={false} dataField="dateCreated" dataType="date"  format={"yyyy-MM-dd HH:mm:ss"} />
                    <Column dataField="status" caption="Status" width={150} allowEditing={false} />
                    <Column key="actions" type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={renderButtons} />
                </DataGrid>
            </div>

        </>
    );
}

export default ExportGrid
