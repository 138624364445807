import { Button, Popup } from "devextreme-react";
import ExportGrid from "./ExportGrid";
import { useSelector } from "react-redux";
import useSWR from 'swr'
import { fetchExportAllDownload, fetcher } from "../../../../actions";
import { LinearProgress } from "@mui/material";
import { Component } from "devextreme-react/core/component";
import PropTypes from 'prop-types';

export const ExportModal = (props) => {
    Component.propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    }

    const { open, onClose} = props;
    const userId = useSelector((state) => state.userInfo.userAuthenticated.id);
    const currentRole = useSelector(state => state.userInfo.selectedRole);
    const { data, mutate , error, isLoading } = useSWR(`/api/Export/${userId}`, fetcher);

    const renderTitle = (title) => {
        return <>
            <h3>{title}</h3>
        </>
    }
    const renderCloseButton = () => {
        return (
            <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}><Button onClick={() => onClose("export", false)}>X</Button></div>
        )
    }

    const onDeleteExport = () => {
        mutate();
    }

    const renderGrid = () => {
        if (error) return <div>Failed to load</div>

        if (isLoading) {
            return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
        }
        return(<ExportGrid userId={userId} data={data} onDeleteExport={onDeleteExport}/>);
    }

    async function createBulkExport(){
        await fetchExportAllDownload(userId, currentRole);
        mutate();
    }


    const startExport = () => {
        createBulkExport().then((result) => {
            mutate();
            });
        
    }
    
    const displayTitle = (title) => {
        return <>
            <div style={{ display: "flex", justifyContent: "space-between" }} >
                {renderTitle(title)}
                {renderCloseButton()}
            </div>
    
        </>
    }

    return (
        <div >
            <Popup id="popup"
                visible={open}
                onClose={onClose}
                showTitle={true}
                titleRender={() => displayTitle("Bulk Export List")}
                width="40vw"
                resizeEnabled={true}
                height={"70vh"}
            >
                <div>
                    {renderGrid()}
                </div>
                <div style={{paddingTop:"10px"}}>
                    <Button onClick={startExport} >New Export</Button>
                </div>
            </Popup>


        </div>
    );
}

export default ExportModal