import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import { Check, ExpandLess, ExpandMore, Logout, StarBorder, VerifiedUserRounded } from "@mui/icons-material";
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from "@mui/material";
import { SwitchRole } from "../../actions";
import { useMsal } from "@azure/msal-react";


const FlexBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    color: "#b9b9b9"

}
const userNameStyle = {
    marginRight: "5px"

}



export const LogoutButton = () => {
    const roles = useSelector(state => state.userInfo.roles);
    const selectedRole = useSelector(state => state.userInfo.selectedRole);
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openRoles, setOpenRoles] = useState(false);
    const userData = useSelector(state => state.userInfo.userAuthenticated)

    const logout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    const dispatch = useDispatch();


    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const ExpandRoles = () => {
        setOpenRoles(!openRoles);
    }
    const ChangeRole = (idRole) => {
        dispatch(SwitchRole(idRole));
    }


     const handleLogout = () =>{
        localStorage.clear();
        logout("popup")
     }


    const renderRoleCheck = (idRole, isMainRole) => {
        if (selectedRole === 0) {
            if (isMainRole) {
                return <Check />
            }
            return "";
        }
        else{
            if (selectedRole === idRole){
                return <Check />
            }
            return "";
        }
    }

    const renderRoles = () => {

        if (!roles) {
            return "";
        }
        return (
            <Collapse in={openRoles} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {roles.map((x,i) => {
                        return (
                            <ListItemButton key={`changeRole-${i}`} sx={{ pl: 4 }} onClick={() => ChangeRole(x.idRole)}>
                                <ListItemIcon>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary={x.role} />
                                {renderRoleCheck(x.idRole,x.mainRole)}
                            </ListItemButton>
                        );
                    }
                    )}
                </List>
            </Collapse>
        );

    }

    if (!userData){
        return<></>
    }

    return (
        <>
            <IconButton sx={{ p: 0 }}
                id="userAvatar"
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} >
                <div style={FlexBoxStyle} >
                    <Typography style={userNameStyle} >{userData.displayName}</Typography>
                    <PersonIcon />
                </div>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <VerifiedUserRounded />
                        </ListItemIcon>
                        <ListItemButton onClick={ExpandRoles}>
                            <ListItemText primary="Roles" />
                            {openRoles ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        {renderRoles()}
                    </ListItem>
                    <ListItem >
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemButton>
                            <ListItemText primary="LogOut" onClick={() => handleLogout()} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Menu>
        </>


    );

}