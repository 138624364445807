import { DataGrid, Column, Editing, Scrolling, HeaderFilter } from "devextreme-react/data-grid";
import { Validator, RequiredRule } from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";



export const FilterValidationGrid = (props) => {
    const { dataValidated, setDataValidated, typeOfSearchColumnName, typeOfSearchColumn, typeOfSearchId, filterName, setFilterName, shouldShowError, setShouldShowError } = props;

    const filterNameTextBoxStyle = filterName === null ? { marginBottom: "0.625rem", marginTop: "0.625rem", borderColor: "#880808" } : { marginBottom: "0.625", marginTop: "0.625" }
    const onCellPrepared = (e) => {
        if (e?.rowType === 'data' && e?.isAltRow === true) {
            e.cellElement.style.backgroundColor = "#f5f5f5";
        }
        if (e?.data?.exist === 'No' && shouldShowError) {
            e.cellElement.style.color = "#FF0000";
        }
    };

    const setCellValueValidateGrid = (newData, value, currentRowData) => {
        let newDataValidated = newData;

        if (value === '') {
            newDataValidated = dataValidated.filter((item) => {
                if (typeOfSearchId === 1) {
                    return item.partName !== currentRowData.partName;
                }
                else {
                    return item.internalId !== currentRowData.internalId;
                }
            });
        }
        else {
            newDataValidated = dataValidated.map(obj => {
                if (typeOfSearchId === 1) {
                    if (obj.partName === currentRowData.partName) {
                        return { ...obj, partName: value };
                    }
                }
                else {
                    if (obj.internalId === currentRowData.internalId) {
                        return { ...obj, internalId: value };
                    }
                }

                return obj;
            });


        }
        setDataValidated(newDataValidated);
        setShouldShowError(false);
    };



    return (
        < div>
            <div className="flex-item">
                <span>Filter Name *</span>
            </div>
            <TextBox
                onChange={(e) => setFilterName(e.component._changedValue)}
                placeholder="Add Filter Name"
                defaultValue={filterName}
                style={filterNameTextBoxStyle}
            >
                <Validator>
                    <RequiredRule IsRequired="true" message="field is required" />
                </Validator>
            </TextBox>
            <DataGrid
                dataSource={dataValidated}
                showBorders={true}
                columnMinWidth={10}
                columnAutoWidth={true}
                height={"32vh"}
                width={"100%"}
                columnResizingMode={"widget"}
                wordWrapEnabled={true}
                onCellPrepared={(e) => onCellPrepared(e, dataValidated)}
                filterMode={"withAncestors"}
            >
                <Editing mode="cell" allowUpdating={true}
                />
                <Scrolling mode="virtual" />
                <HeaderFilter visible={true} />
                <Column
                    dataField={typeOfSearchColumn}
                    caption={typeOfSearchColumnName}
                    alignment="center"
                    setCellValue={(newData, value, currentRowData) => setCellValueValidateGrid(newData, value, currentRowData)}
                />
                <Column
                    dataField="exist"
                    caption="Valid"
                    alignment="left"
                    allowEditing={false}
                />
            </DataGrid>

        </div >
    );
}
