import { Settings } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import { Button, DataGrid } from "devextreme-react";
import { Column, Editing, Item, Lookup, Form, Scrolling } from "devextreme-react/data-grid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteUserRole, updateUserRole } from "../../actions";
import ModalContainer from "../../common/ModalContainer";
import { modalActions } from "./modalActions";
import PermissionsByUser from "./Modals/PermissionsByUser/PermissionsByUser";


const AdminUserRoleBody = (props) => {
    const data = props.data;
    const users = useSelector(state => state.admin.users);
    const [state, setState] = useState({
        "open": {
            "permissionsByUser": false
        },
        "userId": 0
    });
    const usersDs = data.users;
    const dispatch = useDispatch()

    const onRowRemoved = (e) => {
        console.log(e);
    }


    const onSaving = (e) => {
        const change = e.changes[0];
        if (change.type === "remove") {
            const deletedRow = change.key;
            const userId = deletedRow.idUser;
            const roleId = deletedRow.idRole;
            dispatch(deleteUserRole(userId, roleId));
        }
        else if (change.type === "insert") {
            const formData = e.changes[0].data;
            const user = users.filter(x => x.Id === formData.id)[0];
            const postData = { idRole: data.id, idUser: formData.id, mainRole: formData.mainRole };
            dispatch(updateUserRole(postData));
        }

    };

    const { ActionsButtons, handleCloseDialog } = modalActions(setState, state, "permissionsByUser", <Settings />, "userId", "idUser");

    return (<div>

        <Card >
            <CardContent>
                <div id="kitsGrid">
                    <DataGrid
                        id="gridContainer"
                        dataSource={usersDs}
                        showBorders={true}
                        onSaving={onSaving}
                        onRowRemoved={onRowRemoved}
                        height={"30vh"}
                    >
                        <Editing
                            mode="form"
                            allowAdding={true}
                            allowDeleting={true}
                            useIcons={true}
                        >
                            <Scrolling mode="virtual" />
                            <Form>
                                <Item itemType="group" caption="User Roles">
                                    <Item dataField="id" />
                                    <Item dataField="email" visible={false} />
                                    <Item dataField="mainRole" />

                                </Item>
                            </Form>
                        </Editing>
                        <Column dataField="id" caption="id" visible={false}>
                            <Lookup
                                dataSource={users}
                                valueExpr="Id"
                                displayExpr="DisplayName"
                            />
                        </Column>
                        <Column dataField="idUser" caption="idUser" visible={false} />
                        <Column dataField="displayName" caption="Name" />
                        <Column dataField="email" caption="email" />
                        <Column dataField="mainRole" caption="isMainRole" dataType={"boolean"} />
                        <Column type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={ActionsButtons} />
                        <Column type="buttons" >
                            <Button name="edit" />
                            <Button name="delete" />
                        </Column>
                    </DataGrid>

                </div>
            </CardContent>
        </Card>
        <ModalContainer open={state.open.permissionsByUser} modalId="permissionsByUser" onClose={handleCloseDialog} title={<h3>User Permissions</h3>} >
            <PermissionsByUser userId={state.userId} />
        </ModalContainer>

    </div>)


}

export default AdminUserRoleBody