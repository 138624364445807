import { useSelector } from "react-redux";

const GridHeader = () => {
    const programId = useSelector(state => state.userInfo.selectedProgram);
    const programsList = useSelector(state => state.userInfo.programs);

    const getProgramNameSelected = () => {
        const program = programsList.find(programItem => programItem.programId === +programId);
        return program ? program.name : "";
    }

    return (
        <div className="titleStyle">
            Program: {getProgramNameSelected()}
            <hr className="lineStyle" />
        </div>
    )
}
export default GridHeader;