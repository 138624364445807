import { Button, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { LoadPanel } from "devextreme-react";
import GridSettingsContent from "./GridSettingsContent"

export const GridSettingsModal = ({ onClose, onSavedData, ...gridSettings }) => {
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingForm(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);


  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose("settings", false)}>X</Button>
      </div>
    );
  };
  const displayTitle = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="note">
            <h3>Grid styles</h3>
            </span>
            {renderCloseButton()}
        </div>
      </>
    );
  };

  const onSubmit = (gridSettings) => {
    onSavedData(gridSettings);
  };

  if (isLoadingForm === true) {
    return (<LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={true} />)
  }

  return (
    <>
      <Popup
        id="gridSettingPopup"
        visible={true}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        width={"440px"}
        height={"400px"}
        resizeEnabled={true}
      >
        <div>
          <div className="dx-fieldset">
                <GridSettingsContent onSubmit={onSubmit} {...gridSettings} />
          </div>
        </div>
      </Popup>
    </>
  );
};
