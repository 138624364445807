import React, { useState } from "react";
import { DataGrid } from "devextreme-react";
import LinkCellBig from "../../cellstyles/linkCellBig";
import {
  Column, Scrolling, FilterRow,
  HeaderFilter,
  FilterPanel, ColumnFixing, MasterDetail, StateStoring
} from "devextreme-react/data-grid";
import Moment from "moment";
import "./RelatedComponentsGrid.css";
import RelatedComponentsDetails from "./RelatedComponentsDetails";
import { fetcher } from "../../../../actions";
import useSWR from "swr";
import { LinearProgress } from "@mui/material";
import { GetColumnsByGrid, customRenderColumn } from "../../../../common/Columns";
import { GridTooltips } from "../../../gridTooltip/gridTooltips";
import { useRef } from "react";

import { InventoryModal } from "../../dialog/Inventory/InventoryModal";
import { ConsolidateModal } from "../../dialog/Consolidate/ConsolidateModal";
import { VendorModal } from "../../dialog/VendorInventory/VendorModal";


const handleRowPrepared = (e) => {
  if (e.rowType !== "group" && e.rowType !== "header") {
    if (e.key != undefined) {
      e.rowElement.style.backgroundColor = e.key["color"];
    }
  }
};

const handleCellPrepared = (e) => {
  if (e.rowType === "data") {
    if (e.column.dataField === "qtyRemaining") {
      const available = e.data["qtyAvailable"] || 0;
      const members = e.data["actualMemberQuantity"] || 0;
      if (available < members && e.data["programId"] != null) {
        e.cellElement.style.backgroundColor = "#FF8484";
      }
    }

    if (e.column.type === "detailExpand") {
      if (e.data.id === 0) {
        e.cellElement.style.visibility = "hidden";
      }
    }
  }
}


function renderColumn(columnKey, component) {
  const extraProps = { cssClass: "tooltip-target-" + columnKey };
  return customRenderColumn(columnKey, component, extraProps);
}

const RelatedComponentsGrid = (props) => {
  const columns = GetColumnsByGrid(3);
  const { parentData, programId, categoryId } = props;
  const { data, isLoading, error } = useSWR(`/api/kits/relatedItems/${parentData.name}`,fetcher);
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const [openConsolidateModal, setOpenConsolidateModal] = useState(false);
  const [openVendorInventoryModal, setOpenVendorInventoryModal] =
    useState(false);
  const [internalIdState, setInternalIdState] = useState(null);
  const [modalName, setModalName] = useState(null);

  const showConsolidateDialog = (e) => {
    console.log("e", e.row?.data);
    setOpenConsolidateModal(true);
    setInternalIdState(e.row.data.memberInternalID);
    setModalName(e.row.data.namepart);
  };
  const dataGridRef = useRef(null);

  const showInventoryDialog = (e) => {
    console.log("e", e.row?.data);
    setOpenInventoryModal(true);
    setInternalIdState(e.row.data.memberInternalID);
    setModalName(e.row.data.namepart);
  };

  const showVendorInventoryDialog = (e) => {
    setOpenVendorInventoryModal(true);
    setInternalIdState(e.row.data.memberInternalID);
    setModalName(e.row.data.namepart);
  };

  const handleCloseDialog = () => {
    setOpenInventoryModal(false);
    setOpenConsolidateModal(false);
    setOpenVendorInventoryModal(false);
  };

  const renderInventoryModal = () => {
    if (openInventoryModal === true) {
      return (
        <InventoryModal
          open={true}
          onClose={handleCloseDialog}
          internalId={internalIdState}
          name={modalName}
          categoryId={categoryId}
          programId={programId}
        />
      );
    }
    return <></>;
  };

  const renderVendorInventoryModal = () => {
    if (openVendorInventoryModal === true) {
      return (
        <VendorModal
          open={true}
          onClose={handleCloseDialog}
          internalId={internalIdState}
          name={modalName}
          categoryId={categoryId}
          programId={programId}
        />
      );
    }
    return <></>;
  };

  const renderConsolidateModal = () => {
    if (openConsolidateModal === true) {
      return (
        <ConsolidateModal
          open={true}
          onClose={handleCloseDialog}
          internalId={internalIdState}
          name={modalName}
          categoryId={categoryId}
          programId={programId}
        />
      );
    }
  }
  const updateGridDimensions = () => {
    dataGridRef.current.instance.updateDimensions();
  };


  const renderDetail = (props) => {
    const { id, memberInternalId, replacedBy, replaces, baseItem, parent } = props.data;
    return <RelatedComponentsDetails ID={id} memberInternalID={memberInternalId} replacedby={replacedBy} replaces={replaces} baseitem={baseItem} parentName={parent} updateGridDimension={updateGridDimensions} />
  }

  const dataSource = data;
  let gridData = [];
  if (dataSource) {
    gridData = dataSource.map((obj) => {
      return {
        ...obj,
        lastDateItem:
          obj.lastDateItem === "0001-01-01T00:00:00"
            ? ""
            : Moment(obj.lastDateItem).format("yyyy-MM-DD"),
      };
    });
  }

  if (error) return <div>Failed to load</div>
  if (isLoading) return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
  return (
    <>
      <div id="RelatedComponentsGrid">
        <DataGrid
          ref={dataGridRef}
          dataSource={gridData}
          showBorders={true}
          onRowPrepared={handleRowPrepared}
          onCellPrepared={handleCellPrepared}
          allowColumnResizing={true}
          columnResizingMode={'widget'}
          height={"65vh"}
          width={"100%"}
          filterMode={'withAncestors'}
        >
          <FilterRow visible={true} />
          <FilterPanel visible={true} />
          <HeaderFilter visible={true} />
          <ColumnFixing enabled={true} />
          <StateStoring enabled={true} type="localStorage" storageKey="relatedComponents" />
          <Scrolling mode="infinite" />
          {renderColumn("memberItem", <Column dataField="memberItem" caption="Member Item" width={"110px"} />)}
          {renderColumn("universalProductType", <Column dataField="universalProductType" caption="Universal Product Type" width={"150px"} />)}
          {renderColumn("l12M", <Column dataField="l12M" caption="L12M Qty Sold" width={"60px"} />)}
          {renderColumn("bo", <Column dataField="bo" caption="BO" width={"60px"} />)}
          {renderColumn("actualMemberQuantity", <Column dataField="actualMemberQuantity" caption="Qty Members Required" width={"60px"} />)}
          {renderColumn("inbound", <Column dataField="inbound" caption="Inbound" width={"60px"} cellRender={(e) => LinkCellBig(e, showConsolidateDialog)} />)}
          {renderColumn("ljlInventory", <Column dataField="ljlInventory" caption="Vendor Inventory" width={"60px"} cellRender={(e) => LinkCellBig(e, showVendorInventoryDialog)} />)}
          {renderColumn("qtyAvailable", <Column dataField="qtyAvailable" caption="Qty Available in NS" width={"60px"} cellRender={(e) => LinkCellBig(e, showInventoryDialog)} />)}
          {renderColumn("qtyRemaining", <Column dataField="qtyRemaining" caption="Qty Remaining in NS" width={"60px"} />)}
          {renderColumn("po", <Column dataField="po" caption="Qty Member PO" width={"60px"} />)}
          {renderColumn("lastDateItem",<Column dataField="lastDateItem" caption="Last Date Received" format={"yyyy-MM-dd"} dataType={"date"} width={"10vw"}/>)}
          {renderColumn("preferredVendor", <Column dataField="preferredVendor" caption="Preferred Vendor " width={"10vw"} />)}
          {renderColumn("specialSystem", <Column dataField="specialSystem" caption="Exclusivity" width={"10vw"} />)}
          <MasterDetail enabled={true} render={renderDetail} />
        </DataGrid>
        <GridTooltips columns={columns} />
        {renderInventoryModal()}
        {renderConsolidateModal()}
        {renderVendorInventoryModal()}
      </div>
    </>
  );
}

export default RelatedComponentsGrid;
