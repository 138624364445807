import { custom } from "devextreme/ui/dialog";

export const showConfirmDeleteAllDialog = () => {
    let myDialog = custom({
      title: "Please Note",
      messageHtml:
        "<center>This action will clear all user entered values (PO Qty, Notes)</center> <center>in the current Program and Category</center><center>within the permissions of your current role.</center><center>Are you sure you wish to proceed?</center>",
      buttons: [
        {
          text: "Yes, DELETE USER INPUT",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: "Cancel",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
  
    return myDialog.show();
  };