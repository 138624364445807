import { FETCH_SETTINGS, FETCH_SETTINGS_LOADING, POST_SETTINGS, POST_SETTINGS_LOADING } from "../actions/types";


const settingsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SETTINGS_LOADING:
            return { ...state, 'loading': true, 'saving': false };
        case FETCH_SETTINGS:
            const data = action.payload.reduce(function (r, a) {
                r["categoryId_" + a.idCategory] = r["categoryId_" + a.idCategory] || [];
                r["categoryId_" + a.idCategory]["programId_" + a.idProgram] = r["categoryId_" + a.idCategory]["programId_" + a.idProgram] || [];
                r["categoryId_" + a.idCategory]["programId_" + a.idProgram]["codeLetter_" + a.code[0]] = a;
                return r;
            }, Object.create(null));
            return { ...state, 'loading': false, 'saving': false, dataArray: data }
        case POST_SETTINGS_LOADING:
            return { ...state, 'saving': true }
        case POST_SETTINGS:
            return { ...state, 'saving': false }
        default:
            return state;
    }
};

export default settingsReducer;