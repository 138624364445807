import { MsalAuthenticationTemplate } from "@azure/msal-react";
import './App.css';
import { InteractionType } from "@azure/msal-browser";
import { Website } from "./Website";
import { Routes, Route } from "react-router-dom";
import withAppInsights from "../src/common/ApplicationInsights"
import ErrorPage from "./components/error/error";

function App() {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </MsalAuthenticationTemplate>

  );
}

export default withAppInsights(App);
