import { FETCH_BACK_LOADING, FETCH_BACK } from "../actions/types";


const backReducer =  (state = {'Back':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_BACK_LOADING:
            return {...state, 'loading': true, 'Back':null };
        case FETCH_BACK:
            return { ...state, [action.internalId]:action.payload, 'loading': false}
        default:
            return state;
    }
};

export default backReducer;