import { Typography } from "@mui/material";
import { Button, List, RadioGroup, ScrollView } from "devextreme-react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFeaturesViewerAllByRole, updateFeaturePermissionsByRole } from "../../../../actions";
import useSWR from 'swr'
import { fetcher } from "../../../../actions";
const _ = require("lodash");



function GroupTemplate(item) {
    return <div style={{ padding: "-1px 0px 10px", marginTop: "-10px" }}>Assigned: {item.key}</div>
}

const groupArray = (data) => {
    let grouped_data = _.groupBy(data, "typeFeature")
    var result = Object.keys(grouped_data).map(x => {
        return { "key": x, "items": grouped_data[x] }
    });
    return result;
}

export const FeaturesByRole = (props) => {
    const { roleId } = props;
    const [controlState, setControlState] = useState({});
    const { data, isLoading } = useSWR(`/api/RoleFeatures/reportRolesFeaturesPermissions?idRole=${roleId}`, fetcher);

    const dispatch = useDispatch();
    const setPermissionValue = (e, item) => {
        // update the database here
        dispatch(updateFeaturePermissionsByRole(item.idRole, item.idFeature, e));


        let groupIndex = controlState.findIndex(x => x.items.find(x => x.idFeature === item.idFeature));
        let items = [...controlState[groupIndex].items];
        let objectIndex = items.findIndex(x => x.idFeature === item.idFeature);
        let newItem = { ...item };
        newItem.permission = e;
        items[objectIndex] = newItem;
        const result = [...controlState];
        result[groupIndex] = { ...controlState[groupIndex], "items": items };
        setControlState([...result]);
    }

    const setViewerAll = (roleId) => {
        dispatch(setFeaturesViewerAllByRole(roleId))
    }

    const renderItem = (item) => {
        const writePermissions = [
            { id: 0, text: 'No' },
            { id: 1, text: 'Viewer' },
            { id: 2, text: 'Editor' }
        ];
        const readPermissions = [
            { id: 0, text: 'No' },
            { id: 1, text: 'Viewer' }
        ];
        let permissions = writePermissions;
        if (item.editable === 0) {
            permissions = readPermissions;
        }
        return <div>
            <div>Assigned: {item.nameFeature}</div>
            <div>
                <RadioGroup id="radio-group-with-selection" items={permissions} value={item.permission}
                    valueExpr="id" displayExpr="text" onValueChange={(e) => setPermissionValue(e, item)} />
            </div>
        </div>;
    }

    const grouped = groupArray(data);
    useEffect(() => {
        setControlState(grouped);
    }, [data]);


    if (isLoading) {
        return <></>
    }

    return (
        <div id="FeaturesByRoleContainer">
            <div><br></br></div>
            <div>
                <Typography display="block" variant="body2">
                    Define access to predefined menu and features depending on the selected role,
                </Typography>
                <Typography display="block" variant="body2">
                    None: The role with this permission has no access to the feature
                </Typography>
                <Typography display="block" variant="body2">
                    Viewer: The role with this permission can only access but not edit anything
                </Typography>
                <Typography display="block" variant="body2">
                    Editor: The role with this permission can only access and edit
                </Typography>
            </div>
            <div><br></br></div>
            <div><Button onClick={() => setViewerAll(roleId)} >Viewer All</Button></div>
            <div id="FeaturesByRoleGridContainer" style={{ height: "38vh", marginTop: "1vh" }}>
                <ScrollView width='100%' height='100%'>
                    <div className="list-container">
                        <List
                            dataSource={controlState}
                            height="100%"
                            grouped={true}
                            collapsibleGroups={true}
                            groupRender={GroupTemplate}
                            itemRender={renderItem}
                        />
                    </div>
                </ScrollView>
            </div>
        </div>


    );
}


export default FeaturesByRole; 
