import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./GlobalNotification.css";

export const GlobalNotification = (props) => {
    const exportMessage = useSelector((state) => state.signalR.exportData);
    const [visible, setvisible] = useState(false);
    const userId = useSelector((state) => state.userInfo.userAuthenticated.id);

    const onHiding = () => {
        setvisible(false);
    }

    useEffect(() => {
        if (exportMessage) {
            if (exportMessage.adUserId === userId) {
                setvisible(true);
            }
        }
    }
        , [exportMessage, userId]);

    if (!visible) {
        return null;
    }
    return (
        <>
            <div>
                <div className="dx-card responsive-paddings dx-theme-accent-as-background-color global-notification-text global-notification-text-orientation" onClick={onHiding}>
                    <div className="dx-card-block">
                        <div className="dx-card-text ">
                            <p>All data export completed</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}