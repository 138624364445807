import { useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import { useDispatch } from 'react-redux';

const useSignalR = (hubUrl) => {
    const [connection, setConnection] = useState(null);
    const isConnected = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${hubUrl}/hubs/grid`)
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

        const startConnection = async () => {
            try {
                await newConnection.start();
                console.info("Signal R connection started");
                isConnected.current = true;

                newConnection.on("BroadcastMessage", (message) => {
                    const { type, data } = message;
                    switch (type) {
                        case 'gridUpdate':
                            dispatch({ type: 'DATAGRID_VALUE_CHANGED', payload: data });
                            break;
                        case 'Export':
                            dispatch({ type: 'DATAGRID_EXPORT_COMPLETED', payload: data });
                            break;
                        default:
                            console.warn(`Unhandled message type: ${type}`);
                    }
                }
                );

                newConnection.on('SendGroupMessage', async message => {
                    const { type, data } = message;
                    switch (type) {
                        case 'gridUpdate':
                            dispatch({ type: 'DATAGRID_VALUE_CHANGED', payload: data });
                            break;
                        case 'Export':
                            dispatch({ type: 'DATAGRID_EXPORT_COMPLETED', payload: data });
                            break;
                        default:
                            console.warn(`Unhandled message type: ${type}`);
                    }
                });
            } catch (error) {
                console.error('Error starting connection or joining group:', error);
            }
        };

        startConnection();

        return () => {
            if (newConnection) {
                newConnection.stop();
            }
        };
    }, [hubUrl, dispatch]);

    return isConnected.current;
};

export default useSignalR;
