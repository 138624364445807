import {
  DataGrid,
  Summary,
  TotalItem,
  Column,
  Scrolling,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  ColumnFixing,
} from "devextreme-react/data-grid";
import Moment from "moment";
import { LinearProgress } from "@mui/material";
import { fetcher } from "../../../../actions";
import useSWR from "swr";

const getDataGrid = (data) => {
  if (!data) {
    return null;
  }
  const newArr = data.map((obj) => {
    var date =
      obj.date === "0001-01-01T00:00:00"
        ? ""
        : Moment(obj.date).format("yyyy-MM-DD");
    return { ...obj, FormattedDate: date };
  });
  return newArr;
};

const InfoCurrentPeriodsGrid = (props) => {
  const { internalId } = props;
  const { data: dataSourceCurrent, isLoading } = useSWR(
    `/api/Report/reportCurrentTimePeriod?InternalID=${internalId}`,
    fetcher
  );
  const gridData = getDataGrid(dataSourceCurrent);

  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      {dataSourceCurrent && dataSourceCurrent?.length > 0 && (
        <div id="InfoCurrentPeriodsGrid">
          <div>
            <h3>Transactions From Current Month</h3>
          </div>
          <br />
          <DataGrid
            dataSource={gridData}
            showBorders={true}
            columnMinWidth={10}
            columnAutoWidth={true}
            height={"35vh"}
            width={"97%"}
            columnResizingMode={"widget"}
            filterMode={"withAncestors"}
          >
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <ColumnFixing enabled={true} />
            <Scrolling mode="infinite" />
            <Column dataField="FormattedDate" caption="Date" format={"yyyy-MM-dd"} dataType={"date"} />
            <Column dataField="documentNumber" caption="Document Number" />
            <Column dataField="customerName" caption="Customer Name" />
            <Column dataField="quantity" caption="Quantity" />
            <Column dataField="assemblyItem" caption="Assembly Item" /> 
            <Summary>
              <TotalItem
              column="quantity"
              summaryType="sum"
              displayFormat="{0}"
              />
            </Summary>
          </DataGrid>
        </div>
      )}
    </>
  );
};

export default InfoCurrentPeriodsGrid;
