import { Button, Popup, ScrollView } from "devextreme-react";

export const ModalContainer = (props) =>{
    const { open, onClose, modalId, children, title } = props;

    const renderTitle = (title) => {
        return <>
          {title}
        </>
      }
    
      const renderCloseButton = () => {
    ;
        return (
          <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}><Button onClick={() => onClose()}>X</Button></div>
        )
    
      }
    
      const displayTitle = (title) => {
        return <>
          <div style={{ display: "flex", justifyContent: "space-between" }} >
            {renderTitle(title)}
            {renderCloseButton()}
          </div>   
        </>
      }

    return (
        <div >
          <Popup id="popup"
            visible={open}
            onHiding={onClose}
            showTitle={true}
            titleRender={()=>displayTitle(title )}
            width="60vw"
            resizeEnabled={true}
          >
             {children}
          </Popup>
        </div>
      );
}

export default ModalContainer;