import { Tooltip } from "devextreme-react";

export const GridToolTip = (props) => {

    const { columnCode, toolTip } = props;


    /**
  * Method that generates the tooltips to be displayed in the grid names separated by newlines
  * @param {*} columnCode 
  * @returns 
  * Developer: Cnino
  * Date: 2023/01/23
  */
    const showColumnTooltip = (toolTip) => {
                return <span className='tooltip-text-style'>{toolTip}</span>
    };



    const renderTooltip = (columnCode,toolTip) => {
        if (toolTip!==null){
            return (
                <>
                    <Tooltip
                        target={`.dx-header-row .tooltip-target-${columnCode}`}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        key={`tooltip-column-${columnCode}`}
                        >
                        {showColumnTooltip(toolTip)}
                    </Tooltip>
                </>
            );
        }
        return<></>
        
    }

    return renderTooltip(columnCode, toolTip);

}