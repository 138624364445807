import { Button, CheckBox, DataGrid, LoadPanel, Popup, Toast } from "devextreme-react"
import { Column, Editing, Item, Scrolling, Toolbar } from "devextreme-react/data-grid";
import { getProgramCategoriesByTemplateId, updateProgramCategoriesTemplate } from "../../../../../actions";
import { useEffect, useRef, useState } from "react";
import { FilterRow } from "devextreme-react/tree-list";

export const CloneGridTemplateDialog = (props) => {
    const open = props.open;
    const templateId = props.templateId;
    const onClose = props.onClose;
    const [gridData, setGridData] = useState(null);
    const grid = useRef(null);

      
    useEffect(() => {
        let mounted = true
        // React advises to declare the async function directly inside useEffect
        async function getGridData() {
            const response = await getProgramCategoriesByTemplateId(templateId);
            let newState = response.data.map(x => { return { ...x, selected: x.selected, modified: false } });
            if (mounted)
            setGridData(newState);
        };
        getGridData();

        return () => {
            mounted = false
          }
  
    }, [templateId, open]);

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    });

    const [loadPanelConfig, setloadPanelConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    });


    const hideLoadPanel = () => {
        setloadPanelConfig({
            ...loadPanelConfig,
            isVisible: false
        });
    }

    const showLoadPanel = () => {
        setloadPanelConfig({
            ...loadPanelConfig,
            isVisible: true
        });
    }

    const switchSelectAll = (e) => {
        let newState = gridData.map((item) => {
            return { ...item, selected: e.value, modified: true }
        });
        setGridData(newState);
    }

    const saveCloneGrid = async () => {
        showLoadPanel();
        let data = gridData.filter(x => x.modified === true).map(x => { return { "selected": x.selected, "categoryId": x.categoryId, "programId": x.programId, "templateId": templateId } });
        await updateProgramCategoriesTemplate(data).then(() => {
            let newState = gridData.map(x => { return { ...x, modified: false } });
            setGridData(newState);
            hideLoadPanel();
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: 'Template cloned successfully'
            });
        });
    }

    const renderSelectedHeader = (data) => {
        return (
            <p style={{ fontSize: '14px' }}>{`${data.column.caption} `} <CheckBox onValueChanged={switchSelectAll}></CheckBox></p>
        )
    }

    const onRowUpdated = (e) => {
        const newState = gridData.map(obj => {
            if (obj.programId === e.data.programId && obj.categoryId === e.data.categoryId) {
                return { ...obj, modified: !obj.modified };
            }
            return obj;
        });
        setGridData(newState);
    }

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    return (
        <div>
            <Popup
                visible={open}
                onHiding={() => onClose("showCloneGridTemplate", false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                resizeEnabled={false}
                title={"Clone Template"}
                height={"65vh"}
                width={"50vw"}
            >
                <div>
                    <DataGrid
                        id="templatesGrid"
                        dataSource={gridData}
                        height={"50vh"}
                        showBorders={true}
                        ref={grid}
                        onRowUpdated={(e) => { onRowUpdated(e) }}
                    >
                        <Toolbar>
                            <Item location="before">
                                <Button
                                    icon='save'
                                    onClick={() => saveCloneGrid()} />
                            </Item>
                        </Toolbar>
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            useIcons={false}
                            startEditAction={'click'}
                        ></Editing>
                        <Scrolling mode="virtual" />
                        <FilterRow visible={true} />

                        <Column dataField="programName" caption="Program" visible={true} allowEditing={false} />
                        <Column dataField="categoryName" caption="Category" visible={true} allowEditing={false} />
                        <Column dataField="selected" visible={true} headerCellRender={renderSelectedHeader} allowSorting={false} />
                    </DataGrid>
                    <Toast
                        visible={toastConfig.isVisible}
                        message={toastConfig.message}
                        type={toastConfig.type}
                        onHiding={onHiding}
                        displayTime={600}
                    />
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        onHiding={hideLoadPanel}
                        visible={loadPanelConfig.isVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        hideOnOutsideClick={false}
                        message="Cloning Template..."
                    />

                </div>
            </Popup>
        </div>
    );
}

export default CloneGridTemplateDialog;