import { Button, CheckBox, DataGrid, LoadPanel, Popup, Toast } from "devextreme-react"
import { Column, Editing, Item, Scrolling, Toolbar } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { FilterRow } from "devextreme-react/tree-list";
import { getGridTemplateShareUsersRoleByTemplateId, updateGridTemplateShare } from "../../../../../actions/GridTemplateShare";

export const ShareGridTemplateDialog = (props) => {
  const { templateId } = props;
  const open = props.open;
  const [gridData, setGridData] = useState([]);
  const onClose = props.onClose;

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
  });

  const [loadPanelConfig, setloadPanelConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
  });

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }
  const hideLoadPanel = () => {
    setloadPanelConfig({
      ...loadPanelConfig,
      isVisible: false,
    });
  };

  const showLoadPanel = () => {
    setloadPanelConfig({
      ...loadPanelConfig,
      isVisible: true,
    });
  };

  useEffect(() => {
    let mounted = true;
    async function getUsershared(templateId) {
      if (templateId !== 0) {
        const response = await getGridTemplateShareUsersRoleByTemplateId(
          templateId
        );
        let newState = response.map((x) => {
          return { ...x, selected: x.selected, modified: false };
        });
        if (mounted) setGridData(newState);
      }
    }
    getUsershared(templateId);
    return () => {
      mounted = false;
    };
  }, [templateId, open]);

  const switchSelectAll = (e) => {
    let newState = gridData.map((item) => {
      return { ...item, selected: e.value, modified: true };
    });
    setGridData(newState);
  };

  const saveShareGrid = async () => {
    showLoadPanel();
    let data = gridData
      .filter((x) => x.modified === true)
      .map((x) => {
        return { selected: x.selected, userId: x.userId };
      });
    await updateGridTemplateShare(templateId, data).then(() => {
      hideLoadPanel();
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Template shared successfully",
      });
    });
  };

  const onRowUpdated = (e) => {
    const newState = gridData.map((obj) => {
      if (obj.userId === e.data.userId) {
        return { ...obj, modified: !obj.modified };
      }
      return obj;
    });
    setGridData(newState);
  };

  const renderSelectedHeader = (data) => {
    return (
      <p style={{ fontSize: "14px" }}>
        {`${data.column.caption} `}{" "}
        <CheckBox onValueChanged={switchSelectAll}></CheckBox>
      </p>
    );
  };

    return (
        <div>
            <Popup
                visible={open}
                onHiding={() => onClose("showShareGridTemplate", false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                resizeEnabled={false}
                title={"Share Template"}
                height={"65vh"}
            >
                <div>
                    <DataGrid
                        id="templatesGrid"
                        dataSource={gridData}
                        height={"50vh"}
                        showBorders={true}
                        onRowUpdated={onRowUpdated}

                    >
                        <Scrolling mode="virtual" />
                        <FilterRow visible={true} />
                        <Toolbar>
                            <Item location="before">
                                <Button
                                    icon='save'
                                    onClick={() => saveShareGrid()} />
                            </Item>
                        </Toolbar>
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            useIcons={false}
                            startEditAction={'click'}
                        ></Editing>
                        <Column dataField="name" caption="Name" visible={true} allowEditing={false} />
                        <Column dataField="selected" visible={true} headerCellRender={renderSelectedHeader} allowSorting={false} />
                    </DataGrid>
                </div>
                <Toast
                    visible={toastConfig.isVisible}
                    message={toastConfig.message}
                    type={toastConfig.type}
                    onHiding={onHiding}
                    displayTime={600}
                />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    onHiding={hideLoadPanel}
                    visible={loadPanelConfig.isVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                    message="Cloning Template..."
                />
            </Popup>
        </div>
    );
}
export default ShareGridTemplateDialog;
