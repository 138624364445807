import { useEffect, useState } from "react";
import { DataGrid, Column, Editing, Scrolling, HeaderFilter } from "devextreme-react/data-grid";
import "./exclusionsMainBox.css";

export const ItemsGrid = (props) => {
    const { gridData, isCustomerType, selectedName, setShowError } = props;
    const [data, setData] = useState(null)

    function setCellValueExcludedQty(
        newData,
        value,
        currentRowData,
        dataSource,
    ) {
        const index = dataSource.findIndex(x => x.id === currentRowData?.id);
        if (index !== -1) {
            dataSource[index].eqty = value;
        }
        newData[index] = value;
        const hasInvalidEqty = value < 0 || value > currentRowData?.qty;
        setData(dataSource);
        setShowError(hasInvalidEqty);

    }
    const onCellPrepared = (e) => {
        if (e?.rowType === 'data' && e?.isAltRow === true) {
            e.cellElement.style.backgroundColor = "#f5f5f5";
        }
        if (e?.data?.eqty > e?.data?.qty || e?.data?.eqty < 0 || e?.data?.exclusions === true) {
            e.cellElement.style.color = "#FF0000";
        }
        if (e.rowType === "data" && e.column.allowEditing) {
            e.cellElement.style.backgroundColor = "#f5f2d8";
        }
    };

    useEffect(() => {
        setData(gridData);
    }, [gridData]);

    return (
        < div>
            <div className="flex-item" style={{ display: 'flex', justifyContent: 'center', marginTop: "5vh", marginBottom: "5vh" }}>
                <h3>{selectedName}</h3>
            </div>
            <DataGrid
                dataSource={data}
                showBorders={true}
                columnMinWidth={10}
                columnAutoWidth={true}
                height={"31vh"}
                width={"100%"}
                columnResizingMode={"widget"}
                wordWrapEnabled={true}
                filterMode={"withAncestors"}
                onCellPrepared={(e) => onCellPrepared(e)}
            >

                <Editing mode="cell" allowUpdating={true}
                />
                <Scrolling mode="virtual" />
                <HeaderFilter visible={true} />
                <Column
                    dataField="name"
                    caption="Item Name"
                    alignment="left"
                    allowEditing={false}
                />
                {!isCustomerType && (
                    <Column
                        dataField="qty"
                        caption="IF Qty"
                        alignment="left"
                        allowEditing={false}
                    />)}
                {!isCustomerType && (
                    <Column
                        dataField="eqty"
                        caption="Excluded Qty"
                        alignment="left"
                        allowEditing={true}
                        dataType="number"
                        setCellValue={(newData, value, currentRowData) => setCellValueExcludedQty(newData, value, currentRowData, data)}
                    />)}
                <Column
                    dataField="exclusionNote"
                    caption="Exclusion Notes"
                    alignment="left"
                    allowEditing={true}
                />
                <Column
                    dataField="exclusions"
                    caption="Exclusion"
                    alignment="left"
                    allowEditing={false}
                />
            </DataGrid>
        </div >
    );
}
