import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminInformation, fetchAllCategoriesByProgram, fetchAllPrograms } from "../../actions";
import AdminRoles from "./adminRoles";
import AdminUserRoles from "./adminUserRoles";


const RolesMainBox = () => {
const dispatch = useDispatch();
const roles = useSelector(state => state.admin.roles)
const programs = useSelector(state => state.admin.programs)
const categories = useSelector(state => state.admin.categories)
    useEffect(() => {
        if (!roles){
            dispatch(fetchAdminInformation());
        }
        if (!categories){
            dispatch(fetchAllCategoriesByProgram(0));
        }
        if (!programs){
            dispatch(fetchAllPrograms());
        }

    }, []);


if (!roles){
    return <></>
}

    return (
        <div id="container" style={{ display: "flex", paddingTop:"2vh" }}>
            <div id="controls" style={{ width: "100%" }}>
                <div style={{ maxWidth: "95%", paddingTop: "3px", paddingLeft: "1vw" }}>
                    <div>
                        <AdminRoles roles={roles}/>
                    </div>
                    <div style={{ paddingTop:"1vh"}}>
                        <AdminUserRoles roles={roles}/>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default RolesMainBox