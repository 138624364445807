import { Button, Popup } from "devextreme-react";
import KitsGrid from "./kitsGrid";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { memo } from "react";
import { LoadPanel } from "devextreme-react"
import PropTypes from 'prop-types';
import { fetcher } from "../../../../actions";
import useSWR from "swr";
import "./kitsModal.css";
import { ModalCloseButton } from "../../../tools/ModalCloseButton";

export const KitsModal = memo(({ open, onClose, name, internalId }) => {
  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(`/api/Report/reportActiveKits?InternalID=${internalId}`, fetcher);

  const renderTitle = () => {
    const { publicItems, nonPublicItems, undisclosedItems } = dataSource;

    return (
      <h3>
        {name} - Total Current Systems ({publicItems}{" "}
        <RemoveRedEyeOutlinedIcon className="greenIcon" />|{" "}
        {nonPublicItems}{" "}
        <VisibilityOffOutlinedIcon className="orangeIcon" />|{" "}
        {undisclosedItems} <VisibilityOffOutlinedIcon />)
      </h3>
    );
  };

  const renderCloseButton = () => (
    <div className="closeButtonContainer">
      <ModalCloseButton onClose={() => onClose("kits", false)} />
    </div>
  );


  const displayTitle = () => {
    return (
      <div className="titleContainer">
        {renderTitle()}
        {renderCloseButton()}
      </div>
    );
  };

  if (error) return <div>Failed to load</div>;

  if (isLoading === true) {
    return (<LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={true} />)
  }

  return (
    <div>
      <Popup
        id="popup"
        visible={open}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        minWidth="60vw"
        height={"80vh"}
        resizeEnabled={true}
      >
        <KitsGrid dataSource={dataSource.data} />
      </Popup>
    </div>
  );
});

KitsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  internalId: PropTypes.string
};

export default KitsModal;
