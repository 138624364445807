import { FETCH_KIT, FETCH_KIT_LOADING } from "../actions/types";


const kitReducer = (state = {'Kits':null, 'loading': false }, action) => {
    switch (action.type) {
        case FETCH_KIT_LOADING:
            return { ...state, 'loading': true, 'Kits': null };
        case FETCH_KIT:
            return { ...state, [action.internalId]:action.payload  , 'loading': false }
        default:
            return state;
    }
};

export default kitReducer;