import { Button, SelectBox } from "devextreme-react";
import DataGrid, { Column, Editing, FilterPanel, FilterRow, HeaderFilter, StateStoring } from "devextreme-react/data-grid";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUserProgramCategories, deleteUserProgramCategories, fetchUserProgramCategories, fetchAllCategoriesByProgram } from "../../../../actions";

export const PermissionsByUser = (props) => {
    const { userId } = props;

    const [state, setstate] = useState({ "programId": 0, "categoryId": 0, "addButtonVisible": false });

    const programs = useSelector(state => state.admin.programs)
    const categories = useSelector(state => state.admin.categories_by_program)
    const userProgramsCategories = useSelector(state => state.admin.programCategories);
    const isLoading = useSelector(state => state.admin.loading)
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId!==0){
            dispatch(fetchUserProgramCategories(userId));
        }      
    }, [userId]);

    if (isLoading) {
        return <></>
    }

    /**
     * Developer: CNino
     * Date: 2022-11-01
     * Remarks: The call of the categories is added according to the program selected by the user
     * @param {*} e 
     */
    const setProgramId = (e) => {
        setstate({ ...state, "programId": e, "addButtonVisible": ((e !== 0) && (state.categoryId !== 0)) });
        dispatch(fetchAllCategoriesByProgram(e));
    }
    const setCategoryId = (e) => {
        setstate({ ...state, "categoryId": e, "addButtonVisible": ((state.programId !== 0) && (e !== 0)) });
    }

    const saveUserProgramCategories = (e, idUser, idProgram, idCategory) => {
        dispatch(createUserProgramCategories(idUser, idProgram, idCategory));
    }

    const onSaving = (e) => {
        const change = e.changes[0];
        if (change.type === "remove") {
            const deletedRow = change.key;
            const idUser = deletedRow.idUser;
            const idCategory = deletedRow.idCategory;
            const idProgram = deletedRow.idProgram;
            dispatch(deleteUserProgramCategories(idUser, idProgram, idCategory));
        }
    };

    if (userId===0){
        return <></>
    }

    return (
        <>
            <div style={{ display: "flex" }}>
                <div>
                    <div>Programs</div>
                    <div>
                        <SelectBox dataSource={programs}
                            displayExpr="name"
                            valueExpr="programId"
                            onValueChange={setProgramId}
                            defaultValue={state.programId}
                        />
                    </div>
                </div>
                <div style={{ paddingLeft: "1vw" }}>
                    <div >Categories</div>
                    <div >
                        <SelectBox dataSource={categories}
                            displayExpr="categoryName"
                            valueExpr="categoryId"
                            onValueChange={setCategoryId}
                            defaultValue={state.categoryId}
                        />
                    </div>
                </div>

            </div>
            <div style={{ paddingTop: "1vh" }}>
                <Button icon="plus" visible={state.addButtonVisible} onClick={(e) => saveUserProgramCategories(e, userId, state.programId, state.categoryId)}>Add</Button>
            </div>
            <div id="kitsGrid" style={{ height: "60vh", paddingTop: "1vh" }} >
                <DataGrid
                    id="gridContainer"
                    dataSource={userProgramsCategories}
                    showBorders={true}
                    height={"100%"}
                    onSaving={onSaving}

                >
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <StateStoring enabled={true} type="localStorage" />
                    <Editing
                        allowDeleting={true}
                        useIcons={true}
                    >
                    </Editing>
                    <Column dataField="idUser" caption="idUser" visible={false} />
                    <Column dataField="idProgram" caption="ProgramId" visible={false} />
                    <Column dataField="program" caption="Program" allowEditing={false} />
                    <Column dataField="idCategory" caption="categoryId" visible={false} />
                    <Column dataField="category" caption="Category" allowEditing={false} />
                </DataGrid>

            </div>
        </>

    );
}
export default PermissionsByUser;