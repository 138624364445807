import { Button, Popup, ScrollView } from "devextreme-react";
import LogGrid from "./LogGrid";

export const LogModal = (props) => {
  const { onClose, name, internalId, programId, categoryId } = props;

  const renderTitle = () => {
    return (
      <>
        <h3>{name} - Editable Fields Log </h3>
      </>
    );
  };
  const renderCloseButton = () => {
    return (
      <div style={{ paddingTop: "10px", right: "1vw", top: "4vh" }}>
        <Button onClick={() => onClose()}>X</Button>
      </div>
    );
  };
  const displayTitle = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {renderTitle()}
          {renderCloseButton()}
        </div>
      </>
    );
  };

  return (
    <>
      <Popup
        id="popup"
        visible={true}
        onHiding={onClose}
        showTitle={true}
        titleRender={displayTitle}
        width="55vw"
        height="20vw"
        resizeEnabled={true}
      >
        <div>
          <ScrollView height="100%" width="100%">
            <LogGrid
              internalId={internalId}
              programId={programId}
              categoryId={categoryId}
            />
          </ScrollView>
        </div>
      </Popup>
    </>
  );
};
