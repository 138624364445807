import { FETCH_CURRENT_PERIODS_LOADING, FETCH_CURRENT_PERIODS } from "../actions/types";


const infoCurrentPeriodsReducer =  (state = {}, action) => {
    switch (action.type) {
        case FETCH_CURRENT_PERIODS_LOADING:
            return {...state, 'loading': true };
        case FETCH_CURRENT_PERIODS:
            return { ...state,  [action.internalId]: action.payload ,'loading': false}
        default:
            return state;
    }
};

export default infoCurrentPeriodsReducer;