/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, useCallback } from "react";
import "./mainGrid.css";
import "devextreme/dist/css/dx.light.css";
import TreeList, {
  Editing,
  Selection,
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Scrolling,
  ColumnChooser,
  SearchPanel,
  Toolbar,
  Item,
  ColumnFixing,
  KeyboardNavigation,
  LoadPanel,
  Search,
} from "devextreme-react/tree-list";
import { useDispatch, useSelector } from "react-redux";
import LinkTooltipCell from "../grid/cellstyles/linkTooltipCell";
import LinkCell from "../grid/cellstyles/linkCell";
import { KitsModal } from "../grid/dialog/Kits/kitsModal";
import {
  fetchUniqueCustomers,
  fetchSettings,
} from "../../actions";
import { SettingsModal } from "../grid/dialog/Settings/SettingsModal";
import { VendorsModal } from "../grid/dialog/Vendors/VendorsModal";
import { PurchaseOrderModal } from "../grid/dialog/PurchaseOrder/PurchaseOrderModal";
import { LogModal } from "../grid/dialog/Log/LogModal";
import {
  renderL3MvsL12M,
  renderProjectedMoHAfterL12M,
  renderProjectedMoHAfterL3M,
  renderTopBuyerShare,
  renderTopBuyerName,
  rendery2y1,
  renderYoyCell,
  renderYoyStabilityCell,
  renderIconQtyCell
} from "./customCellStyle";
import UniqueCustomerModal from "../grid/dialog/UniqueCustomers/UniqueCustomerModal";
import {
  setCellValue,
  onCellPrepared,
  displayNode,
  disableNumericArrows
} from "./customGridActions";
import ExportModal from "../grid/dialog/Export/ExportModal";
import {
  CalculateAdditionalMoHLoad,
  calculatePoCostApprover,
  calculatePoCostMarket,
  calculatePoReviewerCost,
  calculateRevisedPoQty,
  calculateY2ToY1,
  calculateRemainingEstMoh,
  calculateTopBuyerShare,
} from "../../common/formulas";
import { BackModal } from "../grid/dialog/Back/BackModal";
import { excelExport } from "../../common/Export";
import { GridTooltips } from "../gridTooltip/gridTooltips";
import { SaveGridTemplateDialog } from "../grid/dialog/GridTemplate/saveGridTemplate";
import InventoryModal from "../grid/dialog/Inventory/InventoryModal";
import { ConsolidateModal } from "../grid/dialog/Consolidate/ConsolidateModal";
import { VendorModal } from "../grid/dialog/VendorInventory/VendorModal";
import { GetColumnsByGrid, customRenderColumn } from "../../common/Columns";
import { LinearProgress } from "@mui/material";
import PropTypes from 'prop-types';
import MohSelectBox from "./toolbar/Components/mohSelectBox";
import { getModifierValueId } from "../../common/mohFunctions";
import ToolBarButton from "./toolbar/Components/toolbarButton";
import { YearLabel } from "./functions/utils";
import ToolBarDropDownButton from "./toolbar/Components/toolbarDropDownButton";
import { ExportOptions } from "./Data/ExportOptions";
import { useInterval } from 'usehooks-ts'
import { getActiveGridTemplatesByUserProgramCategory } from "../../actions/gridTemplates/gridTemplatesActions";
import { clearCategoryGrid, clearGlobalGrid } from "./functions/cleanFields";
import { NetsuiteUpdateButton } from "../tools/NetsuiteUpdateButton";
import { GridSettingsModal } from "../grid/dialog/GridSettings/GridSettingsModal";
import { getGridAttributesByDefault, gridAttributesKey } from "../../common/gridSettings";

const MainGrid = (props) => {

  MainGrid.propTypes = {
    data: PropTypes.array,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    mutate: PropTypes.func,
    onMohModifierChange: PropTypes.func.isRequired,
    programId: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    selectedModifier: PropTypes.number.isRequired,
  };

  const { data, error, isLoading, mutate, onMohModifierChange, programId, categoryId } = props;
  const columns = GetColumnsByGrid(1);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const storageKey = `storageKey-${programId}-${categoryId}`;
  const gridInstance = useRef(null);
  const user = useSelector(state => state.userInfo.userAuthenticated);
  const programsList = useSelector(state => state.userInfo.programs);
  const categoriesList = useSelector(state => state.userInfo.categories);
  const currentPermissions = useSelector(state => state.userInfo.permissions);
  const selectedRoleId = useSelector(state => state.userInfo.selectedRole);
  const groupId = `Group${programId}${categoryId}`;
  const [selectedModifier, setSelectedModifier] = useState(1);
  const [expandAll, setExpandAll] = useState(true);
  const [selectedInternalId, setSelectedInternalId] = useState(null);
  const [selectedPartName, setSelectedPartName] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState({ open: false, programId: 0, categoryId: 0 });
  const [showKitModal, setShowKitModal] = useState(false);
  const [showUniqueCustomerModal, setShowUniqueCustomerModal] = useState(false);
  const [showPreferredVendorModal, setShowPreferredVendorModal] = useState(false);
  const [showBODModal, setShowBODModal] = useState(false);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [showConsolidateModal, setShowConsolidateModal] = useState(false);
  const [showVendorInventoryModal, setShowVendorInventoryModal] = useState(false);
  const [showPurchaseOrderModal, setShowPurchaseOrderModal] = useState(false);
  const [showSaveColumnConfigurationComponent, setShowSaveColumnConfigurationComponent] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showGridSettingsModal, setShowGridSettingsModal] = useState({ open: false });
  const [logModalData, setLogModalData] = useState({ internalId: null, categoryId: null, programId: null, open: false });
  const [gridSettings, setGridSettings] = useState(getGridAttributesByDefault());
  const [showGrid, setShowGrid] = useState(false);
  const incomingSignalrGridData = useSelector(state => state.signalR?.gridData);

  useEffect(() => {
    dispatch(fetchSettings(programId));
  }, [programId]);

  useEffect(() => {
    const loadTemplateAndUpdateGrid = async () => {
      try {
        const currentTemplate = await loadCurrentTemplate(storageKey);
        gridInstance.current.instance.state(currentTemplate);
        setShowGrid(true);
      } catch (error) {
        console.error('Error loading current template:', error);
      }
      finally {
        setShowGrid(true);
      }
    };

    setShowGrid(false);
    loadTemplateAndUpdateGrid();

  }, [storageKey]);

  useEffect(() => {
    const updateGridData = async () => {
      if (incomingSignalrGridData) {
          if (gridInstance) {
            let dataSource = gridInstance.current.instance.getDataSource();
            const store = dataSource.store();
            try {
              await store.update(incomingSignalrGridData.keyItem, { [incomingSignalrGridData.fieldName]: incomingSignalrGridData.fieldValue });
              dataSource.load();
            } catch (error) {
              console.error(error);
            }
          }
        }
    };

    updateGridData();
  }, [incomingSignalrGridData, programId, categoryId, gridInstance]);

  useInterval(
    () => {
      const saveTemplate = async () => {
        try {
          const state = gridInstance.current.instance.state();
          await saveCurrentState(storageKey, state);
        } catch (error) {
          console.error('Error saving current template:', error);
        }
      };
      saveTemplate();
    },
    30000
  );

  const [state, setState] = useState({
    data: {
      kits: {
        name: "",
        internalId: 0,
      },
      uniqueCustomer: {
        name: "",
        internalId: 0,
        Period: 1,
      },
      vendors: {
        name: "",
        internalId: 0,
      },
      back: {
        name: "",
        BO_total: 0,
        CommitTotal: 0,
        internalId: 0,
      },
      inventory: {
        name: "",
        internalId: 0,
      },
      consolidate: {
        name: "",
        internalId: 0,
      },
      vendor: {
        name: "",
        internalId: 0,
      },
      export: {
        filters: "",
      },
    },
  });

  const showKitDialog = useCallback((e) => {
    setExpandAll(false);
    setShowKitModal(true);
    setSelectedInternalId(e.row.data.InternalId);
    setSelectedPartName(e.row.data.Name);
  }, [setExpandAll, setShowKitModal, setSelectedInternalId, setSelectedPartName]);


  const showUniqueCustomerDialog = useCallback((e, Period) => {
    setExpandAll(false);
    setShowUniqueCustomerModal(true);
    setState({
      ...state,
      data: {
        ...state.data,
        uniqueCustomer: {
          ...state.data.kits,
          name: e.row.data.Name,
          internalId: e.row.data.InternalId,
          Period: Period,
        },
      },
    });
    dispatch(fetchUniqueCustomers(e.row.data.InternalId));
  }, [setExpandAll, setShowUniqueCustomerModal, setState, dispatch]);

  const showBODialog = useCallback((e) => {
    setExpandAll(false);
    setShowBODModal(true);
    setState({
      ...state,
      data: {
        ...state.data,
        back: {
          ...state.data.back,
          name: e.row.data.Name,
          BO_total: e.row.data.BO_total,
          CommitTotal: e.row.data.CommitTotal,
          internalId: e.row.data.InternalId,
        },
      },
    });
  }, [setExpandAll, setShowBODModal, setState]);

  const showLogDialog = useCallback((e) => {
    setLogModalData({ internalId: e.row.data.InternalId, categoryId: e.row.data.categoryId, programId: e.row.data.programId, name: e.row.data.Name, open: true });
  }, [setLogModalData]);

  const showDialog = (e, action) => {
    setExpandAll(false);
    action(true);
    setSelectedInternalId(e.row.data.InternalId);
    setSelectedPartName(e.row.data.Name);
  }

  const showExportDialog = () => {
    setShowExportModal(true);
  };

  const showPreferences = () => {
    setShowSettingsModal({ ...settings, open: true, categoryId: categoryId, programId: programId })
  };

  const showSaveColumnConfiguration = () => {
    setShowSaveColumnConfigurationComponent(true);
  };

  const handleSaveSettings = () => {
    setShowSettingsModal(false);
    mutate();
    dispatch(fetchSettings(programId));
  };

  const showGridSettings = () => {
    setShowGridSettingsModal({ open: true });
  };

  const handleSaveGridSettings = ({ columnLines, rowLines, borders, rowAlternation }) => {
    const gridAttributes = { columnLines, rowLines, borders, rowAlternation };
    setShowGridSettingsModal({ open: false });
    setGridSettings(gridAttributes);
    localStorage.setItem(gridAttributesKey, JSON.stringify(gridAttributes));
  };
  
  const renderLogModal = useCallback(() => {
    if (logModalData.open === true && logModalData.internalId !== null) {
      return (
        <LogModal
          open={true}
          onClose={() => setLogModalData({ internalId: null, categoryId: null, programId: null, open: false })}
          internalId={logModalData.internalId}
          name={logModalData.name}
          categoryId={logModalData.categoryId}
          programId={logModalData.programId}
        />
      );
    }
    return <></>;
  }, [logModalData]);

  const recalculateValues = (value) => {
    const selectedValue = getModifierValueId(value);
    onMohModifierChange(selectedValue);
    setSelectedModifier(selectedValue);
  };

  const isColVisible = useCallback((code) => {
    if (!currentPermissions) {
      return false;
    }
    let column = currentPermissions.filter((x) => x.ColumnCode === code)[0];
    return !!column;
  }, [currentPermissions]);

  const isColEditable = useCallback((code) => {
    if (!currentPermissions) {
      return false;
    }
    let column = currentPermissions.filter((x) => x.ColumnCode === code)[0];
    if (!column) {
      return false;
    }
    return column.Permission === 1;
  }, [currentPermissions]);

  const cleanUpdateFields = () => {
    const cleanUpdateFieldsAsync = async () => {
      if (programId === 0 && categoryId === 0) {
        await clearGlobalGrid(data, mutate, selectedRoleId, categoriesList, gridInstance, currentPermissions);
      }
      else {
        await clearCategoryGrid(data, mutate, programId, categoryId, currentPermissions);
      }
    }
    cleanUpdateFieldsAsync();
  };

  const renderDialog = useCallback((visible, component) => {

    if (visible) {
      return component;
    }
    return <></>;
  }, []);

  const loadCurrentTemplate = async (key) => {
    console.log("loadCurrentTemplate from local", key);
    try {
      let localTemplate = localStorage.getItem(key);
      if (localTemplate) {
        return JSON.parse(localTemplate);
      }
      console.log("loadCurrentTemplate from DB", key);
      const dbTemplate = await getActiveGridTemplatesByUserProgramCategory(user.id, programId, categoryId);
      localStorage.setItem(key, dbTemplate.template);
      console.log("dbTemplate", dbTemplate);
      return JSON.parse(dbTemplate.template);

    } catch (error) {
      console.error('Error loading current template:', error);
    }
    return null;
  };

  const saveCurrentState = async (key, state) => {
    try {
      console.log("saveCurrentState", key);
      const currentState = JSON.stringify(state);
      localStorage.setItem(key, currentState);
    } catch (error) {
      console.error('Error saving current template:', error);
    }
  };

  const handleTemplateChange = (currentTemplate) => {
    const state = JSON.parse(currentTemplate);
    gridInstance.current.instance.state(state);
  };

  const onNodesInitialized = (e) => {
    const visibleNodes = e.root.children.filter((x) => x.visible === true);
    visibleNodes.forEach(function (node) {
      displayNode(node, 1);
    });
  };

  const showExport = (e) => {
    let selectedOption = e.itemData.id;
    if (selectedOption === 1) {
      let currentData = [];
      gridInstance.current.instance.forEachNode((x) =>
        x.visible === true ? currentData.push(x.data) : ""
      );
      excelExport(gridInstance.current.instance, currentData);
    } else if (selectedOption === 2) {
      showExportDialog();
    }
  };


  const isAllowedColumn = useCallback((columnCode) => {
    const AllowedColumns = ["col_id", "col_program", "col_category"];
    return AllowedColumns.includes(columnCode);
  }, []);

  const renderColumn = useCallback((columnKey, component) => {
    if (isColVisible(columnKey) || isAllowedColumn(columnKey)) {
      const extraProps = { cssClass: "tooltip-target-" + columnKey };
      return customRenderColumn(columnKey, component, extraProps);
    }
    return "";
  }, [isColVisible, isAllowedColumn]);

  const saleAmountEditorOptions = { format: '$#,##0.##', showClearButton: true };

  const headerCellRender = (data) => {
    let text = data.column.caption;
    return <p style={{ whiteSpace: "normal" }}>{text}</p>;
  };

  const onEditorPreparing = (e) => {
    disableNumericArrows(e);
  };

  const getProgramName = (rowData, programsList) => {
    const programId = rowData["programId"];
    if (programId === null) return null;
    const program = programsList.find((x) => x.programId === programId);
    return program ? program.name : null;
  }

  const getCategoryName = (rowData, CategoryList) => {
    const programId = rowData["programId"];
    const categoryId = rowData["categoryId"];
    if (programId === null || categoryId === null) return null;
    const programCategories = CategoryList[programId];
    if (!programCategories) return null;
    const category = programCategories.find((x) => x.categoryId === categoryId);
    return category ? category.categoryName : null;
  }

  const addMenuItems = (e) => {
    if (e.row?.rowType === "data") {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
        text: 'View Editable Fields Log',
        onItemClick: () => {
          if (e.row.rowType === "data") {
            showLogDialog(e);
          }
        }
      });
      if (programId === 0 && categoryId === 0) {
        e.items.push(
          {
            text: 'Settings',
            onItemClick: () => {
              const rowCategoryId = e.row.data.categoryId;
              const rowProgramId = e.row.data.programId;
              setShowSettingsModal({ ...settings, open: true, categoryId: rowCategoryId, programId: rowProgramId })
            }
          });
      }
    }
  }

  const renderLoadingIndicator = () => {
    if (isLoading || !showGrid) {
      return (
        <div style={{ paddingTop: "5px" }}>
          <LinearProgress /> Loading...{" "}
        </div>
      );
    }
  }

  const onEditingStart = (e) => {
    if (!categoriesList[e.data.programId].find(x => x.categoryId === e.data.categoryId)) {
      e.cancel = true;
    }
  }

  const filterBySelectedRows = (e) => {
    const selectedRows = gridInstance.current.instance.getSelectedRowsData();
    const selectedNames = selectedRows.map(x => x.Name);
    gridInstance.current.instance.columnOption("Name", "filterValues", selectedNames)
  }

  if (error) return <div>Failed to load</div>;
  if (!settings.dataArray) return <div></div>
  return (
    <>
      {renderLoadingIndicator}
      <TreeList
        visible={showGrid && !isLoading}
        height={"80vh"}
        ref={gridInstance}
        dataSource={data}
        showColumnLines={gridSettings.columnLines}
        showBorders={gridSettings.borders}
        showRowLines={gridSettings.rowLines}
        rowAlternationEnabled={gridSettings.rowAlternation}
        onNodesInitialized={onNodesInitialized}
        allowColumnResizing={true}
        columnResizingMode="widget"
        keyExpr="keyItem"
        parentIdExpr="ParentID"
        id="items"
        onCellPrepared={(e) => onCellPrepared(e, settings.dataArray, categoriesList)}
        onContextMenuPreparing={addMenuItems}
        filterMode={"withAncestors"}
        autoExpandAll={expandAll}
        allowColumnReordering={true}
        style={{ margin: "1vw", maxHeight: "80vh" }}
        onEditorPreparing={onEditorPreparing}
        wordWrapEnabled={false}
        onColumnsChange={(e) => console.log(e)}
        onEditingStart={onEditingStart}

      >
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyAction={"moveFocus"}
          enterKeyDirection={"column"}
        />
        <SearchPanel visible={true} width={250} />
        <LoadPanel enabled={false} />
        <ColumnChooser
          enabled={true}
          mode={"select"}
          sortOrder={"asc"}
          height={"50vh"}
        >
          <Search enabled={true} />
        </ColumnChooser>
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <ColumnFixing enabled={true} />
        <Selection mode="multiple" selectAllMode={"allPages"} />
        <Editing mode="cell" allowUpdating={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Toolbar>
          <Item location="before">
            <NetsuiteUpdateButton origin={`grid-${groupId}`} />
          </Item>
          {programId !== 0 && categoryId !== 0 ?
            <Item location="before">
              <ToolBarButton code="Settings" location="before" icon="preferences" action={showPreferences} tooltipText="Settings for the current Program and Category" />
            </Item>
            : ""
          }
          <Item location="before">
            <ToolBarDropDownButton code="Download Data" location="before" icon="exportxlsx" action={showExport} ExportOptions={ExportOptions} />
          </Item>
          <Item location="before">
            <ToolBarButton code="Delete Data" location="before" icon="clearformat" action={cleanUpdateFields} tooltipText="Clears user entered data for the current Program and Category." />
          </Item>
          <Item location="before">
            <ToolBarButton code="templates" location="before" icon="save" action={showSaveColumnConfiguration} tooltipText="Setup, share, or load a saved view/filter/template for the data grid." validatePermission={false} />
          </Item>
          <Item location="before">
            <MohSelectBox selectedModifier={selectedModifier} onSelectedMohChanged={(e) => recalculateValues(e.value)} />
          </Item>
          <Item location="before">
            <ToolBarButton code="filterSelected" location="before" icon="filter" action={filterBySelectedRows} tooltipText="filter by selected rows" validatePermission={false} />
          </Item>
          <Item location="after">
            <ToolBarButton code="DataGridLineSettings" icon="tableproperties" action={showGridSettings} tooltipText="Grid styles" validatePermission={false} />
          </Item>
          <Item
            name="columnChooserButton"
          />
          <Item
            name="searchPanel"
          />
        </Toolbar>
        {renderColumn("col_id", <Column caption="id" allowEditing={false} dataField="Id" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} visible={false} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_name", <Column caption="Name" allowEditing={false} dataField="Name" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_program", <Column caption="Program" allowEditing={false} dataField="programId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} calculateCellValue={(rowData) => getProgramName(rowData, programsList)} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_category", <Column caption="Category" allowEditing={false} dataField="categoryId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} calculateCellValue={(rowData) => getCategoryName(rowData, categoriesList)} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_internalid", <Column icon="refresh" caption="Internal ID" allowEditing={false} dataField="InternalId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_description", <Column dataField="Description" caption="Sales Description" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_purchaseDescription", <Column dataField="purchaseDescription" caption="Purchase Description" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_exclusivity", <Column caption="Exclusivity" allowEditing={false} dataField="Exclusivity" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_purchasePrice", <Column dataField="Purchase Price" caption="Purchase Price" allowEditing={false} dataType={"number"} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_preferredVendor", <Column dataField="Preferred Vendor" allowEditing={false} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowPreferredVendorModal))} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_universalProductType", <Column dataField="universalProductType" caption="Universal Product Type" allowEditing={false} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_groupNotes", <Column dataField="Group Notes" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_inactive", <Column dataField="Inactive" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_discontinued1", <Column dataField="Discontinued 1" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_replacedBy", <Column dataField="replacedBy" caption="Replaced By" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_replaces", <Column dataField="replaces" caption="Replaces" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_qohTotal", <Column dataField="QoH Total" caption="QoH Total" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_qohCa", <Column dataField="QoHCA" caption="QoH CA" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_qohNC", <Column dataField="QoHNC" caption="QoH NC" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_availTotal", <Column dataField="Avail Total" caption="Avail Total" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_availCA", <Column dataField="AvailCA" caption="Avail CA" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_availNC", <Column dataField="AvailNC" caption="Avail NC" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poTotal", <Column dataField="PO Total" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowPurchaseOrderModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_commitTotal", <Column dataField="CommitTotal" caption="Commit Total" allowEditing={false} cellRender={(e) => LinkCell(e, showBODialog)} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_boTotal", <Column dataField="BO_total" caption="BO Total" allowEditing={false} cellRender={(e) => LinkCell(e, showBODialog)} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_mod", <Column dataField="Mod" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_availPoModBo", <Column dataField="Avail + PO + Mod - BO" caption="Avail + PO + Mod - BO" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_inbound", <Column dataField="Inbound" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowConsolidateModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_refinish", <Column dataField="Refinish" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_scrDent", <Column dataField="ScrDent" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_ljlInventory", <Column dataField="LJL Inventory" caption="Vendor Inventory" allowEditing={false} dataType={"number"} cellRender={(e) => LinkCell(e, () => showDialog(e, setShowVendorInventoryModal))} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y3", <Column dataField="Y3" caption={YearLabel(3)} allowEditing={false} alignment="center" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 6)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y2", <Column dataField="Y2" caption={YearLabel(2)} allowEditing={false} alignment="center" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 5)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y1", <Column dataField="Y1" caption={YearLabel(1)} allowEditing={false} alignment="center" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 4)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y0", <Column dataField="Y0" caption={YearLabel(0)} allowEditing={false} alignment="center" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 3)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_yTotal", <Column dataField="Y_Total" caption={"STLY History"} allowEditing={false} cellRender={renderYoyCell} alignment="center" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_yoyStability", <Column dataField="YoY_Stability" caption="YOY Stability" cellRender={renderYoyStabilityCell} allowEditing={false} alignment="center" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y2_to_y1", <Column dataField="y2ToY1" caption="Y2 To Y1" alignment="right" calculateCellValue={calculateY2ToY1} cellRender={rendery2y1} allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l12M", <Column dataField="L12M" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 2)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l3M", <Column dataField="L3M" caption="L3M" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 1)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l12MAvg", <Column dataField="L12MAvg" caption="L12M Avg" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l3MAvgM", <Column dataField="L3M Avg" caption="L3M Avg" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l3MvsL12M", <Column dataField="L3M vs L12M" caption="L3M vs L12M" allowEditing={false} cellRender={renderL3MvsL12M} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_activeKits", <Column dataField="Active Parents" caption={"Current Systems"} cellRender={(e) => LinkCell(e, showKitDialog)} allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_activePublic", <Column dataField="ActivePublic" caption="Public Systems" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_activeNoPublic", <Column dataField="ActiveNoPublic" caption="Non-Public Systems" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_uniqueCustomerCount", <Column dataField="Unique Customer Count" caption="Unique Customers (L12M)" cellRender={(e) => LinkCell(e, showUniqueCustomerDialog, 7)} allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_topBuyerShare", <Column dataField="TopBuyerShare" cellRender={(e) => renderTopBuyerShare(e, showUniqueCustomerDialog, 7)} calculateCellValue={calculateTopBuyerShare} allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_topBuyerName", <Column dataField="TopBuyerName" caption="Top Buyer Name" cellRender={(e) => LinkTooltipCell(e, showUniqueCustomerDialog, 7)} dataType={"string"} allowEditing={false} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_minOrderQuantity", <Column dataField="minOrderQuantity" caption="Min Order Quantity" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_maxOrderQuantity", <Column dataField="maxOrderQuantity" caption="Max Order Quantity" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_reorderMultiple", <Column dataField="reorderMultiple" caption="Reorder Multiple" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_lowMoh", <Column dataField="Low MoH" caption="Low Est Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_midMoh", <Column dataField="Mid MoH" caption="Mid Est Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_targetMoh", <Column dataField="Target MoH" caption="Target MOH Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_suggestedPoQty", <Column dataField="Suggested PO Qty" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costSuggested", <Column dataField="CostSuggested" caption="Suggested PO Cost" allowEditing={false} dataType={"number"} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyReviewer", <Column dataField="poQtyReviewer" dataType="number" caption="PO Qty Reviewer" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyReviewer")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poQtyReviewer", "poQtyReviewer", "I", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesReviewer", <Column dataField="poNotesReviewer" dataType={"string"} caption="PO Notes Reviewer" allowEditing={isColEditable("col_poNotesReviewer")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesReviewer", "poNotesReviewer", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costReviewer", <Column dataField="CostReviewer" caption="Reviewer PO Cost" allowEditing={false} calculateCellValue={calculatePoReviewerCost} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyMarket", <Column dataField="poQtyMarket" dataType="number" caption="PO Qty Market" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyMarket")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poQtyMarket", "poQtyMarket", "I", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesMarket", <Column dataField="poNotesMarket" dataType={"string"} caption="PO Notes Market" allowEditing={isColEditable("col_poNotesMarket")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesMarket", "poNotesMarket", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costMarket", <Column dataField="CostMarket" dataType="number" caption="Market PO Cost" allowEditing={false} calculateCellValue={calculatePoCostMarket} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyApprover", <Column dataField="poQtyApprover" dataType="number" caption="PO Qty Approver" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyApprover")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poQtyApprover", "poQtyApprover", "I", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesApprover", <Column dataField="poNotesApprover" dataType={"string"} caption="PO Notes Approver" allowEditing={isColEditable("col_poNotesApprover")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesApprover", "poNotesApprover", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costApprover", <Column dataField="CostApprover" dataType="number" caption="Approver PO Cost" allowEditing={false} calculateCellValue={calculatePoCostApprover} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_additionalMohL3ML12MLoad", <Column dataField="additionalMohL3ML12MLoad" caption="Additional MoH Load (L12M | L3M)" allowEditing={false} calculateCellValue={(rowData) => CalculateAdditionalMoHLoad(rowData, selectedModifier)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_remainingEstMoh12M", <Column dataType="number" dataField="Remaining Est MoH L12M" caption="Remaining Est MoH L12M" allowEditing={false} calculateCellValue={(rowData) => calculateRemainingEstMoh(rowData, 12)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_projectedMohAfterPoL12M", <Column dataType="number" dataField="Projected MoH after PO L12M" caption="Projected MoH after PO L12M" allowEditing={false} cellRender={(data) => renderProjectedMoHAfterL12M(data, settings.dataArray)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_remainingEstMoh3M", <Column dataType="number" dataField="Remaining Est MoH L3M" caption="Remaining Est MoH L3M" allowEditing={false} calculateCellValue={(rowData) => calculateRemainingEstMoh(rowData, 3)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_projectedMohAfterPoL3M", <Column dataType="number" dataField="Projected MoH after PO L3M" caption="Projected MoH after PO L3M" allowEditing={false} cellRender={(data) => renderProjectedMoHAfterL3M(data, settings.dataArray)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_revisedPoQty", <Column dataField="Revised PO Qty" caption="Revised PO Qty" allowEditing={false} calculateCellValue={(rowData) => calculateRevisedPoQty(rowData, selectedModifier)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_updatedDate", <Column caption="Updated Date" allowEditing={false} dataField="UpdatedDate" format={"yyyy-MM-dd hh:mm:ss"} dataType={"date"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_createdDate", <Column dataField="CreatedDate" caption="Creation Date" allowEditing={false} format={"yyyy-MM-dd hh:mm:ss"} dataType={"date"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_itemAge", <Column caption="Age (Months)" allowEditing={false} dataField="Age" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_exclusions", <Column caption="Exclusions" dataField="Exclusions" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
      </TreeList>
      <GridTooltips columns={columns} />
      {renderLogModal()}
      {renderDialog(showKitModal, <KitsModal open={showKitModal} internalId={selectedInternalId} name={selectedPartName} onClose={() => setShowKitModal(false)} />)}
      {renderDialog(showUniqueCustomerModal, <UniqueCustomerModal open={showUniqueCustomerModal} internalId={state.data.uniqueCustomer.internalId} name={state.data.uniqueCustomer.name} onClose={() => setShowUniqueCustomerModal(false)} Period={state.data.uniqueCustomer.Period} />)}
      {renderDialog(showSettingsModal.open, <SettingsModal open={showSettingsModal.open} onClose={() => setShowSettingsModal(false)} onSavedData={handleSaveSettings} categoryId={showSettingsModal.categoryId} programId={showSettingsModal.programId} />)}
      {renderDialog(showPreferredVendorModal, <VendorsModal open={showPreferredVendorModal} onClose={() => setShowPreferredVendorModal(false)} internalId={selectedInternalId} name={selectedPartName} />)}
      {renderDialog(showInventoryModal, <InventoryModal onClose={() => setShowInventoryModal(false)} internalId={selectedInternalId} name={selectedPartName} categoryId={categoryId} programId={programId} />)}
      {renderDialog(showBODModal, <BackModal open={showBODModal} onClose={() => setShowBODModal(false)} internalId={state.data.back.internalId} name={state.data.back.name} />)}
      {renderDialog(showExportModal, <ExportModal open={showExportModal} onClose={() => setShowExportModal(false)} />)}
      {renderDialog(showConsolidateModal, <ConsolidateModal open={showConsolidateModal} onClose={() => setShowConsolidateModal(false)} internalId={selectedInternalId} name={selectedPartName} />)}
      {renderDialog(showVendorInventoryModal, <VendorModal open={showVendorInventoryModal} onClose={() => setShowVendorInventoryModal(false)} internalId={selectedInternalId} name={selectedPartName} />)}
      {renderDialog(showPurchaseOrderModal, <PurchaseOrderModal open={showPurchaseOrderModal} onClose={() => setShowPurchaseOrderModal(false)} internalId={selectedInternalId} name={selectedPartName} />)}
      {renderDialog(showSaveColumnConfigurationComponent, <SaveGridTemplateDialog open={showSaveColumnConfigurationComponent} onTemplateChange={handleTemplateChange} onClose={() => setShowSaveColumnConfigurationComponent(false)} userId={user.id} programId={programId} categoryId={categoryId} storageKey={storageKey} />)}
      {renderDialog(showGridSettingsModal.open, <GridSettingsModal onClose={() => setShowGridSettingsModal(false)} onSavedData={handleSaveGridSettings} {...gridSettings} />)}
    </>
  );
};

export default MainGrid;

