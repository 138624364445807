import axios from "axios";
import { GetSilentTokent } from "../components/authentication/AuthenticationHelpers";

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.accessToken;
}

const netsuiteClient = axios.create({
    baseURL: process.env.REACT_APP_NETSUITE_API,
    timeout: 500000,
  });

  netsuiteClient.interceptors.request.use(
    async (config) => {
      const token = await GetSilentTokent();
      config.headers["Authorization"] = 'Bearer ' + token.accessToken ;
      return config;
    },
    (error) => {
  
      console.log("Error in request interceptor: ", error);
      return Promise.reject(error); 
    }
  );
  

  export default netsuiteClient;
  