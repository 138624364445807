import { FETCH_PERIODS_LOADING, FETCH_PERIODS } from "../actions/types";


const infoPeriodsReducer =  (state = {'InfoPeriods':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_PERIODS_LOADING:
            return {...state, 'loading': true, 'InfoPeriods':null };
        case FETCH_PERIODS:
            return { ...state,  [action.internalId]: action.payload ,'loading': false}
        default:
            return state;
    }
};

export default infoPeriodsReducer;