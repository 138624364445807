import { useState } from "react";
import { Button } from "devextreme-react";
import { postValidateFilter, postMasterGridFilter, postMasterGridColumnFilter } from "../../actions/gridFilter/gridFilterActions";

import { Stepper } from 'react-form-stepper';

import { FilterTypeSelection } from "./FilterTypeSelection";
import { FilterValidationGrid } from "./FilterValidationGrid";
import { CopyPasteGrid } from "./CopyPasteGrid";
import { ColumnsFilter } from "./ColumnsFilter";
import { ColumnsFilterReview } from "./ColumnsFilterReview"

import './WizardStepper.css';


export const WizardStepper = (props) => {
    const { onApplyFilter } = props;
    const [data, setData] = useState([])
    const [dataValidated, setDataValidated] = useState(null)
    const [step, setStep] = useState(0)
    const [typeOfSearchId, setTypeOfSearchId] = useState(1)
    const [shouldShowError, setShouldShowError] = useState(true);
    const [filterName, setFilterName] = useState(null);
    const [filterRows, setFilterRows] = useState([{
        id: 0,
        column: null,
        columnType: null,
        operator: null,
        operatorName: null,
        filterValue: null,
        filterValue2: null,
        logicalOperator: null,
    }]);

    let typeOfSearchColumnName = null;
    let typeOfSearchColumn = null;

    if (typeOfSearchId === 1) {
        typeOfSearchColumnName = 'Part Name';
        typeOfSearchColumn = 'partName';
    }
    else if (typeOfSearchId === 2) {
        typeOfSearchColumnName = 'Internal Id';
        typeOfSearchColumn = 'internalId';
    }
    else {
        typeOfSearchColumnName = 'Columns Filter';
        typeOfSearchColumn = 'columnsFilter';
    };


    const formatValidatedData = (data) => {
        const result = data.map((row) => {
            const cells = row;
            if (typeOfSearchColumn === 'partName') {
                return {
                    partName: cells.name,
                    exist: cells.exist === 1 ? 'Yes' : 'No'
                };
            }
            else {
                return {
                    internalId: cells.name,
                    exist: cells.exist === 1 ? 'Yes' : 'No'
                };
            }

        });
        return result;

    }

    const saveMasterGridFilter = async () => {
        const response = typeOfSearchId === 3 ? postMasterGridColumnFilter(filterRows) : postMasterGridFilter(dataValidated, typeOfSearchId);
        const filterUrl = typeOfSearchId === 3 ? '/api/Items/itemColumnsMassiveFilter' : '/api/Items/itemMassiveFilter';
        setFilterName(response[0]?.filterName);
        onApplyFilter(response, filterUrl);
    }

    const reportValidateMasterGridFilter = async (data) => {
        const response = await postValidateFilter(data, typeOfSearchId);
        const formatData = formatValidatedData(response);
        setDataValidated(formatData);
    }

    const dataHasErrors = dataValidated?.some((item) => item.exist === 'No');

    const onStepContinue = (e) => {
        if (step === 1) {
            reportValidateMasterGridFilter(data);
            setStep(step + 1);
        }
        if (step === 2) {
            reportValidateMasterGridFilter(dataValidated);
            setShouldShowError(true);
        }
        else {
            setStep(step + 1);
        }
    };

    const onStepFinalize = (e) => {
        saveMasterGridFilter();
    };

    const onStepBack = e => {
        setStep(step - 1);
    };

    const onClearData = e => {
        setData([]);
    };


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div>
                        <FilterTypeSelection typeOfSearchId={typeOfSearchId} setTypeOfSearchId={setTypeOfSearchId} />
                    </div>
                );
            case 1:
                if (typeOfSearchId === 3) {
                    return (
                        <div>
                            <ColumnsFilter typeOfSearchId={typeOfSearchId} setTypeOfSearchId={setTypeOfSearchId} filterRows={filterRows} setFilterRows={setFilterRows} />
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            <CopyPasteGrid data={data} setData={setData} typeOfSearchColumnName={typeOfSearchColumnName} typeOfSearchColumn={typeOfSearchColumn} shouldShowError={shouldShowError} />
                        </div>
                    );
                };
            case 2:
                if (typeOfSearchId === 3) {
                    return (
                        <div>
                            <ColumnsFilterReview filterRows={filterRows} filterName={filterName} setFilterName={setFilterName} />
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            <FilterValidationGrid dataValidated={dataValidated} setDataValidated={setDataValidated} typeOfSearchColumnName={typeOfSearchColumnName} typeOfSearchColumn={typeOfSearchColumn} typeOfSearchId={typeOfSearchId} filterName={filterName} setFilterName={setFilterName} shouldShowError={shouldShowError} setShouldShowError={setShouldShowError} />
                        </div>
                    );
                }

            default:
                return 0;
        }
    }

    return (
        <>
            <Stepper
                steps={[{ label: 'Select Type Of Search' }, { label: 'Select Values' }, { label: 'Final Results' }]}
                activeStep={step}
                styleConfig={{
                    activeBgColor: '#2b7cff',
                    activeTextColor: '#fff',
                    inactiveBgColor: '#fff',
                    inactiveTextColor: '#2b7cff',
                    completedBgColor: '#C5C5C5',
                    completedTextColor: '#2b7cff',
                    size: '2em'
                }}
                className={'stepper'}
                stepClassName={'stepper__step'}
            />
            {renderStepContent(step)}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {step !== 0 &&
                    (
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                            <Button
                                text="Back"
                                onClick={() => onStepBack()}
                            />
                        </div>
                    )}
                {step === 1 && typeOfSearchId !== 3 &&
                    (
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                            <Button
                                text={'Clear Data'}
                                onClick={() => onClearData()}
                            />
                        </div>
                    )}
                {step < 3 && typeOfSearchId !== 3 &&
                    (
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                            <Button
                                text={step === 2 ? 'Revalidate Filter' : 'Next'}
                                onClick={() => onStepContinue()}
                            />
                        </div>
                    )}
                {step < 2 && typeOfSearchId === 3 &&
                    (
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                            <Button
                                text={'Next'}
                                onClick={() => onStepContinue()}
                            />
                        </div>
                    )}
                {step === 2 &&
                    (
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                            <Button
                                text={'Apply Filter'}
                                onClick={() => onStepFinalize()}
                                disabled={filterName === null || dataHasErrors}
                            />
                        </div>
                    )}
            </div>

        </>
    );
};
