export const textOptions = [
  {
    id: 1,
    name: "a͟b͟c͟ Contains",
  },
  {
    id: 2,
    name: "a̶b̶c̶ Does not contain",
  },
  {
    id: 3,
    name: "a̶bc Starts with",
  },
  {
    id: 4,
    name: "abc̶ Ends with",
  },
  {
    id: 5,
    name: "= Equals",
  },
  {
    id: 6,
    name: "≠ Does not equal",
  },
  {
    id: 7,
    name: "○ is Blank",
  },
  {
    id: 8,
    name: "● is Not Blank",
  },
];

export const numericOptions = [
  {
    id: 1,
    name: "= Equals",
  },
  {
    id: 2,
    name: "≠ Does not equal",
  },
  {
    id: 3,
    name: "< is less than",
  },
  {
    id: 4,
    name: "> is greater than",
  },
  {
    id: 5,
    name: "< is less than or equal to",
  },
  {
    id: 6,
    name: "> is greater than or equal to",
  },
  {
    id: 7,
    name: "○ is Blank",
  },
  {
    id: 8,
    name: "● is Not Blank",
  },
  {
    id: 9,
    name: "- is between",
  },
];

export const numericOptionsToPost = [
  {
    id: 1,
    name: "equals",
  },
  {
    id: 2,
    name: "doesNotEqual",
  },
  {
    id: 3,
    name: "isLessThan",
  },
  {
    id: 4,
    name: "isGreaterThan",
  },
  {
    id: 5,
    name: "isLessThanOrEqual",
  },
  {
    id: 6,
    name: "isGreaterThanOrEqual",
  },
  {
    id: 7,
    name: "isBlank",
  },
  {
    id: 8,
    name: "isNotBlank",
  },
  {
    id: 9,
    name: "isBetween",
  },
];

export const columnsValueOptions = [
  {
    id: 9,
    name: "☑ is any of",
  },
  {
    id: 10,
    name: "▭ is none of",
  },
];

export const logicalOperatorOptions = [
  {
    id: 1,
    text: "Or",
  },
  {
    id: 2,
    text: "And",
  },
];
