import useSWR from 'swr'
import { fetcher } from '../../../../actions';
import TreeList, { Column, Selection } from 'devextreme-react/tree-list';
import { Scrolling } from 'devextreme-react/data-grid';
import moment from 'moment';
import { useCallback } from 'react';
import { LinearProgress } from '@mui/material';

export const RelatedComponentsDetails = (props) => {
    const { memberInternalID, replacedby, replaces, baseitem, parentName, updateGridDimension } = props;
    const { data, error, isLoading } = useSWR(`/api/Report/reportHierarchyRelated?Replacedby=${replacedby}&Replaces=${replaces}&BaseItem=${baseitem}&InternalId=${memberInternalID}&name=${parentName}`, fetcher)

    const handleCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === "QtyRemaining") {
                var available = e.data['QtyAvailable'] == null ? 0 : e.data['QtyAvailable'];
                var members = e.data['QtyMembers'] == null ? 0 : e.data['QtyMembers'];
                if (available < members) {
                    e.cellElement.style.backgroundColor = "#FF8484";
                }
            }
            if (e.column.dataField === "namepart") {
                if (e.data['parentItem'] === 1) {
                    e.cellElement.style.fontWeight = "bold";
                }
            }
        }

        if (e.rowType === "data") {
            if (e.column.type === "detailExpand") {
                if (e.data.ID === "0") {
                    e.cellElement.style.visibility = "hidden";
                }
            }
        }
    }


    const formatDate = useCallback((e) => {
        if (e.value != null) {
            return moment(e.value).format('yyyy-MM-DD');
        }
    }, []);


    if (error) return <div>Failed to load</div>
    if (isLoading) return (<div style={{ paddingTop: "5px" }}><LinearProgress /> Loading... </div>)
    return (
        <div>

            <TreeList
                dataSource={data}
                showBorders={true}
                columnResizingMode={'widget'}
                width={'100%'}
                columnAutoWidth={true}
                filterMode={'withAncestors'}
                onCellPrepared={handleCellPrepared}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                keyExpr="internalID"
                parentIdExpr="parentID"
                autoExpandAll={true}
                onContentReady={updateGridDimension}
            >
                <Scrolling mode="standard" />
                <Selection mode="multiple" />
                <Column dataField="namepart" caption="Member Item"  width={"290px"} />
                <Column dataField="12MSales" caption="L12M Qty Sold" width={"60px"} />
                <Column dataField="BO" caption="BO" width={"60px"} />
                <Column dataField="QtyMembers" caption="Qty Members Required" width={"60px"} />
                <Column dataField="Inbound" caption="Inbound" width={"60px"} />
                <Column dataField="LJLInventory" caption="Vendor Inventory" width={"60px"} />
                <Column dataField="QtyAvailable" caption="Qty Available in NS" width={"60px"} />
                <Column dataField="QtyRemaining" caption="Qty Remaining in NS" width={"60px"} />
                <Column dataField="PO" caption="Qty Member PO" width={"60px"} />
                <Column dataField="lastDateItem" dataType={"date"} format={"yyyy-MM-dd"} caption="Last Date Received" customizeText={formatDate} width={"10vw"} />
                <Column dataField="PreferVendor" caption="Preferred Vendor " width={"10vw"} />
                <Column dataField="specialSystem" caption="Exclusivity" width={"10vw"} />
            </TreeList>

        </div>
    );
}

export default RelatedComponentsDetails;
