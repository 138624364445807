import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { GridTabs } from "../grid/gridTabs";
import "./mainBox.css";
import RolesMainBox from "../Roles/rolesMainBox";
import FilesMainBox from "../Files/filesMainBox";
import { GlobalGrid } from "../../GlobalGrid";
import MainSelector from "../grid/mainSelector";
import { onChangeSelectedCategory } from "../../actions";
import { changedMenu } from "../../actions/menu";
import { MENU_MAIN_SELECTOR, MENU_FILES, MENU_ROLES, MENU_GLOBAL_GRID, MENU_GRID_TABS, MENU_GRID_RENDER, MENU_EXCLUSIONS } from "../../actions/menuTypes";
import { GlobalGridRenderer } from "../../components/globalGrid/globalGridRenderer";
import { Exclusions } from "../../components/Exclusions/Exclusions";

export const MainBox = () => {
  const dispatch = useDispatch();
  const menuId = useSelector((state) => state.menu.selectedMenu);
  const [filter, setFilter] = useState(null);

  const onMainSelectorChange = (e) => {
    dispatch(changedMenu(MENU_MAIN_SELECTOR));
    dispatch(onChangeSelectedCategory(e));
  }

  const onApplyFilter = (filter) => {
    setFilter(filter);
  }

  switch (menuId) {
    case MENU_MAIN_SELECTOR:
      return <MainSelector onChange={onMainSelectorChange} onApplyFilter={onApplyFilter} />;
    case MENU_FILES:
      return <FilesMainBox />;
    case MENU_ROLES:
      return <RolesMainBox />;
    case MENU_GLOBAL_GRID:
      return <GlobalGrid />;
    case MENU_GRID_TABS:
      return <GridTabs />;
    case MENU_GRID_RENDER:
      return <GlobalGridRenderer url={'/api/Items/itemColumnsMassiveFilter'} data={filter} />;
    case MENU_EXCLUSIONS:
      return <Exclusions />;
    default:
      return null;
  }


};
