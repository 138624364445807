import { Card, CardContent, Typography } from "@mui/material";
import { TabPanel } from "devextreme-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminUserRoleBody from "./adminUserRoleBody";
import Button from "devextreme-react/button";
import { Tooltip } from 'devextreme-react/tooltip';
import { postUsers, fetchAdminInformation } from "../../actions";
import notify from 'devextreme/ui/notify';

const itemTitleRender = (role) => {
    return <span>{role.name}</span>;
}

const AdminUserRoles = () => {
    const dispatch = useDispatch();
    const roles = useSelector(state => state.admin.roles)
    const [selectedTab, setSelectedTab] = useState(0);
    const onSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            setSelectedTab(args.value);
        }
    }

    const refreshDataUsers = () => {
        return dispatch(postUsers()).then(
            response => dispatch(fetchAdminInformation()), notify(`The users has been updated correctly.`),
            error => notify(`The users has been updated incorrectly.`)            
          );
      };

    if (!roles) {
        return <></>
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    Users <Button icon="refresh" onClick={refreshDataUsers} id="refreshUsers" key="refUsers" />
                    <Tooltip
                        target="#refreshUsers"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                    >
                        <div>Update information in the Active Directory to include, modify or delete users.</div>
                    </Tooltip>
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Assign users to the different system roles
                </Typography>
                <hr></hr>
                <div>
                    <TabPanel
                        dataSource={roles}
                        selectedIndex={selectedTab}
                        onOptionChanged={onSelectionChanged}
                        itemTitleRender={itemTitleRender}
                        itemComponent={AdminUserRoleBody}
                        animationEnabled={true}
                    />
                </div>
            </CardContent>
        </Card>
    );
}

export default AdminUserRoles



