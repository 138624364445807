import apiClient from "../../apis/items";


export const getGridTemplatesByUserProgramCategory = async (idUser, idProgram, idCategory) => {
    const response = await apiClient.get(`/api/GridTemplates/${idUser}/${idProgram}/${idCategory}`);
    return response.data;
}


export const getActiveGridTemplatesByUserProgramCategory = async (idUser, idProgram, idCategory) => {
    try {
        const response = await apiClient.get(`/api/GridTemplates/${idUser}/${idProgram}/${idCategory}`);
        const result = response.data.find(x => x.selected===true);
        return result || null;
    } catch (error) {
        console.error('Error getting active grid templates:', error);
        throw error;
    }
}



export const getGridTemplatesByUser = async (userId) => {
    const response = await apiClient.get(`/api/GridTemplates/user/${userId}/true`);
    return response.data;
}

const patchGridTemplate = async (id, data) => {
    const currentTemplate = await apiClient.get(`/api/GridTemplates/${id}`);
    if (currentTemplate.status === 200) {
        await apiClient.patch(`/api/GridTemplates/${id}`, data);
    }
}

export const setSelectedTemplate = async (id, selected) => {
    const data = [
        {
            "path": "/selected",
            "op": "replace",
            "value": selected ? 1: 0
        }
    ]
    await patchGridTemplate(id, data);
}

export const updateExistingTemplate = async (id, template) => {
    const data = [
        {
            "path": "/template",
            "op": "replace",
            "value": template
        },
        {
            "path": "/selected",
            "op": "replace",
            "value": 1
        }
    ]
    await patchGridTemplate(id, data);
}

export const postGridTemplate = async (templateName, idUser, idProgram, idCategory, template) => {
    const data = { "templateName": templateName, "userId": idUser, "programId": idProgram, "categoryId": idCategory, "template": template, "selected": true };
    var response = await apiClient.post(`/api/GridTemplates`, data)
    return response.data;
}

export const updateTemplateName = async (id, templateName) => {
    const data = [
        {
            "path": "/templateName",
            "op": "replace",
            "value": templateName
        }
    ]
    await patchGridTemplate(id, data);
}


export const deleteGridTemplate = async (id) => {
    const currentTemplate = await apiClient.get(`/api/GridTemplates/${id}`);
    if (currentTemplate.status === 200) {
        await apiClient.delete(`/api/GridTemplates/${id}`);
    }
}
