import { Button, LoadIndicator, Tooltip } from "devextreme-react";
import { fetchNetsuiteIntegration, fetchNetsuiteLastUpdate, fetcher, postNotification } from "../../actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSwr from "swr";


export const NetsuiteUpdateButton = (props) => {
    const { origin } = props;
    const [isSyncing, setIsSyncing] = useState(false);
    const { data, isLoading, mutate } = useSwr('/api/serviceStatus/NETSUITE', fetcher, { refreshInterval: 10000 })

    const dispatch = useDispatch();
    const permissions = useSelector(state => state.userInfo.feature);
    const btnId = `btnNetsuite-${origin}`;



    useEffect(() => {
        if (data) {
            setIsSyncing(data.status !== "IDLE");
        }
    }, [data]);


   
    const updateFromNetsuite = () => {
        const updateFromNetsuiteAsync = async () => {
            const notification = {
                type: "NETSUITE",
                status: "STARTED",
                message: "Netsuite Sync Started.",
            };
            await postNotification(notification);
            dispatch(fetchNetsuiteLastUpdate());
            dispatch(fetchNetsuiteIntegration());
            mutate();
        }
        updateFromNetsuiteAsync();
        setIsSyncing(true);
    }

    if (!permissions) {
        return "";
    }

    const SyncPermissions = permissions.find(x => x.NameFeature === "Manual Update");
    if (!SyncPermissions) {
        return "";
    }


    if (isLoading){
        return (
        <div>

            <Button width={180} height={40} icon="sorted" text="Netsuite Update" id={btnId} >
                <LoadIndicator className="button-indicator" visible={true} />
                <span className="dx-button-text">Loading Netsuite Service Status</span>
            </Button>
            <Tooltip
                target={`#${btnId}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <div>Retrieve the latest data changes from Netsuite.</div>
            </Tooltip>
        </div>
    );
    }

    const buttonText = isSyncing ? "Updating..." : "Netsuite Update"; 
    return (
        <div>

            <Button width={180} height={40} icon="sorted" text="Netsuite Update" onClick={updateFromNetsuite} id={btnId} >
                <LoadIndicator className="button-indicator" visible={isSyncing} />
                <span className="dx-button-text">{buttonText}</span>
            </Button>
            <Tooltip
                target={`#${btnId}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <div>Retrieve the latest data changes from Netsuite.</div>
            </Tooltip>
        </div>
    );

}
