import { DataGrid } from "devextreme-react";
import { Column, FilterPanel, FilterRow, HeaderFilter, Summary, TotalItem } from "devextreme-react/cjs/data-grid";

export const ExcludedItemsGrid = (props) => {
    const { dataSource } = props;
    return (
        <>
            <div id="ExcludedItemsGrid" style={{height: "35vh"}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    width={"100%"}
                    height={"30vh"}
                    style={{minHeight: "220px"}}
                >
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <Column dataField="yyyY_MM" caption="Date" />
                    <Column dataField="documentNumber" caption="Excluded Transaction" />
                    <Column dataField="customerName" caption="Customer Name" />
                    <Column dataField="originalQTY" caption="Original Quantity" />
                    <Column dataField="excludedQTY" caption="Excluded Quantity" />
                    <Column dataField="typeName" caption="Exclusion Type" />
                    <Column dataField="exclusionNote" caption="Exclusion Notes" />
                    <Summary>
                        <TotalItem
                            column="excludedQTY"
                            name="Total Excluded Quantity"
                            summaryType="sum"
                            displayFormat="Sum: {0}"
                            showInColumn="excludedQTY" />
                    </Summary>
                </DataGrid>
            </div>
        </>
    );
}