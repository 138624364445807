import { graphConfig } from "./AuthConfig"

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken, type = null) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    switch (type) {
        case "photo":
            return fetch(graphConfig.graphProfilePhotoEndpoint, options)
                .then(response => response)
                .catch(error => console.log(error));
        default:
            return fetch(graphConfig.graphMeEndpoint, options)
                .then(response => response.json())
                .catch(error => console.log(error));
    }


}