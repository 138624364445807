import { DataGrid, Column, Scrolling } from "devextreme-react/data-grid";
import { LinearProgress } from "@mui/material";
import { fetcher } from "../../../../actions";
import useSWR from "swr";

const ConsolidateGrid = (props) => {
  const { internalId } = props;

  const {
    data: dataSource,
    error,
    isLoading,
  } = useSWR(
    `/api/Report/reportConsolidateShipments?InternalID=${internalId}`,
    fetcher
  );

  if (error) return <div>Failed to load</div>;
  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }

  return (
    <>
      <div id="ConsolidateGrid">
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnMinWidth={10}
          columnAutoWidth={true}
          height={"20vh"}
          width={"100%"}
          columnResizingMode={"widget"}
          wordWrapEnabled={true}
        >
          <Scrolling mode="virtual" />
          <Column dataField="shipping" caption="Inbound" alignment="left" />
          <Column dataField="notes" caption="ETA Summary" alignment="left" />
        </DataGrid>
      </div>
    </>
  );
};

export default ConsolidateGrid;
