export const getModifierValueId = (modifierValueCode) => {
    let modifierValue = 1;
    if (modifierValueCode === "col_poQtyReviewer") {
        modifierValue = 2;
    }
    if (modifierValueCode === "col_poQtyMarket") {
        modifierValue = 3;
    }
    if (modifierValueCode === "col_poQtyApprover") {
        modifierValue = 4;
    }
    return modifierValue;
}


export const getModifierValueCode = (modifierValueId) => {
    let modifierValue = "col_suggestedPoQty";
    if (modifierValueId === 2) {
        modifierValue = "col_poQtyReviewer";
    }
    if (modifierValueId === 3) {
        modifierValue = "col_poQtyMarket";
    }
    if (modifierValueId === 4) {
        modifierValue = "col_poQtyApprover";
    }
    return modifierValue;
}

