import { FETCH_RELATEDCOMPONENTS, FETCH_RELATEDCOMPONENTS_LOADING } from "../actions/types"; 


const relatedComponentsReducer =  (state = {'RelatedComponents':null, 'loading': false }, action) => {
    switch (action.type) {
        case FETCH_RELATEDCOMPONENTS_LOADING:
            return {...state, 'loading': true ,'RelatedComponents': null};
        case FETCH_RELATEDCOMPONENTS:
            return { ...state, 'RelatedComponents': action.payload ,'loading': false}
        default:
            return state;
    }
};

export default relatedComponentsReducer;