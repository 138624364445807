import { DataGrid } from "devextreme-react";
import { Column, Editing, Scrolling } from "devextreme-react/data-grid";
import "./saveGridTemplate.css";

export const GridTemplatesGrid = (props) => {
    const { templates, deleteTemplate, updateGridTemplateName, renderButtons } = props;


    const renderTemplateName = (row) => {
        return (
            <>
                {row.data.selected === true ? <div style={{ display: "inline-block", fontWeight: "bold" }} >{row.data.templateName}</div> : <div style={{ display: "inline-block" }} >{row.data.templateName}</div>}
            </>
        );
    }

    const renderOwnerName = (row) => {
        return (
            <>
                {row.data.shared === true ? <div style={{ display: "inline-block", fontWeight: "bold" }} >{row.data.ownerName}</div> : <div style={{ display: "inline-block" }} ></div>}
            </>
        );
    }

    const setEditableCells = (e) => {
        const readonlyFields = ["templateName", "Owner"];
        if (readonlyFields.indexOf(e.dataField) !== -1 && e.row.data.shared === true) {
            e.editorOptions.readOnly = true;
        }
    }

    return (
        <div style={{ paddingTop: "5px" }}>
            <DataGrid
                id="templatesGrid"
                dataSource={templates}
                height={"40vh"}
                showBorders={true}
                onRowRemoved={deleteTemplate}
                onRowUpdated={updateGridTemplateName}
                rowAlternationEnabled={true}
                onEditorPreparing={setEditableCells}
            >
                <Scrolling mode="virtual" />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                ></Editing>
                <Column dataField="templateName" caption="Template Name" visible={true} cellRender={renderTemplateName} />
                <Column dataField="OwnerName " caption="Owner" allowEditing={false} visible={true} cellRender={renderOwnerName} />
                <Column key="actions" type="buttons" caption="Actions" allowEditing={false} minWidth={50} cellRender={renderButtons} />
            </DataGrid>
        </div>
    );

}

export default GridTemplatesGrid