
/**
 * Returns a formatted label for a given year.
 * @param {number} year - The year to generate the label for.
 * @returns {string} The formatted label for the year.
 */
export const YearLabel = (year) => {
    //Current date utc
    let yearl = new Date().getFullYear() - year;
    return "(Y" + year + ") " + yearl;
};
