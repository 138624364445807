import { fetcherNetsuite } from "../../actions";
import useSWR from "swr";

export const NetsuiteLastUpdateLabel = (props) => {

    const {data, error, isLoading, mutate} = useSWR(`/api/Netsuite/lastUpdateInfo?type=0`, fetcherNetsuite); 

    if (isLoading){
        return <div>Loading Netsuite Last Update...</div>
    }

    const renderLastUpdate = (lastUpdate) => {
        if (!lastUpdate) {
            return '';
        }
    
        const datetime = new Date(lastUpdate);
        if (isNaN(datetime.getTime())) {
            return '';
        }
    
        const pad = (num) => String(num).padStart(2, '0');
    
        const year = datetime.getFullYear();
        const month = pad(datetime.getMonth() + 1);
        const day = pad(datetime.getDate());
        const hour = pad(datetime.getHours());
        const minute = pad(datetime.getMinutes());
        const second = pad(datetime.getSeconds());
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };
    if (error){
        return <div>Error loading Netsuite Last Update</div>
    }

    if (data){
        const lastUpdate = data[0].UpdatedDate;
        return (
            <div>
                <span style={{ fontSize: "1em" }}>{renderLastUpdate(lastUpdate)}</span>
            </div>
        );
    }
    else {
        return <div></div>
    }


}