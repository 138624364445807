import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import RadioGroup from 'devextreme-react/radio-group';
import { fetcher } from "../../actions";
import useSWR from "swr";
import { Delete } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import { textOptions, numericOptions, columnsValueOptions, logicalOperatorOptions } from "./ColumnOptions"

export const ColumnsFilter = (props) => {

    const { filterRows, setFilterRows } = props;

    const { data: dataSource } = useSWR(`/api/RoleColumns/reportColumns`, fetcher);

    const preferredVendorRow = filterRows?.filter((row) => row.id === 5);
    const universalProductTypeRow = filterRows?.filter((row) => row.id === 6);
    const programTypeRow = filterRows?.filter((row) => row.id === 91);
    const categoryTypeRow = filterRows?.filter((row) => row.id === 92);
    const shouldFetchPreferredVendor = preferredVendorRow[0]?.operator === 9 || preferredVendorRow[0]?.operator === 10;
    const shouldFetchUniversalProductType = universalProductTypeRow[0]?.operator === 9 || universalProductTypeRow[0]?.operator === 10;
    const shouldFetchPrograms = programTypeRow[0]?.operator === 9 || programTypeRow[0]?.operator === 10;
    const shouldFetchCategories = categoryTypeRow[0]?.operator === 9 || categoryTypeRow[0]?.operator === 10;

    const { data: preferredVendorValues } = useSWR(shouldFetchPreferredVendor ? "/api/ValidateItems/GetColumnsItems?columnName=PreferredVendor" : null, fetcher);
    const { data: UniversalProductTypeValues } = useSWR(shouldFetchUniversalProductType ? "/api/ValidateItems/GetColumnsItems?columnName=universalProductType" : null, fetcher);
    const { data: ProgramValues } = useSWR(shouldFetchPrograms ? "/api/Programs" : null, fetcher);
    const { data: CategoryValues } = useSWR(shouldFetchCategories ? "/api/Categories" : null, fetcher);

    const preferredVendorOptions = preferredVendorValues?.map((x, index) => {
        return {
            id: index,
            name: x.list,
        }
    });

    const universalProductTypeOptions = UniversalProductTypeValues?.map((x, index) => {
        return {
            id: index,
            name: x.list,
        }
    });

    const columnOptions = dataSource?.filter(column => column.NameReport !== null && column.GridId === 1)?.map(x => {
        return {
            id: x.Id,
            name: x.NameColumn,
            columnType: x.ColumnType,
        }
    });

    const programOptions = ProgramValues?.map((x) => {
        return {
            id: x.programId,
            name: x.name,
        }
    });

    const categoryOptions = CategoryValues?.map((x) => {
        return {
            id: x.categoryId,
            name: x.categoryName,
        }
    });

    const handleColumnChange = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].id = e.value;
            newRows[index].column = columnOptions.find(
                a => a.id === e.value
            )?.name;
            newRows[index].columnType = columnOptions.find(
                a => a.id === e.value
            )?.columnType
            setFilterRows(newRows);
        }
    }

    const handleOperatorChange = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].operator = e.value;

            if (newRows[index].columnType === 'numeric') {
                const fullValue = numericOptions.find(
                    a => a.id === e.value
                )?.name
                newRows[index].operatorName = fullValue?.slice(fullValue?.indexOf(" ") + 1);
            }
            else {
                let options = textOptions;
                if (newRows[index].id === 5 || newRows[index].id === 6 || newRows[index].id === 91 || newRows[index].id === 92) {
                    options = [...textOptions, ...columnsValueOptions]
                }
                const fullValue = options.find(
                    a => a.id === e.value
                )?.name;
                newRows[index].operatorName = fullValue?.slice(fullValue?.indexOf(" ") + 1);
            }

            setFilterRows(newRows);
        }
    }

    const handleFilterValueChange = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].filterValue = e.component._changedValue;
            setFilterRows(newRows);
        }
    }

    const handleFilterSelectValueChange = (e, index) => {
        if (e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].filterValue = e.value;
            setFilterRows(newRows);
        }
    }

    const handleFilterValue2Change = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].filterValue2 = e.component._changedValue;
            setFilterRows(newRows);
        }
    }

    const handleFilterRowDelete = (index) => {
        const newRows = [...filterRows];
        if (index === newRows?.length - 1 && newRows?.length > 1) {
            newRows[newRows?.length - 2].logicalOperator = null;
        }
        newRows.splice(index, 1);
        setFilterRows(newRows);
    }

    const handleLogicalOperatorChange = (e, index) => {

        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            if (newRows[index].logicalOperator === null) {
                newRows.push({
                    id: 0,
                    column: null,
                    columnType: null,
                    operator: null,
                    operatorName: null,
                    filterValue: null,
                    filterValue2: null,
                    logicalOperator: null,
                });
            }
            newRows[index].logicalOperator = e.value;
            setFilterRows(newRows);
        }
    }

    const getOptionsToSelect = (columnType, columnId) => {
        if (columnType === 'numeric') {
            return numericOptions;
        }
        else if (columnId === 5 || columnId === 6 || columnId === 91 || columnId === 92) {
            return [...textOptions, ...columnsValueOptions]
        }
        else {
            return textOptions
        }
    }

    const renderNumericField = (operator, filterValue, filterValue2, index) => {
        if (operator === 9) {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem" }}>
                    <div style={{ paddingRight: "0.5rem" }}>
                        <NumberBox
                            onChange={(e) => handleFilterValue2Change(e, index)}
                            placeholder="Add Filter Value"
                            value={filterValue2}
                        >
                        </NumberBox>
                    </div>
                    <div >
                        <NumberBox
                            onChange={(e) => handleFilterValueChange(e, index)}
                            placeholder="Add Filter Value"
                            value={filterValue}
                        >
                        </NumberBox>
                    </div>
                </div >
            )
        }
        else {
            return (
                <div >
                    <NumberBox
                        onChange={(e) => handleFilterValueChange(e, index)}
                        placeholder="Add Filter Value"
                        value={filterValue}
                    >
                    </NumberBox>
                </div>
            )
        }
    }

    const renderStringField = (id, operator, filterValue, index) => {
        let columnOptions = null;
        if (operator === 9 || operator === 10) {
            switch (id) {
                case 5:
                    columnOptions = preferredVendorOptions;
                    break;
                case 6:
                    columnOptions = universalProductTypeOptions;
                    break;
                case 91:
                    columnOptions = programOptions;
                    break;
                case 92:
                    columnOptions = categoryOptions;
                    break;
                default:
                    columnOptions = null;
            }

            return (
                <SelectBox
                    placeholder="Select Logical operator "
                    dataSource={columnOptions}
                    displayExpr="name"
                    valueExpr="name"
                    value={filterValue}
                    onValueChanged={(e) => handleFilterSelectValueChange(e, index)}
                    id="filterValue"

                />
            )
        }
        else {
            return (
                <TextBox
                    onChange={(e) => handleFilterValueChange(e, index)}
                    placeholder="Add Filter Value"
                    value={filterValue}
                >
                </TextBox >
            )
        }
    }


    return (
        <div>
            {filterRows?.map((filterRow, index) => (
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fcfcfc", paddingTop: "2rem", paddingBottom: "2rem", paddingLeft: "0.5rem", paddingRight: "1rem", flexGrow: "1", flexBasis: "0" }}>
                        <div style={{ display: "flex" }}>
                            <SelectBox
                                placeholder="Select Column to Filter "
                                dataSource={columnOptions}
                                displayExpr="name"
                                valueExpr="id"
                                value={filterRow?.id}
                                onValueChanged={(e) => handleColumnChange(e, index)}
                                id="column"

                            />
                        </div>
                        <div >
                            <SelectBox
                                placeholder="Select Logical operator "
                                dataSource={getOptionsToSelect(filterRow?.columnType, filterRow?.id)}
                                displayExpr="name"
                                valueExpr="id"
                                value={filterRow?.operator}
                                onValueChanged={(e) => handleOperatorChange(e, index)}
                                id="operator"

                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem" }}>
                            {filterRow?.columnType === 'numeric' ? renderNumericField(filterRow?.operator, filterRow?.filterValue, filterRow?.filterValue2, index) : renderStringField(filterRow?.id, filterRow?.operator, filterRow?.filterValue, index)}
                        </div>
                        {filterRow?.operator !== 7 && filterRow?.operator !== 8 &&
                            <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem", justifyContent: "space-between" }}>
                                {filterRow?.columnType === 'numeric' && filterRow?.operator === 9 &&
                                    <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem", justifyContent: "space-between" }}>
                                        <div style={{ paddingRight: "0.5rem" }}>
                                            <NumberBox
                                                onChange={(e) => handleFilterValue2Change(e, index)}
                                                placeholder="Add Filter Value"
                                                value={filterRow?.filterValue2}
                                            >
                                            </NumberBox>
                                        </div>
                                        <span style={{ marginTop: "0.5rem" }}>-</span>
                                    </div>
                                }
                                {filterRow?.columnType === 'numeric' ? (
                                    <NumberBox
                                        onChange={(e) => handleFilterValueChange(e, index)}
                                        placeholder="Add Filter Value"
                                        value={filterRow?.filterValue}
                                    >
                                    </NumberBox>
                                ) : (
                                    <TextBox
                                        onChange={(e) => handleFilterValueChange(e, index)}
                                        placeholder="Add Filter Value"
                                        value={filterRow?.filterValue}

                                    >
                                    </TextBox>)
                                }
                            </div>
                        }
                        <div style={{ marginTop: "0.4rem" }}>
                            <RadioGroup items={logicalOperatorOptions} value={filterRow?.logicalOperator} onValueChanged={(e) => handleLogicalOperatorChange(e, index)} valueExpr="id" layout="horizontal" />
                        </div>
                        {(index !== 0 || filterRows.length > 1) &&
                            <IconButton id={index} onClick={() => handleFilterRowDelete(index)}>
                                <Delete fontSize="small" />
                            </IconButton>
                        }
                    </div>
                    {
                        index < filterRows.length - 1 &&
                        <div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: "1rem" }}
                            >
                                <div style={{ flex: 1, height: '1px', backgroundColor: 'black' }} />

                                <div>
                                    <p style={{ width: '70px', textAlign: 'center' }}>{logicalOperatorOptions.find(
                                        a => a.id === filterRow?.logicalOperator
                                    )?.text}</p>
                                </div>

                                <div style={{ flex: 1, height: '1px', backgroundColor: 'black' }} />
                            </div>
                        </div>
                    }
                </div>
            ))
            }
        </div >
    );
}
