import { Link, Tooltip } from '@mui/material';

const LinkTooltipCell = (cellData, action, params) => {
  return (
    <Tooltip title={cellData.value}>
        <Link 
            variant="body2"
            onClick={() => {
                return action(cellData, params)
            }}
            style={{color:"#626262", fontFamily:"verdana", fontSize:"9px", cursor: "pointer"}}
        >
            {cellData.value}
        </Link>
    </Tooltip>
  );
}
export default LinkTooltipCell;