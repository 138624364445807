import { getModifierValueCode } from "./mohFunctions";

export const calculatePoCost = (rowData, qtyKey) => {
    const suggestedPrice = rowData["Purchase Price"];
    let value = rowData[qtyKey];
    if (!value) {
        value = 0;
    }
    let result = Math.round((value * suggestedPrice) * 100) / 100;
    return result;
}


export const calculatePoReviewerCost = (rowData) => {
    return calculatePoCost(rowData, "poQtyReviewer");
}

export const calculatePoCostMarket = (rowData) => {
    return calculatePoCost(rowData, "poQtyMarket");
}

export const calculatePoCostApprover = (rowData) => {
    const result = calculatePoCost(rowData, "poQtyApprover");
    return result;
}

export const calculateRevisedPoQty = (rowData, selectedModifier) => {
    const selectedModifierCode= getModifierValueCode(selectedModifier);
    let result = 0;
    switch (selectedModifierCode) {
        case "col_suggestedPoQty":
            result = rowData["Suggested PO Qty"];
            break;
        case "col_poQtyReviewer":
            result = rowData["poQtyReviewer"];
            break;
        case "col_poQtyMarket":
            result = rowData["poQtyMarket"];
            break;
        case "col_poQtyApprover":
            result = rowData["poQtyApprover"];
            break;
        default:
            result = 0;
            break;
    }
    if (!result) {
        return 0;
    }
    return result;
}



export const CalculateProjectedMohAfterPo3M = (rowData, selectedModifier) => {
    const revisedPoQty = Number(calculateRevisedPoQty(rowData, selectedModifier));
    const atptbt = Number(rowData["Avail + PO + Mod - BO"]);
    const qohTotal = Number(rowData["QoH Total"]);
    const l3mAvg = Number(rowData["L3M Avg"]);
    
    if (!(qohTotal + atptbt + revisedPoQty > 0)) return " - ";
    if (l3mAvg === 0)
        return " ∞ ";
    const output = Number((revisedPoQty + atptbt) / l3mAvg);
    
    return output.toFixed(1);
}

export const calculateY2ToY1 = (rowData) => {
    var column = rowData["y2ToY1"];
    var y1 = rowData["Y1"];
    var y2 = rowData["Y2"];
    if (column === 'Infinity') return " ∞ ";
    if (column === null || (y2 == null && y1 == null )) return null;
    return typeof column === 'number' ? column.toFixed(0) : "";
}

export const calculateTopBuyerShare = (rowData) => {
    var column = rowData["TopBuyerShare"];
    if (column == null) return null;
    if (column === 'Infinity') return " ∞ ";
    return typeof column === 'number' ? column.toFixed(0) : "";
}

export const calculateRemainingEstMoh = (rowData, Months) => {
    var column = Months === 12 ? rowData["Remaining Est MoH L12M"] : rowData["Remaining Est MoH L3M"];
    if (column == null) return " - ";
    if (column === 'Infinity') return " ∞ ";
    return typeof column === 'number' ? column.toFixed(1) : "";
}


export const CalculateProjectedMohAfterPo12M = (rowData, selectedModifier) => {
    const RevisedPoQty = Number(calculateRevisedPoQty(rowData, selectedModifier));
    const ATPTBT = Number(rowData["Avail + PO + Mod - BO"]);
    const QohTotal = Number(rowData["QoH Total"]);
    const L12MAvg = Number(rowData["L12MAvg"]);
    
    if (!(QohTotal + ATPTBT + RevisedPoQty > 0)) return " - ";
    if (L12MAvg === 0)
        return " ∞ ";
    var output = Number((RevisedPoQty + ATPTBT) / L12MAvg);
    
    return typeof output === 'number' ? output.toFixed(1) : "";
}


const parseValue = (value) => isNaN(parseFloat(value)) ? 0 : parseFloat(value);

export const CalculateAdditionalMoHLoad = (rowData, selectedModifier) => {
    const revisedPoQty = calculateRevisedPoQty(rowData, selectedModifier);

    const projectedMohAfterPo12M = CalculateProjectedMohAfterPo12M(rowData, selectedModifier);
    const projectedMohAfterPo3M = CalculateProjectedMohAfterPo3M(rowData, selectedModifier);

    const val1 = parseValue(projectedMohAfterPo12M);
    const val2 = parseValue(projectedMohAfterPo3M);

    const val3 = parseValue(rowData["Remaining Est MoH L12M"]);
    const val4 = parseValue(rowData["Remaining Est MoH L3M"]);

    let val5 = Math.round((val1 - val3), 0);
    let val6 = Math.round((val2 - val4), 0);

    if(projectedMohAfterPo12M === " - " || projectedMohAfterPo12M === " ∞ " ||
    rowData["Remaining Est MoH L12M"] === " - " || rowData["Remaining Est MoH L12M"]=== " ∞ " 
    ){
        val5 = " ∞ "
    }

    if(projectedMohAfterPo3M === " - " || projectedMohAfterPo3M === " ∞ " ||
    rowData["Remaining Est MoH L3M"] === " - " || rowData["Remaining Est MoH L3M"]=== " ∞ " 
    ){
        val6 = " ∞ "
    }

    const total = revisedPoQty > 0 ? `${val5} | ${val6}` : "";
    return total;
}