const CustomersChart = (props) => {

    const { data, totalCustomers } = props;
    let cons = 0;
    let cons2 = 0;

    const total = data.reduce((accumulator, object) => {
        return accumulator + object.value;
    }, 0);

    return (
        <div style={{ paddingTop: "2%", width: "40%" }}>
            <div>
                <span>Total Customers {totalCustomers}</span>
            </div>
            <div style={{ display: "flex", width: "100%", paddingTop: "10px" }}>
                {data.map((x) => {
                    cons = cons + 1;
                    const percent = `${(x.value / total) * 100}%`;
                    return (
                        <div key={"graphic" + cons} style={{ height: "30px", width: percent, backgroundColor: x.color }} />
                    );
                })}
            </div>
            <div key={"labels" + cons}>
            {data.map((x) => {
                cons2 = cons2 + 1;
                return (
                    <div key={"bar1" + cons2} className="legendItem" style={{ display: "flex", margin: "10px" }}>
                        <div key={"bar2" + cons2} style={{ height: "20px", width: "20px", backgroundColor: x.color }} />
                        <span key={"span " + cons2}>{x.title}-{x.value}</span>
                    </div>

                );
            })}
            </div>
        </div>

    );
}

export default CustomersChart;