import { FETCH_VENDOR_LOADING, FETCH_VENDOR } from "../actions/types";


const vendorReducer =  (state = {'Vendor':null, 'loading':false}, action) => {
    switch (action.type) {
        case FETCH_VENDOR_LOADING:
            return {...state, 'loading': true, 'Vendor':null };
        case FETCH_VENDOR:
            return { ...state, [action.internalId]:action.payload, 'loading': false}
        default:
            return state;
    }
};

export default vendorReducer;