import { clearFields, clearFieldsGlobalGrid } from "../customGridActions";
import { showConfirmDeleteAllDialog } from "../dialogs/confirmDialog";

const DELETE_USER_INPUT = "Yes, DELETE USER INPUT";

export const clearGlobalGrid = async (data, mutate, roleId, categories, gridInstance, currentPermissions) => {
    const permissions = {
        "col_poQtyReviewer": 0,
        "col_poNotesReviewer": 0,
        "col_poQtyMarket": 0,
        "col_poNotesMarket": 0,
        "col_poQtyApprover": 0,
        "col_poNotesApprover": 0,
    };
    currentPermissions.forEach((list) => {
        if (list.Permission === 1 && permissions.hasOwnProperty(list.ColumnCode)) {
            permissions[list.ColumnCode] = 1;
        }
    });

    const dialogResult = await showConfirmDeleteAllDialog();
    if (dialogResult.buttonText === DELETE_USER_INPUT) {
        const currentData = [];
        gridInstance.current.instance.forEachNode((x) =>
            x.visible === true ? currentData.push(x.data) : ""
        );
        var internalIds = currentData.map((item) =>
            item.InternalId
        ).join(",");
        await clearFieldsGlobalGrid(roleId,
            internalIds,categories,  permissions["col_poQtyReviewer"],
            permissions["col_poNotesReviewer"],
            permissions["col_poQtyMarket"],
            permissions["col_poNotesMarket"],
            permissions["col_poQtyApprover"],
            permissions["col_poNotesApprover"],
            data,
            mutate);
        mutate();
    }
};

export const clearCategoryGrid = async (data, mutate, programId, categoryId, currentPermissions) => {

    const permissions = {
        "col_poQtyReviewer": 0,
        "col_poNotesReviewer": 0,
        "col_poQtyMarket": 0,
        "col_poNotesMarket": 0,
        "col_poQtyApprover": 0,
        "col_poNotesApprover": 0,
    };
    currentPermissions.forEach((list) => {
        if (list.Permission === 1 && permissions.hasOwnProperty(list.ColumnCode)) {
            permissions[list.ColumnCode] = 1;
        }
    });

    const dialogResult = await showConfirmDeleteAllDialog();
    if (dialogResult.buttonText === DELETE_USER_INPUT) {
        clearFields(
            programId,
            categoryId,
            permissions["col_poQtyReviewer"],
            permissions["col_poNotesReviewer"],
            permissions["col_poQtyMarket"],
            permissions["col_poNotesMarket"],
            permissions["col_poQtyApprover"],
            permissions["col_poNotesApprover"],
            data,
            mutate
        );
    }
};
