import { GRIDTEMPLATE_CREATE, GRIDTEMPLATE_CREATE_LOADING, GRIDTEMPLATE_GET, GRIDTEMPLATE_GET_LOADING } from "../actions/types";

const gridTemplateReducer = (state = { 'loading': false, 'createdTemplate' : null, 'templates': [] }, action) => {
    switch (action.type) {
        case GRIDTEMPLATE_CREATE_LOADING:
            return { ...state, 'loading': true, 'template': null };
        case GRIDTEMPLATE_CREATE:
            let templatesList = state.templates[action.programId][action.categoryId]; 
            templatesList.push(action.payload);
            return {...state,
                'createdTemplate' : action.payload,
                'loading': false, 
                'templates': {...state.templates, [action.programId]:{...state.templates[action.programId], [action.categoryId]: templatesList}}
            };
        case GRIDTEMPLATE_GET_LOADING:
            return { ...state, 'loading': true, 'template': null };
        case GRIDTEMPLATE_GET:
            return {...state,
                'loading': false, 
                'templates': {...state.templates, [action.programId]:{...state.templates[action.programId], [action.categoryId]: action.payload}}
            };
        default:
            return state;
    }
};

export default gridTemplateReducer;