import { Button } from "devextreme-react";
import PropTypes from 'prop-types';
import "./ModalCloseButton.css";

export const ModalCloseButton = ({ onClose }) => {
    return (
            <Button icon="close" onClick={() => onClose()} className={"closeButtonStyle"} />
    );
}

ModalCloseButton.propTypes = {
    onClose: PropTypes.func.isRequired
}
