import { Card, CardContent, Divider, List, ListItem } from "@mui/material";
import { SelectBox } from "devextreme-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNetsuiteLastUpdate, fetchSettings, onChangeSelectedCategory, onChangeSelectedProgram } from "../../actions";
import { changedMenu } from "../../actions/menu";
import { GlobalGridLink } from "../Menu/components/GlobalGridLink";
import { NetsuiteUpdateButton } from "../tools/NetsuiteUpdateButton";
import { NetsuiteLastUpdateLabel } from "../tools/NetsuiteLastUpdateLabel";

export const ProgramSelector = () => {

    const [programId, setProgramId] = useState(0);
    const programs = useSelector(state => state.userInfo.programs);
    const permissions = useSelector(state => state.userInfo.feature);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchNetsuiteLastUpdate());
    }, []);

    const handleChange = (e) => {
        let programId = e.value;
        dispatch(onChangeSelectedProgram(programId));
        dispatch(onChangeSelectedCategory(0));
        dispatch(fetchSettings(programId));
        dispatch(changedMenu(5));
        setProgramId(programId);
    };

    if (!permissions) {
        return "";
    }

    return (
        <>
            <Card >
                <CardContent>
                    <List>
                        <ListItem>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>Program</div>
                                <div >
                                    <SelectBox items={programs}
                                        placeholder="Select..."
                                        displayExpr="name"
                                        valueExpr="programId"
                                        onValueChanged={handleChange}
                                    />
                                </div>
                                <div style={{paddingTop:"20px"}}>
                                    <GlobalGridLink text="Filter Wizard" style={{ color: "#161616" }} />
                                </div>
                            </div>

                        </ListItem>

                        <Divider></Divider>
                        <ListItem>
                            <div style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
                                <NetsuiteUpdateButton origin={"sideMenu"} />
                            </div>
                        </ListItem>
                        <Divider></Divider>
                        <ListItem>
                            <div style={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                                <div style={{ width: "100%" }}>
                                    <span style={{ fontSize: "1em" }}>Integrator Timestamp (PST)</span><br />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <NetsuiteLastUpdateLabel origin={"sideMenu"} />
                                </div>
                            </div>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </>
    );
}
