
import apiClient from "../apis/items";
import netsuiteClient from "../apis/netsuite";
import {
    FETCH_KIT, FETCH_KIT_LOADING, FETCH_NETSUITEINTEGRATION, FETCH_NETSUITEINTEGRATION_LOADING,
    FETCH_RELATEDCOMPONENTS, FETCH_RELATEDCOMPONENTS_LOADING, FETCH_USERPERMISSIONS,
    FETCH_SETTINGS, FETCH_SETTINGS_LOADING, FETCH_UNIQUECUSTOMERS, FETCH_UNIQUECUSTOMERS_LOADING,
    FETCH_VENDORS_LOADING, FETCH_VENDORS, FETCH_BACK_LOADING, FETCH_BACK, FETCH_CATEGORIES_LOADING, FETCH_CATEGORIES, FETCH_ITEMS_LOADING, FETCH_ITEMS, FETCH_PROGRAMS_LOADING, FETCH_PROGRAMS, FETCH_UPLOADEDDATA_LOADING, FETCH_UPLOADEDDATA, FETCH_UPLOADERROR_LOADING, FETCH_UPLOADERROR, FETCH_USERPERMISSIONS_LOADING, FETCH_ROLEUSERS_LOADING, FETCH_ROLEUSERS, FETCH_FEATUREPERMISSIONS_LOADING, FETCH_FEATUREPERMISSIONS, RESET_ITEMS, FETCH_ADMIN_ROLES_LOADING, FETCH_ADMIN_ROLES, FETCH_ADMIN_USERS, FETCH_ADMIN_USERS_LOADING, FETCH_NETSUITE_LASTUPDATE_LOADING, FETCH_NETSUITE_LASTUPDATE, ROLE_USER_ADDED, ROLE_USER_ADDED_LOADING, ROLE_USER_DELETE_LOADING, ROLE_USER_DELETE, ROLE_ADDED_LOADING, ROLE_ADDED, ROLE_DELETE_LOADING, ROLE_DELETE,
    POST_SETTINGS_LOADING, POST_SETTINGS, FETCH_NETSUITE_FILELASTUPDATE_LOADING, FETCH_NETSUITE_FILELASTUPDATE, USER_AUTHENTICATED, ROLES_FEATURE_PERMISSIONS_LOADING, ROLES_FEATURE_PERMISSIONS, ROLES_COLUMNS_PERMISSIONS_LOADING, ROLES_COLUMNS_PERMISSIONS, ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING, ROLES_FEATURE_PERMISSIONS_UPDATE, FETCH_CATEGORIES_ALL_LOADING, FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM, FETCH_PROGRAMS_ALL_LOADING, FETCH_PROGRAMS_ALL, FETCH_CATEGORIES_ALL, FETCH_CATEGORIES_ALL_BY_PROGRAM, USER_CATEGORIESPROGRAMS_LOADING, USER_CATEGORIESPROGRAMS,
    ROLES_FEATURE_VIEWER_ALL_LOADING, ROLES_FEATURE_VIEWER_ALL, ROLES_COLUMNS_VIEWER_ALL_LOADING, ROLES_COLUMNS_VIEWER_ALL, USER_CATEGORIESPROGRAMS_DELETE_LOADING, USER_CATEGORIESPROGRAMS_DELETE, USER_CATEGORIESPROGRAMS_CREATE_LOADING, USER_CATEGORIESPROGRAMS_CREATE, CHANGE_PO_VALUE, CHANGE_PO_LOADING, FETCH_ITEMS_BY_CATEGORY_LOADING, FETCH_ITEMS_BY_CATEGORY,
    FETCH_COLUMNS_LOADING, FETCH_COLUMNS, CHANGE_SELECTED_PROGRAM,
    GRIDTEMPLATE_GET, GRIDTEMPLATE_GET_LOADING, USER_INFORMATION_LOADED, FETCH_USERINFO_LOADING, FETCH_INVENTORY_LOADING, FETCH_INVENTORY, CHANGE_SELECTED_CATEGORY, OPEN_WIZARD_MODAL,
    FETCH_PERIODS_LOADING, FETCH_PERIODS, FETCH_SUMMARY_PERIODS_LOADING, FETCH_SUMMARY_PERIODS, FETCH_CURRENT_PERIODS_LOADING, FETCH_CURRENT_PERIODS, FETCH_GRAPHIC_PERIODS_LOADING, FETCH_GRAPHIC_PERIODS,
    FETCH_CONSOLIDATE_LOADING, FETCH_CONSOLIDATE, FETCH_VENDOR_LOADING, FETCH_VENDOR, POST_USERS_LOADING, POST_USERS, FETCH_SETTINGS_ALL, FETCH_ROLEUSERS_ERROR
} from "./types";

export const fetchNetsuiteLastUpdate = () => async dispatch => {
    dispatch({ type: FETCH_NETSUITE_LASTUPDATE_LOADING });
    const lastUpdate = await netsuiteClient.get(`/api/Netsuite/lastUpdateInfo?type=0`);
    dispatch({ type: FETCH_NETSUITE_LASTUPDATE, payload: lastUpdate.data });
}


export const fetchItemsByCategory = (programId, categoryId, modifier = 1) => async dispatch => {
    dispatch({ type: FETCH_ITEMS_BY_CATEGORY_LOADING });
    const response = await apiClient.get(`/api/Report/reportHppoByCategory?programId=${programId}&categoryId=${categoryId}&modifier=${modifier}`);
    dispatch({ type: FETCH_ITEMS_BY_CATEGORY, payload: response.data, categoryId: categoryId, programId: programId });
}


export const fetchItems = (programId) => async dispatch => {
    dispatch({ type: FETCH_ITEMS_LOADING });
    const response = await apiClient.get(`/api/Report/reportHPPO?program=${programId}`);
    dispatch({ type: FETCH_ITEMS, payload: response.data });
    dispatch({ type: FETCH_NETSUITE_LASTUPDATE_LOADING });
    const lastUpdate = await netsuiteClient.get(`/api/Netsuite/lastUpdateInfo?type=0`);
    dispatch({ type: FETCH_NETSUITE_LASTUPDATE, payload: lastUpdate.data });
}


export const calculatePoValues = (categoryId, selectedModifier) => async dispatch => {
    dispatch({ type: CHANGE_PO_LOADING });
    dispatch({ type: CHANGE_PO_VALUE, categoryId: categoryId, selectedModifier: selectedModifier });

}

export const fetchLastUpdateFiles = (ItemId) => async dispatch => {
    dispatch({ type: FETCH_NETSUITE_FILELASTUPDATE_LOADING });
    const lastFileUpdate = await netsuiteClient.get(`/api/Netsuite/lastUpdateInfo?type=${ItemId}`);
    dispatch({ type: FETCH_NETSUITE_FILELASTUPDATE, payload: lastFileUpdate.data });
}

export const GetLastUpdateFiles = async (ItemId) => {
    const response = await netsuiteClient.get(`/api/Netsuite/lastUpdateInfo?type=${ItemId}`);
    return response.data;
}

export const fetchKit = (ItemId) => async dispatch => {
    dispatch({ type: FETCH_KIT_LOADING });
    const response = await apiClient.get(`/api/Report/reportActiveKits?InternalID=${ItemId}`);
    dispatch({ type: FETCH_KIT, payload: response.data, internalId: ItemId });
}

export const fetchRelatedComponents = (ItemId) => async dispatch => {
    dispatch({ type: FETCH_RELATEDCOMPONENTS_LOADING });
    const response = await apiClient.get(`/api/Report/reportBO?Name=${ItemId}`);
    dispatch({ type: FETCH_RELATEDCOMPONENTS, payload: response.data });
}

export const fetchUniqueCustomers = (ItemId) => async dispatch => {
    dispatch({ type: FETCH_UNIQUECUSTOMERS_LOADING });
    const response = await apiClient.get(`/api/Report/reportuniquecustomer?internalid=${ItemId}`);
    dispatch({ type: FETCH_UNIQUECUSTOMERS, payload: response.data });
}

export const fetchNetsuiteIntegration = () => async dispatch => {
    dispatch({ type: FETCH_NETSUITEINTEGRATION_LOADING });
    const response = await netsuiteClient.get(`/api/Netsuite/NetsuiteIntegration`);
    dispatch({ type: FETCH_NETSUITEINTEGRATION, payload: response.data });
}

export const fetchSettings = (programId) => async dispatch => {
    dispatch({ type: FETCH_SETTINGS_LOADING, programId: programId });
    const response = await apiClient.get(`/api/Report/reportSettings?idProgram=${programId}`);
    dispatch({ type: FETCH_SETTINGS, payload: response.data, programId: programId });
}

export const postSettings = (data, programId) => async dispatch => {
    dispatch({ type: POST_SETTINGS_LOADING, programId: programId });
    const response = await apiClient.post(`/api/Report/updateSettings`, data);
    dispatch({ type: POST_SETTINGS, payload: response.data, programId: programId });
}


export const postItems = async (data) => {
    await apiClient.post(`/api/Report/updateItem`, data);
}

export const putExclusions = async (id, data) => {
    await apiClient.patch(`/api/Exclusions/${id}`, data);
}

export const fetchVendors = (internalId) => async dispatch => {
    dispatch({ type: FETCH_VENDORS_LOADING });
    const response = await apiClient.get(`/api/Report/reportPreferVendor?InternalID=${internalId}`);
    dispatch({ type: FETCH_VENDORS, payload: response.data, internalId: internalId });
}

export const fetchBack = (internalId) => async dispatch => {
    dispatch({ type: FETCH_BACK_LOADING });
    const response = await apiClient.get(`/api/Report/reportBackOrderCommitted?InternalID=${internalId}`);
    dispatch({ type: FETCH_BACK, payload: response.data, internalId: internalId });
}

export const fetchInventory = (internalId) => async dispatch => {
    dispatch({ type: FETCH_INVENTORY_LOADING });
    const response = await apiClient.get(`/api/Report/reportDetailInventory?InternalID=${internalId}`);
    dispatch({ type: FETCH_INVENTORY, payload: response.data, internalId: internalId });
}

export const fetchConsolidate = (internalId) => async dispatch => {
    dispatch({ type: FETCH_CONSOLIDATE_LOADING });
    const response = await apiClient.get(`/api/Report/reportConsolidateShipments?InternalID=${internalId}`);
    dispatch({ type: FETCH_CONSOLIDATE, payload: response.data, internalId: internalId });
}

export const fetchVendorInventory = (internalId) => async dispatch => {
    dispatch({ type: FETCH_VENDOR_LOADING });
    const response = await apiClient.get(`/api/Report/reportVendorInventory?InternalID=${internalId}`);
    dispatch({ type: FETCH_VENDOR, payload: response.data, internalId: internalId });
}

export const fetchPrograms = () => async dispatch => {
    dispatch({ type: FETCH_PROGRAMS_LOADING });
    const response = await apiClient.get(`/api/Report/programs`);
    dispatch({ type: FETCH_PROGRAMS, payload: response.data });
}


export const fetchCategories = (userAdId) => async dispatch => {
    dispatch({ type: FETCH_CATEGORIES_LOADING });
    const response = await apiClient.get(`/api/Report/categories?adUserId=${userAdId}`);
    dispatch({ type: FETCH_CATEGORIES, payload: response.data });
}

export const fetchConsolidatedShipment = () => async dispatch => {
    dispatch({ type: FETCH_UPLOADEDDATA_LOADING });
    const response = await apiClient.get(`/api/Files/listConsolidatedShipmentData`);
    dispatch({ type: FETCH_UPLOADEDDATA, payload: response.data });
}

export const fetchVendorList = () => async dispatch => {
    dispatch({ type: FETCH_UPLOADEDDATA_LOADING });
    const response = await apiClient.get(`/api/Files/listVendorInventoryData`);
    dispatch({ type: FETCH_UPLOADEDDATA, payload: response.data });
}

export const fetchFileUploadError = () => async dispatch => {
    dispatch({ type: FETCH_UPLOADERROR_LOADING });
    const response = await apiClient.get(`/api/Files/reportLogErrorFiles`);
    dispatch({ type: FETCH_UPLOADERROR, payload: response.data });
}


export const fetchUserPermissions = (idRole, userId) => async dispatch => {
    dispatch({ type: FETCH_USERPERMISSIONS_LOADING });
    const response = await apiClient.get(`/api/RoleUserPermissions/reportRoleUserPermission?IdRole=${idRole}&IdUser=${userId}`);
    dispatch({ type: FETCH_USERPERMISSIONS, payload: response.data });
}

export const fetchRoleUsers = (userAdId) => async dispatch => {
    try {
        dispatchLoading(dispatch, FETCH_ROLEUSERS_LOADING);
        const response = await apiClient.get(`/api/User/reportListUsersInfo?IdActiveDirectory=${userAdId}`);
        dispatchSuccess(dispatch, FETCH_ROLEUSERS, response.data);

        let activeRole = Object.values(response.data).find(x => x.MainRole === true) || Object.values(response.data)[0];

        dispatchLoading(dispatch, FETCH_PROGRAMS_LOADING);
        const programs = await apiClient.get(`/api/Report/programs?adUserId=${userAdId}`);
        dispatchSuccess(dispatch, FETCH_PROGRAMS, programs.data);

        dispatchLoading(dispatch, FETCH_COLUMNS_LOADING);
        const columns = await apiClient.get(`/api/RoleColumns/reportColumns`);
        dispatchSuccess(dispatch, FETCH_COLUMNS, columns.data);

        dispatchLoading(dispatch, FETCH_CATEGORIES_LOADING);
        const categories = await apiClient.get(`/api/Report/categories?adUserId=${userAdId}`);
        dispatchSuccess(dispatch, FETCH_CATEGORIES, categories.data);

        const idRole = activeRole.idRole;

        dispatchLoading(dispatch, FETCH_USERPERMISSIONS_LOADING);
        const permissions = await apiClient.get(`/api/RoleUserPermissions/reportRoleUserPermission?idRole=${idRole}`);
        dispatch({ type: FETCH_USERPERMISSIONS, payload: permissions.data, selectedRole: idRole });

        dispatchLoading(dispatch, FETCH_FEATUREPERMISSIONS_LOADING);
        const featurePermissions = await apiClient.get(`/api/RoleFeatures/reportPermissions?idRole=${idRole}`);
        dispatchSuccess(dispatch, FETCH_FEATUREPERMISSIONS, featurePermissions.data);

        dispatch({ type: USER_INFORMATION_LOADED });
    } catch (error) {
        console.error(error);
        dispatchError(dispatch, FETCH_ROLEUSERS_ERROR);
    }
}

const dispatchError = (dispatch, type) => {
    dispatch({ type });
}

const dispatchLoading = (dispatch, type) => {
    dispatch({ type });
}

const dispatchSuccess = (dispatch, type, data) => {
    dispatch({ type, payload: data });
}


export const SwitchRole = (idRole) => async dispatch => {

    dispatch({ type: FETCH_USERINFO_LOADING });

    dispatch({ type: FETCH_USERPERMISSIONS_LOADING });
    const permissions = await apiClient.get(`/api/RoleUserPermissions/reportRoleUserPermission?idRole=${idRole}`);
    dispatch({ type: FETCH_USERPERMISSIONS, payload: permissions.data, selectedRole: idRole });

    dispatch({ type: FETCH_FEATUREPERMISSIONS_LOADING });
    const featurePermissions = await apiClient.get(`/api/RoleFeatures/reportPermissions?idRole=${idRole}`);
    dispatch({ type: FETCH_FEATUREPERMISSIONS, payload: featurePermissions.data });

    dispatch({ type: RESET_ITEMS });

    dispatch({ type: USER_INFORMATION_LOADED });

}

export const fetchAdminInformation = () => async dispatch => {
    dispatch({ type: FETCH_ADMIN_ROLES_LOADING });
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: FETCH_ADMIN_ROLES, payload: roles.data });

    dispatch({ type: FETCH_ADMIN_USERS_LOADING });
    const users = await apiClient.get(`/api/User/all`);
    dispatch({ type: FETCH_ADMIN_USERS, payload: users.data });
}



export const updateUserRole = (data) => async dispatch => {
    dispatch({ type: ROLE_USER_ADDED_LOADING });
    await apiClient.post(`/api/User/UpdateRoleUser`, data);
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: ROLE_USER_ADDED, payload: roles.data });
}


export const deleteUserRole = (userId, roleId) => async dispatch => {
    dispatch({ type: ROLE_USER_DELETE_LOADING });
    await apiClient.delete(`/api/User/deleteUserRole?userId=${userId}&roleId=${roleId}`);
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: ROLE_USER_DELETE, payload: roles.data });
}


export const createRole = (name) => async dispatch => {
    const data = { "roleName": name };
    dispatch({ type: ROLE_ADDED_LOADING });
    await apiClient.post(`/api/Roles/createUpdateRole`, data);
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: ROLE_ADDED, payload: roles.data });
}


export const updateRole = (idRole, name) => async dispatch => {
    const data = { "roleId": idRole, "roleName": name };
    dispatch({ type: ROLE_ADDED_LOADING });
    await apiClient.post(`/api/Roles/createUpdateRole`, data);
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: ROLE_ADDED, payload: roles.data });
}



export const deleteRole = (roleId) => async dispatch => {
    dispatch({ type: ROLE_DELETE_LOADING });
    await apiClient.delete(`/api/Roles/deleteRole?roleId=${roleId}`);
    const roles = await apiClient.get(`/api/Roles/all`);
    dispatch({ type: ROLE_DELETE, payload: roles.data });
}


export const onUserAuthenticated = (response) => async dispatch => {
    dispatch({ type: USER_AUTHENTICATED, payload: response });
}



export const fetchPermissionsByRoleId = (roleId) => async dispatch => {
    dispatch({ type: ROLES_FEATURE_PERMISSIONS_LOADING });
    const rolesFeaturesPermissions = await apiClient.get(`/api/RoleFeatures/reportRolesFeaturesPermissions?idRole=${roleId}`);
    dispatch({ type: ROLES_FEATURE_PERMISSIONS, payload: rolesFeaturesPermissions.data })
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS_LOADING });
    const rolesColumnsPermissions = await apiClient.get(`/api/RoleColumns/reportColumnPermissions?idRole=${roleId}`);
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS, payload: rolesColumnsPermissions.data })
}


export const updateFeaturePermissionsByRole = (idRole, idFeature, permission) => async dispatch => {
    const data = { "idRole": idRole, "idFeature": idFeature, "permission": permission };
    dispatch({ type: ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING });
    await apiClient.post(`/api/RoleFeatures/createUpdateRoleFeatures`, data);
    dispatch({ type: ROLES_FEATURE_PERMISSIONS_UPDATE })
}

export const updateFeatureColumnsByRole = async (idRole, idColumn, permission) => {
    const data = { "idRole": idRole, "idColumn": idColumn, "permission": permission };
    await apiClient.post(`/api/RoleColumns/createUpdateRoleColumns`, data);
}

export const SetAllColumnsViewerByRole = async (idRole) => {
    await apiClient.get(`/api/RoleColumns/setAllViewerPermission?roleId=${idRole}`);
}

export const fetchUserProgramCategories = (idUser) => async dispatch => {
    dispatch({ type: USER_CATEGORIESPROGRAMS_LOADING });
    const programCategories = await apiClient.get(`/api/UserProgramsCategories/reportUsersProgramsCategories?idUser=${idUser}`);
    dispatch({ type: USER_CATEGORIESPROGRAMS, payload: programCategories.data })
}

export const fetchAllCategories = () => async dispatch => {
    dispatch({ type: FETCH_CATEGORIES_ALL_LOADING });
    const result = await apiClient.get(`/api/Categories`);
    dispatch({ type: FETCH_CATEGORIES_ALL, payload: result.data });
}
/**
 * Developer: CNino
 * Date: 2022-11-01
 * Remarks: This function is added to allow the system to bring all 
 * the categories that are associated with a program
 * @param {*} programid 
 * @returns 
 */
export const fetchAllCategoriesByProgram = (programid) => async dispatch => {
    dispatch({ type: FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM });
    const result = await apiClient.get(`/api/Categories/categories_by_programid?programid=${programid}`);
    dispatch({ type: FETCH_CATEGORIES_ALL_BY_PROGRAM, payload: result.data });
}

export const fetchAllPrograms = () => async dispatch => {
    dispatch({ type: FETCH_PROGRAMS_ALL_LOADING });
    const result = await apiClient.get(`/api/Programs`);
    dispatch({ type: FETCH_PROGRAMS_ALL, payload: result.data });
}

export const setFeaturesViewerAllByRole = (idRole) => async dispatch => {
    dispatch({ type: ROLES_FEATURE_VIEWER_ALL_LOADING });
    await apiClient.get(`/api/RoleFeatures/setAllViewerPermission?roleId=${idRole}`);
    dispatch({ type: ROLES_FEATURE_VIEWER_ALL });
    dispatch({ type: ROLES_FEATURE_PERMISSIONS_LOADING });
    const rolesFeaturesPermissions = await apiClient.get(`/api/RoleFeatures/reportRolesFeaturesPermissions?idRole=${idRole}`);
    dispatch({ type: ROLES_FEATURE_PERMISSIONS, payload: rolesFeaturesPermissions.data });
}

export const setColumnsViewerAllByRole = (idRole) => async dispatch => {
    dispatch({ type: ROLES_COLUMNS_VIEWER_ALL_LOADING });
    await apiClient.get(`/api/RoleColumns/setAllViewerPermission?roleId=${idRole}`);
    dispatch({ type: ROLES_COLUMNS_VIEWER_ALL });
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS_LOADING });
    const rolesColumnsPermissions = await apiClient.get(`/api/RoleColumns/reportColumnPermissions?idRole=${idRole}`);
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS, payload: rolesColumnsPermissions.data })

}

export const deleteUserProgramCategories = (idUser, idProgram, idCategory) => async dispatch => {
    dispatch({ type: USER_CATEGORIESPROGRAMS_DELETE_LOADING });
    await apiClient.delete(`/api/UserProgramsCategories/deleteUsersProgramsCategories?idUser=${idUser}&idProgram=${idProgram}&idCategory=${idCategory}`);
    dispatch({ type: USER_CATEGORIESPROGRAMS_DELETE });
}


export const createUserProgramCategories = (idUser, idProgram, idCategory) => async dispatch => {
    const data = { "IdUser": idUser, "IdProgram": idProgram, "IdCategory": idCategory };
    dispatch({ type: USER_CATEGORIESPROGRAMS_CREATE_LOADING });
    await apiClient.post(`/api/UserProgramsCategories/createUpdateUsersProgramsCategories`, data);
    dispatch({ type: USER_CATEGORIESPROGRAMS_CREATE });
    dispatch({ type: USER_CATEGORIESPROGRAMS_LOADING });
    const programCategories = await apiClient.get(`/api/UserProgramsCategories/reportUsersProgramsCategories?idUser=${idUser}`);
    dispatch({ type: USER_CATEGORIESPROGRAMS, payload: programCategories.data });
}

export const refreshDataGrid = (programId, categoryId, modifier) => async dispatch => {
    dispatch({ type: FETCH_ITEMS_BY_CATEGORY_LOADING });
    const response = await apiClient.get(`/api/Report/reportHppoByCategory?programId=${programId}&categoryId=${categoryId}&modifier=${modifier}`);
    dispatch({ type: FETCH_ITEMS_BY_CATEGORY, payload: response.data, categoryId: categoryId, programId: programId });
}

export const CleanUpdateFields = async (programId, categoryId, PoQtyReviewer, PoNotesReviewer, PoQtyMarket, PoNotesMarket, PoQtyApprover, PoNotesApprover) => {
    const data = {
        programId: programId,
        categoryId: categoryId,
        poQtyReviewer: PoQtyReviewer,
        poNotesReviewer: PoNotesReviewer,
        poQtyMarket: PoQtyMarket,
        poNotesMarket: PoNotesMarket,
        poQtyApprover: PoQtyApprover,
        poNotesApprover: PoNotesApprover
    }
    await apiClient.post(`/api/Report/clearUpdateFields`, data);
}

export const CleanUpdateFieldsGlobalGrid = async (roleId, internalIds) => {
    const data = {
        internalIds: internalIds,
        roleId: roleId
    }
    await apiClient.post(`/api/Report/globalGridClearUpdateFields`, data);
}

export const fetchColumnsPermissionsByRoleId = (roleId) => async dispatch => {
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS_LOADING });
    const rolesColumnsPermissions = await apiClient.get(`/api/RoleColumns/reportColumnPermissions?idRole=${roleId}`);
    dispatch({ type: ROLES_COLUMNS_PERMISSIONS, payload: rolesColumnsPermissions.data })
}


export const onChangeSelectedProgram = (programId) => async dispatch => {
    dispatch({ type: CHANGE_SELECTED_PROGRAM, programId: programId });
}

export const onChangeSelectedCategory = (categoryId) => async dispatch => {
    dispatch({ type: CHANGE_SELECTED_CATEGORY, categoryId: categoryId });
}

export const openWizardModal = (openWizardModal) => async dispatch => {
    dispatch({ type: OPEN_WIZARD_MODAL, openWizardModal: openWizardModal });
}

export const getGridTemplates = (idUser, idProgram, idCategory) => async dispatch => {
    dispatch({ type: GRIDTEMPLATE_GET_LOADING });
    const response = await apiClient.get(`/api/GridTemplates/${idUser}/${idProgram}/${idCategory}`);
    dispatch({ type: GRIDTEMPLATE_GET, payload: response.data, programId: idProgram, categoryId: idCategory });
}



export const getProgramCategoriesByTemplateId = async (templateId) => {
    const programCategoriesTemplate = await apiClient.get(`/api/GridTemplatesProgramCategory/${templateId}`);
    return programCategoriesTemplate;
}


export const updateProgramCategoriesTemplate = async (data) => {
    const programCategoriesTemplate = await apiClient.post(`/api/GridTemplatesProgramCategory/`, data);
    return programCategoriesTemplate;
}

export const fetchInfoPeriods = (internalId, Period) => async dispatch => {
    dispatch({ type: FETCH_PERIODS_LOADING });
    const response = await apiClient.get(`/api/Report/reportInfoPeriod?InternalID=${internalId}&Period=${Period}`);
    dispatch({ type: FETCH_PERIODS, payload: response.data, internalId: internalId });
}

export const fetchGraphicPeriods = (internalId, Period) => async dispatch => {
    dispatch({ type: FETCH_GRAPHIC_PERIODS_LOADING, internalId: internalId, Period: Period });
    const response = await apiClient.get(`/api/Report/reportTimePeriod?InternalID=${internalId}&Period=${Period}`);
    dispatch({ type: FETCH_GRAPHIC_PERIODS, payload: response.data, internalId: internalId });
}


export const fetchSummaryPeriods = (internalId, Period) => async dispatch => {
    dispatch({ type: FETCH_SUMMARY_PERIODS_LOADING, internalId: internalId, Period: Period });
    const response = await apiClient.get(`/api/Report/reportSummaryPeriod?InternalID=${internalId}&Period=${Period}`);
    dispatch({ type: FETCH_SUMMARY_PERIODS, payload: response.data, internalId: internalId });
}

export const fetchCurrentPeriods = (internalId) => async dispatch => {
    dispatch({ type: FETCH_CURRENT_PERIODS_LOADING, internalId: internalId });
    const response = await apiClient.get(`/api/Report/reportCurrentTimePeriod?InternalID=${internalId}`);
    dispatch({ type: FETCH_CURRENT_PERIODS, payload: response.data, internalId: internalId });
}


export const fetchExportAllDownload = async (userId, roleId) => {
    const response = await apiClient.get(`/api/Export/create/${userId}/${roleId}`);
    return response;
}

export const deleteBulkExport = async (processId) => {
    const response = await apiClient.delete(`/api/Export/${processId}`);
    return response;
}

export const fetcher = (url) => apiClient.get(url).then(res => res?.data);
export const fetcherNetsuite = (url) => netsuiteClient.get(url).then(res => res?.data);

export const postUsers = () => async dispatch => {
    dispatch({ type: POST_USERS_LOADING });
    const response = await apiClient.post(`/api/User/updateUsers?IdRoleLogin=1`);
    dispatch({ type: POST_USERS, payload: response.data });
}

export const getMohSelectValues = async (idRole) => {
    const mohSelectValues = await apiClient.get(`/api/UserColumnMoH/listMohSelectValues?idRole=${idRole}`);
    return mohSelectValues.data;
}


export const getServiceStatusByCode = async (service) => {
    const response = await apiClient.get(`/api/serviceStatus/${service}`);
    return response.data;
}

export const postNotification = async (data) => {
    await apiClient.post(`/api/Notification`, data);
}

export const fetchExcludedItems = async (internalID) => {
    const response = await apiClient.get(`/api/ExcludedItemFulfillment/${internalID}`);
    return response.data;
}
