import Tabs from 'devextreme-react/tabs';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { fetchPermissionsByRoleId } from '../../../../actions';
import ColumnsByRole from './ColumnsByRole';
import FeaturesByRole from './FeaturesByRole';

const tabs = [
    {
        id: 0,
        text: 'Features',
        icon: 'group',
        content: 'Roles Features',
    },
    {
        id: 1,
        text: 'Columns',
        icon: 'columnfield',
        content: 'Roles Columns permissions',
    }
];

export const PermissionsByRoleTab = (props) => {
    const {roleId} = props;
    const [selectedIndex, setselectedIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPermissionsByRoleId(roleId));
    }, [roleId]);

    const onTabsSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            setselectedIndex(args.value);
        }
    }

    const renderComponent = (selectedIndex) => {
        if (selectedIndex === 0) {
            return <FeaturesByRole roleId={roleId}/>
        }
        else if (selectedIndex === 1) {
            return <ColumnsByRole roleId={roleId}/>
        }
        return "";
    }

    return (
        <>
            <div id="tabs">
                <Tabs
                    dataSource={tabs}
                    selectedIndex={selectedIndex}
                    onOptionChanged={onTabsSelectionChanged}
                    scrollByContent
                />
                <div>
                    {renderComponent(selectedIndex)}
                </div>
            </div>
        </>

    )

}

export default PermissionsByRoleTab;